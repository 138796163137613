import {
  getOrderDetails,
  getOrderDetailsWitoutOrderId,
  getOrderItems,
  getProductDetails,
  getProductDetailsById,
  getProducts,
} from "../api/invoiceApis";

export default async function getInvoiceDetails(
  id: number,
  isIncomingOrderId?: boolean
) {
  let orderDetails: any = {};
  if (isIncomingOrderId) {
    orderDetails = await getOrderDetailsWitoutOrderId(id);
  } else {
    orderDetails = await getOrderDetails(id);
  }

  if (orderDetails.order_id !== null) {
    const data: any = {
      ...orderDetails,
      items: [],
    };

    const items = await getOrderItems(id);

    for (let key = 0; key < items.length; key++) {
      if (id) {
        const item = items[key];
        const {
          id,
          batch,
          expiry,
          status,
          quantity,
          bill_quantity,
          cgst,
          sgst,
          hsn,
          sub_total,
          discount,
          total,
          retail_store_id,
        } = item;
        const productDetails = await getProductDetailsById(item.product_id);
        data.items.push({
          retail_store_id,
          // productId: productDetails[0].id,
          name: productDetails[0].name,
          pack: productDetails[0].pack,
          pack_price: productDetails[0].pack_price,
          pack_size: productDetails[0].pack_size,
          order_item_id: id,
          status,
          quantity,
          hsn,
          bill_quantity: bill_quantity || quantity,
          batch,
          expiry,
          discount,
          cgst,
          sgst,
          // price,
          sub_total,
          total,
          // rate: parseFloat(price) - parseFloat(discount),
        });
      }
    }
    return data;
  }

  return { ...orderDetails, items: [] };
}
