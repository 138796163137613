import { message } from "antd";
import { createOrUpdateInvoice, updateOrderStatus } from "../api/invoiceApis";

export default async function saveAsDraft(data: any, onSuccess: any) {
  message.loading({ content: "Wait a sec...", key: "message" }, 10);
  const result: any = await createOrUpdateInvoice({
    ...data,
    saveAsType: "Draft",
  }).then(async (res) => {
    await updateOrderStatus(res.order, { status: 0 });
    if (res.order) {
      message.success(
        {
          content: "Saved as draft successfully.!",
          key: "message",
        },
        0
      );
      onSuccess(res);
    } else {
      message.error({ content: "Something went wrong.!", key: "message" }, 0);
    }
  });

  return result;
}
