import { FireOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  getCustomer,
  getReorderById,
  getReorderItems,
} from "../api/invoiceApis";
import Loader from "./Loader";

const getAddressString = (customer: any) =>
  (customer.line1 ? customer.line1 + ", " : "") +
  (customer.line2 ? customer.line2 + ", " : "") +
  (customer.landmark ? customer.landmark + ", " : "") +
  (customer.city ? customer.city + ", " : "") +
  (customer.state ? customer.state + ", " : customer.state) +
  (customer.pincode ? customer.pincode + ", " : customer.pincode);

interface ReorderOverviewCardProps {
  reorderId: number;
}

export default function ReorderOverviewCard({
  reorderId,
}: ReorderOverviewCardProps) {
  const classes = useStyle();
  const [reorderDetails, setReorderDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const fetchReorderDetails = async () => {
    const reorder = await getReorderById(+reorderId); // reorder details
    const items = await getReorderItems(+reorderId); // reorder items
    const customer = await getCustomer(reorder[0]?.user_id); // customer details

    const address = {
      address: getAddressString(customer[0] || {}),
      phone_no: customer[0]?.phone_no || "",
    };

    setReorderDetails({
      items,
      address,
      reorder: reorder[0] ?? {},
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!!reorderId) {
      setLoading(true);
    }
  }, [reorderId]);

  useEffect(() => {
    if (loading) {
      fetchReorderDetails();
    }
  }, [loading]);

  return (
    <div className={classes.reorderOverviewCard}>
      <div className={classes.cardHeader}>
        <FireOutlined />
        <Typography.Text strong>Reorder Overview</Typography.Text>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {" "}
          <Card className={classes.summaryCard}>
            <div>
              <Typography.Text>Subtotal</Typography.Text>
              <Typography.Text>
                ₹ {(+reorderDetails?.reorder?.subtotal).toFixed(2)}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text>Discount</Typography.Text>
              <Typography.Text>
                ₹ {(+reorderDetails?.reorder?.coupon_discount).toFixed(2)}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text>LP Redeemed</Typography.Text>
              <Typography.Text>
                ₹{" "}
                {(+reorderDetails?.reorder?.redeemed_loyalty_points).toFixed(2)}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text>Shipping Charge</Typography.Text>
              <Typography.Text>
                ₹ {(+reorderDetails?.reorder?.shipping_charge).toFixed(2)}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text strong>Grandtotal</Typography.Text>
              <Typography.Text>
                ₹ {(+reorderDetails?.reorder?.grandtotal).toFixed(2)}
              </Typography.Text>
            </div>
          </Card>
          <Typography.Text strong style={{ padding: "10px 5px" }}>
            Items
          </Typography.Text>
          <div className={classes.itemsWrapper}>
            {reorderDetails?.items?.map((item: any) => (
              <div className={classes.itemWrapper}>
                <div>
                  <div className={classes.itemName}>{item?.name}</div>
                  <div className={classes.itemQuantity}>
                    Qty : {item?.quantity}
                  </div>
                </div>
                <div className={classes.itemTotal}>
                  ₹ {parseFloat(item?.total).toFixed(2)}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  reorderOverviewCard: {
    width: "100%",
    flex: 1,
    marginBottom: 15,
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  cardHeader: {
    padding: [5, 15, 10],
    fontSize: 22,
    color: colors.textPrimary,

    "& .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  summaryCard: {
    marginBottom: 15,

    "& .ant-card-body": {
      padding: [15, 20],
      fontSize: 12,
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
  },
  itemsWrapper: {
    marginTop: 5,
    height: "calc(100vh - 270px)",
    overflowY: "auto",
  },
  itemWrapper: {
    padding: [10, 20],
    marginBottom: 12,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  itemName: {
    marginBottom: 10,
  },
  itemQuantity: {},
  itemTotal: {
    fontWeight: 700,
  },
}));
