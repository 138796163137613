import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import client from '../client'
//import useSubmitHandler from 'hooks/useSubmitHandler'
import { getSession, logout,Session } from '../client/reactives/session'
import { useDestroySessionMutation } from '../generated/schema'

function LogoutPage() {
  const { push } = useHistory()
  const [ destroySession ] = useDestroySessionMutation()
  const { token } = getSession() as Session
  // @ts-ignore
  //const handleLogout = useSubmitHandler(destroySession)
  const handleLogout = async () => {
    const url=process.env.REACT_APP_API_NODE_BASE_URL
    let data={
      expiry_status:"'expired'",
    }
    //handleCreateUser(values)
    await fetch(url+'/logout', { 
      method: "PUT",
      body: JSON.stringify(data), 
      headers: { 
           "Content-type": "application/json",
           'Access-Control-Allow-Origin': "*",
           "x-access-token":`${token}`
       } 
   }).then(response=>response.json()) 
     .then(json=>{
     })
  }
  useEffect(() => {
    // @ts-ignore
    handleLogout().finally(() => {
      logout()
      client.clearStore()
      push('/admin/login')
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default LogoutPage
