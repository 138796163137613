import { Card, Modal, Col, Input, Row, DatePicker, Button } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import InvoiceTable from "./InvoiceTable";
import { createUseStyles } from "react-jss";
import { useEffect, useState } from "react";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { getSession, Session } from "../client/reactives/session";
const { confirm } = Modal;

type InvoiceFormContainerProps = {
  invoice: any;
  setInvoiceCalc: any;
  products: Array<Object>;
  onInputChange: any;
  onDateTimeChange: any;
  onSaveAsDraft: () => void;
  onSaveAndPublish: any;
  onItemsChange: any;
  onItemsDelete: any;
  nextInvoice: () => void;
  prevInvoice: () => void;
  setPageLoading: (val: boolean) => void;
};

function InvoiceFormContainer({
  invoice,
  setInvoiceCalc,
  products,
  onInputChange,
  onDateTimeChange,
  onSaveAsDraft,
  onSaveAndPublish,
  onItemsChange,
  onItemsDelete,
  nextInvoice,
  prevInvoice,
  setPageLoading,
}: InvoiceFormContainerProps) {
  const { userType } = getSession() as Session;
  const classes = useStyles();
  const [formRequired, setFormRequired] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState("");

  const keysToIncludeCalc = ["sgst", "cgst", "total"];

  useEffect(() => {
    (async () => {
      if (
        isLoading === true &&
        (invoice.order_status !== 1 || userType === "super_admin")
      ) {
        if (formRequired) {
          showConfirm();
        } else {
          await onSaveAsDraft();
          setLoading(false);
          setDisabledButton("");
        }
      }
    })();
  }, [isLoading]);

  function showConfirm() {
    confirm({
      title: "Do you really want to Publish this Invoice?",
      icon: <ExclamationCircleOutlined />,
      content: "Twice check before publishing.!",
      onOk() {
        (async () => {
          await onSaveAndPublish();
          setLoading(false);
          setDisabledButton("");
        })();
      },
      onCancel() {
        setLoading(false);
        setDisabledButton("");
      },
    });
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (invoice.order_status !== 1 || userType === "super_admin") {
      if (formRequired) {
        setDisabledButton("saveAsDraft");
      } else {
        setDisabledButton("saveAndPublish");
      }
    }
  };

  const calculateInvoiceTotal = (data: any) => {
    let newData: any = JSON.parse(JSON.stringify(data));
    let tempData: any = { sub_total: 0, discount: 0, gst: 0, total: 0 };

    for (let i = 0; i < newData.length; i++) {
      let dataObj = newData[i];
      for (var key in dataObj) {
        if (dataObj.hasOwnProperty(key) && keysToIncludeCalc.includes(key)) {
          dataObj[key] = dataObj[key] === "" ? 0 : parseFloat(dataObj[key]);
        }
      }

      const { cgst, sgst, total } = dataObj;

      let gstPercentage = cgst + sgst;
      let gstAmount = (total * gstPercentage) / (100 + gstPercentage);
      tempData["sub_total"] += total;
      tempData["gst"] += gstAmount;
      tempData["total"] += total;
    }

    let discount =
      invoice?.discount !== "" &&
      invoice?.discount !== undefined &&
      invoice?.discount !== null
        ? parseFloat(invoice.discount)
        : 0;
    let roundOff =
      invoice?.round_off !== "" &&
      invoice?.round_off !== undefined &&
      invoice?.round_off !== null
        ? parseFloat(invoice.round_off)
        : 0;

    tempData["total"] = tempData["total"] - discount + roundOff;

    tempData = {
      round_off: roundOff,
      discount: discount,
      sub_total: tempData["sub_total"].toFixed(2),
      gst: tempData["gst"].toFixed(2),
      total: tempData["total"].toFixed(2),
    };

    setInvoiceCalc(tempData);
  };

  return (
    <form action="#" onSubmit={handleSubmit}>
      <Row
        className={classes.invoiceFormContainer}
        style={{ flexDirection: "row-reverse" }}
      >
        <Col span={18}>
          <InvoiceTable
            items={products}
            calculateInvoiceTotal={calculateInvoiceTotal}
            required={formRequired}
            onChange={onItemsChange}
            onDelete={onItemsDelete}
            disableAddRow={
              invoice.order_status === 1 && userType !== "super_admin"
            }
          />
        </Col>

        <Col span={6}>
          <Card size="small" className={classes.inputGroupCard}>
            <Row justify="space-between">
              <Col span={24} className={classes.recordNoWrapper}>
                <Title level={4}>Record No :</Title>
                <Button
                  type="text"
                  shape="circle"
                  icon={<CaretLeftOutlined />}
                  onClick={() => {
                    prevInvoice();
                    setPageLoading(true);
                  }}
                />
                <Title level={4}>
                  {invoice.order_id || invoice.incoming_order_id}
                </Title>
                <Button
                  type="text"
                  shape="circle"
                  icon={<CaretRightOutlined />}
                  onClick={() => {
                    nextInvoice();
                    setPageLoading(true);
                  }}
                />
              </Col>
              <Col span={24} style={{ padding: "5px 0" }}>
                <Title type="secondary" level={5}>
                  Status :{" "}
                  {invoice.order_status === 1
                    ? "Published"
                    : invoice.order_status === 0
                    ? "Draft"
                    : invoice.incoming_order_status !== null
                    ? invoice.incoming_order_status
                    : " --- "}
                </Title>
              </Col>
            </Row>
            <div className={classes.inputWithSpan}>
              <span>Customer Name : </span>
              <Input
                size="middle"
                onChange={onInputChange("customer_name")}
                required={formRequired}
                value={invoice.customer_name}
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>Phone Number : </span>
              <Input
                size="middle"
                type="tel"
                required
                onChange={onInputChange("mobile_number")}
                value={
                  invoice.mobile_number !== "null" ? invoice.mobile_number : ""
                }
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>Invoice Number : </span>
              <Input
                size="middle"
                type="text"
                required
                onChange={onInputChange("invoice_number")}
                value={
                  invoice.invoice_number !== "null"
                    ? invoice.invoice_number
                    : ""
                }
              />
            </div>
            <div className={classes.dateTimePicker}>
              <span>Invoice Date : </span>
              {invoice.invoice_date !== null && invoice.invoice_date ? (
                <DatePicker
                  inputReadOnly
                  size="middle"
                  allowClear={false}
                  format="YYYY-MM-DD"
                  value={moment(invoice.invoice_date)}
                  onChange={onDateTimeChange}
                />
              ) : (
                <DatePicker
                  inputReadOnly
                  format="YYYY-MM-DD"
                  onChange={onDateTimeChange}
                />
              )}
            </div>
          </Card>
          <Card size="small" className={classes.inputGroupCard}>
            <div className={classes.inputWithSpan}>
              <span>Sub Total :</span>
              <Input
                size="middle"
                type="number"
                step="any"
                // prefix="  ₹"
                required={formRequired}
                onChange={onInputChange("sub_total")}
                value={invoice.sub_total}
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>Discount :</span>
              <Input
                size="middle"
                type="number"
                step="any"
                // prefix="  ₹"
                required={formRequired}
                onChange={onInputChange("discount")}
                value={invoice.discount}
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>GST :</span>
              <Input
                size="middle"
                type="number"
                step="any"
                required={formRequired}
                // prefix="  ₹"
                onChange={onInputChange("gst")}
                value={invoice.gst}
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>Round Off :</span>
              <Input
                size="middle"
                type="number"
                step="any"
                required={formRequired}
                // prefix="- ₹"
                onChange={onInputChange("round_off")}
                value={invoice.round_off}
              />
            </div>
            <div className={classes.inputWithSpan}>
              <span>Total amount :</span>
              <Input
                size="middle"
                type="number"
                step="any"
                // prefix="  ₹"
                required={formRequired}
                onChange={onInputChange("total")}
                value={invoice.total}
              />
            </div>
          </Card>
          {userType === "super_admin" && invoice.order_status === 1 ? (
            <Col span={24}>
              <Button
                block
                danger
                size="middle"
                type="primary"
                htmlType="submit"
                loading={isLoading && disabledButton !== "saveAndPublish"}
                disabled={disabledButton === "saveAndPublish"}
                onClick={() => {
                  setFormRequired(true);
                }}
              >
                Publish Again
              </Button>
            </Col>
          ) : (
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Button
                  block
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading && disabledButton !== "saveAsDraft"}
                  disabled={
                    invoice.order_status === 1 ||
                    disabledButton === "saveAsDraft"
                  }
                  onClick={() => {
                    setFormRequired(false);
                  }}
                >
                  Save as draft
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  danger
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading && disabledButton !== "saveAndPublish"}
                  disabled={
                    invoice.order_status === 1 ||
                    invoice.order_status !== 0 ||
                    disabledButton === "saveAndPublish"
                  }
                  onClick={() => {
                    setFormRequired(true);
                  }}
                >
                  Publish
                </Button>
              </Col>
            </Row>
          )}{" "}
        </Col>
      </Row>
    </form>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  invoiceFormContainer: {
    padding: [10, 0],
    flex: "1 1",
    width: "100%",
    "& > .ant-col > div": {
      marginBottom: "0.4rem",
    },
  },
  inputGroupCard: {
    border: "1px solid #838383",
    borderRadius: 5,
  },
  inputWithSpan: {
    marginBottom: "0.3rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& span": {
      width: 150,
      fontWeight: 500,
      fontSize: "0.8rem",
    },
    "& input": {
      width: 200,
      overflow: "hidden",
    },
  },

  dateTimePicker: {
    marginBottom: "0.3rem",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& .ant-picker": {
      width: 200,
    },

    "& > span": {
      fontSize: "0.8rem",
      width: 150,
      fontWeight: 500,
    },
  },
  recordNoWrapper: {
    display: "flex",
    alignItems: "center",
    "& .ant-typography": {
      margin: 0,
    },
  },
}));

export default InvoiceFormContainer;
