import axios from "axios";
import { TableFilters } from "../components/tables/ReviewInvoiceTable";
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjgiLCJpYXQiOjE2MTM3MTE2NTF9.eKf1lUwZ2xcsr8NEID7B5AoKbsZdjSX9YqlkXM905-Q";
const config: any = {
  method: "GET",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "x-access-token": `${token}`,
  },
};
const url = process.env.REACT_APP_API_NODE_BASE_URL;

export async function getAllOrders(
  type?: string,
  count?: number,
  orderToSkip?: number,
  filters?: TableFilters
) {
  const invoiceType = type ? type : "all";
  const endpoint = `/getAllOrders?orders_to_skip=${orderToSkip ?? 0}&count=${
    count ?? 50
  }&type=${invoiceType}`;
  const result = await axios
    .post(url + endpoint, filters, config)
    .then((res) => res.data);
  return result;
}

export async function getOrdersByUserId(userId: number, storeId: number) {
  const endpoint = `/getOrders?user_id=${userId}&retail_store_id=${storeId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getOrderDetails(id: number) {
  const endpoint = "/getOrderById?order_id=";
  const result = await axios
    .get(url + endpoint + id, config)
    .then((res) => res.data[0]);
  return result;
}

export async function getOrderDetailsWitoutOrderId(incomingOrderId: number) {
  const endpoint = "/getIncomingOrderById?incoming_order_id=";
  const result = await axios
    .get(url + endpoint + incomingOrderId, config)
    .then((res) => res.data[0]);
  return result;
}

export async function getProductDetails(productSKu: string) {
  const endpoint = `/catalogue/products/${productSKu}?source=admin`;

  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);

  return result;
}

export async function getRetailStoreDetails(id: number) {
  const endpoint = "/getRetailStores?id=";
  const result = await axios
    .get(url + endpoint + id, config)
    .then((res) => res.data);

  return result;
}

export async function getOrderItems(id: number) {
  const endpoint = "/getOrderItems?order_id=";
  const result = await axios
    .get(url + endpoint + id, config)
    .then((res) => res.data);

  return result;
}

export async function updateOrderStatus(id: number, data: any) {
  const endpoint = `/updateOrderStatus?status=${data.status}&id=`;
  const result = await axios
    .put(url + endpoint + id, data, config)
    .then((res) => res.data);

  return result;
}

export async function sendPublishSMS(data: any) {
  let mobileNumber = window.location.hostname.includes("admindev.raseet.com")
    ? 7042046838
    : data.mobile_number;
  const endpoint = `/sendSMS?number=${mobileNumber}&customerName=${data.customerName}&retailStoreName=${data.retailStoreName}&orderTotal=${data.orderTotal}&orderLink=${data.orderLink}&orderId=${data.orderId}`;

  const result = await axios
    .get(url + endpoint, config) //+ id
    .then((res) => res.data);
}

export async function updateOrderFromAdmin(id: number, data: any) {
  const endpoint = "/updateOrderFromAdmin?id=";
  const result = await axios
    .put(url + endpoint + id, data, config)
    .then((res) => res.data);
  return result;
}

export async function updateOrderItemFromAdmin(id: number, data: any) {
  const endpoint = "/updateOrderItemFromAdmin?id=";
  const result = await axios
    .put(url + endpoint + id, data, config)
    .then((res) => res.data);
  return result;
}

export async function updateProductFromAdmin(id: number, data: any) {
  const endpoint = "/updateProductFromAdmin?id=";
  const result = await axios
    .put(url + endpoint + id, data, config)
    .then((res) => res.data);
  return result;
}

export async function deleteOrderItemFromAdmin(id: number) {
  const endpoint = "/deleteOrderItemFromAdmin?id=";
  const result = await axios
    .put(url + endpoint + id, config)
    .then((res) => res.data);
  return result;
}

export async function createOrUpdateInvoice(data: any) {
  const endpoint = "/createOrUpdateInvoice";
  return await axios.post(url + endpoint, data, config).then((res) => res.data);
  // return result;
}

export async function generateInvoiceImageUrl(filename: string) {
  const endpoint = "/generateOrderGetUrl?Bucket=raseetipp&Key=";
  const result = await axios
    .get(url + endpoint + filename, config)
    .then((res) => res.data.getURL);
  return result;
}

export async function getAllRetailStores(
  count?: number,
  rows_to_skip = 0,
  catalogue_type = ""
) {
  const endpoint = `/getAllRetailStores?rows_to_skip=${rows_to_skip}&limit=${
    count ?? 500
  }&catalogue_type=${catalogue_type}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getRetailStoreCustomers(
  retailStoreId: number,
  search?: any,
  limit?: number,
  page?: number
) {
  const endpoint = `/getRetailStoreCustomers?limit=${limit || 100}&page=${
    page || ""
  }&retail_store_id=${retailStoreId}&search=${search || ""}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data?.items || []);
  return result;
}

export async function getProductCatalogue(
  retailStoreId: number,
  userId: number | undefined,
  page: number,
  limit: number,
  search?: string
) {
  let endpoint = `/catalogue/products?page=${page}&limit=${limit}&name=${search}`;

  if (!!userId) {
    endpoint += `&user_id=${userId}`;
  }
  if (!!retailStoreId) {
    endpoint += `&retail_store_id=${retailStoreId}`;
  }

  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function insertUser(data: any) {
  const endpoint = `/insertUsers`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertCustomer(data: any) {
  const endpoint = `/insertInactiveCustomer`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getCustomer(userId: any) {
  const endpoint = `/getCustomer?user_id=${userId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getUserById(userId: any) {
  const endpoint = `/getUserById?user_id=${userId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getCustomerReorders(
  userId: number,
  retailStoreID: number,
  limit?: number,
  page?: number,
  status?: string
) {
  const endpoint = `/getCustomerReorders?user_id=${userId}&retail_store_id=${retailStoreID}&limit=${
    limit ?? ""
  }&page=${page ?? ""}&status=${status}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getReordersSummary(
  retailStoreID: number,
  userId?: number
) {
  const endpoint = `/getReordersSummary?retail_store_id=${retailStoreID}&user_id=${
    userId ?? ""
  }`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getReordersByRetailStore(
  retailStoreId: number,
  limit?: number,
  page?: number,
  status?: string
) {
  const endpoint = `/getReorders?retail_store_id='${retailStoreId}'&limit=${
    limit ?? ""
  }&page=${page ?? ""}&status=${status}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getProducts(
  pid: number,
  retailStoreId: number,
  userId: number
) {
  const endpoint = `/catalogue/products/${pid}?retail_store_id=${retailStoreId}&user_id=${userId}&source=admin`;

  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getProductDetailsById(id: number) {
  const endpoint = "/getProducts?id=";
  const result = await axios
    .get(url + endpoint + id, config)
    .then((res) => res.data);

  return result;
}

export async function deleteCart(id: number) {
  const endpoint = `/deleteCart?id=${id}`;
  const result = await axios
    .delete(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function deleteCartItems(id: number) {
  const endpoint = `/deleteCartItems?id=${id}`;
  const result = await axios
    .delete(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function deleteAllCartItems(cart_id: number) {
  const endpoint = `/deleteAllCartItems?cart_id=${cart_id}`;
  const result = await axios
    .delete(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function deleteReorder(reorder_id: number) {
  const endpoint = `/deleteReorders?id=${reorder_id}`;
  const result = await axios
    .delete(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function deleteAllReorderItems(reorder_id: number) {
  const endpoint = `/deleteAllReorderItems?reorder_id=${reorder_id}`;
  const result = await axios
    .delete(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getReorderById(id: any) {
  const endpoint = `/getReorderById?reorder_id='${id}'`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getReorderItems(reorderId: any) {
  const endpoint = `/getReorderItems?reorder_id=${reorderId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function insertReorder(data: any) {
  const endpoint = `/insertReorder`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertReorderItems(data: any) {
  const endpoint = `/insertReorderItems`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertCart(data: any) {
  const endpoint = `/insertCart`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertCartItems(data: any) {
  const endpoint = `/insertCartItems`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getCart(cartId: number) {
  const endpoint = `/getCart?id='${cartId}'`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getCartItems(cartId: any) {
  const endpoint = `/getCartItems?cart_id=${cartId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getShipping(retailerId: number, cartId?: number) {
  const endpoint = `/shipping/get?retail_store_id=${retailerId}&cart_id=${
    cartId ?? ""
  }`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function updateCartItemsByProductId(
  cart_id: number,
  product_id: number,
  data: any
) {
  const endpoint = `/updateCartItemsProduct?cart_id=${cart_id}&product_id=${product_id}`;
  const result = await axios
    .put(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertReorderLoyalty(data: any) {
  const endpoint = `/loyalty/reorder`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getCartLoyaltyInfo(
  cartId: number,
  retailStoreId: number,
  userId: number
) {
  const endpoint = `/loyalty/get/info?retail_store_id=${retailStoreId}&customer_id=${userId}&cart_id=${cartId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data)
    .catch((e) => console.log(e));
  return result;
}

export async function getCheckCart(retailStoreId: number, userId: number) {
  const endpoint = `/getCheckCart?retail_store_id=${retailStoreId}&user_id=${userId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function updateAddresses(
  addressable_id: number,
  addressable_type: string,
  data: any
) {
  const endpoint = `/updateAddresses?addressable_id=${addressable_id}&addressable_type='${addressable_type}'`;
  const result = await axios
    .put(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function insertAddresses(data: any) {
  const endpoint = `/insertAddresses`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function uploadImage(url: string, file: File) {
  return await fetch(url, {
    method: "PUT",
    body: file,
    redirect: "follow",
    headers: {
      "Content-Type": file.type,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => console.log(response.status))
    .then((result) => console.log("Success"))
    .catch((err) => {
      console.log("err", err);
    });
}

export async function insertPrescription(data: any) {
  const endpoint = `/insertPrescription`;

  let body = {
    user_id: data?.userId,
    retail_store_id: data?.retailStoreId,
    prescription_data: data?.prescriptionData,
  };

  const result = await axios
    .post(url + endpoint, body, config)
    .then((res) => res.data);
  return result;
}

export async function generatePutUrl(key: string, type: string) {
  const endpoint = `/generatePutUrl?Key=${key}&ContentType=${type}&BucketDirName=prescriptionImages`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function addProduct(data: any) {
  const endpoint = `/catalogue/products`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function editProduct(product_id: string, data: any) {
  const endpoint = `/catalogue/products?product_id=${product_id}`;

  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function insertProduct(data: any) {
  const endpoint = `/insertProducts`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function updateProduct(pid: number, data: any) {
  const endpoint = `/updateProducts?product_id=${pid}`;

  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function getBrands() {
  const endpoint = `/catalogue/brand`;

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function addBrand(brandName: string) {
  const endpoint = `/catalogue/brand`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify({
      brand_name: brandName,
    }),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function getProductCategories(vendorCode: string) {
  const endpoint = `/catalogue/category?vendor_code=${vendorCode}`;

  return await fetch(url + endpoint, {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function addProductCategory(category: string) {
  const endpoint = `/catalogue/category?vendor_code=${"RAS01"}`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify({
      category_name: category,
    }),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function createCoupon(data: any) {
  const endpoint = `/coupons/create`;

  return await fetch(url + endpoint, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function updateCoupon(
  couponId: number,
  retailStoreId: number,
  data: any
) {
  const endpoint = `/coupons/edit?coupon_id=${couponId}&retail_store_id=${retailStoreId}`;

  return await fetch(url + endpoint, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "x-access-token": `${token}`,
    },
  }).then((res) => res.json());
}

export async function getCoupons(
  retailStoreId: any = "",
  userId: any = "",
  search: any = "",
  count: any = "",
  offset: any = ""
) {
  const endpoint = `/coupons/get?type="admin"&user_id=${userId}&retail_store_id=${retailStoreId}&search=${search}&count=${count}&offset=${offset}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getCustomerCoupons(
  retailStoreId: number,
  cartAmount: number,
  userId: number
) {
  const endpoint = `/coupons/get?type=customer&retail_store_id=${retailStoreId}&cart_amount=${cartAmount}&user_id=${userId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function getCouponById(couponId: number) {
  const endpoint = `/coupons/get?type="admin"&coupon_id=${couponId}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function toggleCouponStatus(
  active: boolean,
  couponId: number,
  retailStoreId: number
) {
  const endpoint = `/coupons/toggle?coupon_id=${couponId}&retail_store_id=${retailStoreId}&active=${active}`;
  const result = await axios
    .put(url + endpoint, {}, config)
    .then((res) => res.data);
  return result;
}

export async function applyCoupon(coupon_code: string, cart_id: number) {
  const endpoint = `/coupons/apply`;
  const result = await axios
    .post(url + endpoint, { coupon_code, cart_id }, config)
    .then((res) => res.data);
  return result;
}

export async function getUsers(mobile_number: any) {
  const endpoint = `/getUsers?mobile_number=` + mobile_number;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function insertRetailStoreUsersMapping(
  retail_store_id: number,
  mobile_number: any
) {
  const endpoint = `/insertRetailStoreUsersMapping?retail_store_id=${retail_store_id}&mobile_number=${mobile_number}`;
  const result = await axios
    .post(url + endpoint, { retail_store_id, mobile_number }, config)
    .then((res) => res.data);
  return result;
}

export async function reconcileMap(data: any) {
  const endpoint = `/reconcile/map`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function reconcileSettle(data: any) {
  const endpoint = `/reconcile/settle`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getMarketingCampaigns(id: any) {
  const endpoint = `/marketing/campaign?retail_store_id=${id}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}

export async function editMarketingCampaign(id: any, data: any) {
  const endpoint = `/marketing/campaign/${id}/edit`;
  const result = await axios
    .put(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function createMarketingCampaign(data: any) {
  const endpoint = `/marketing/campaign`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getMarketingTargetAudience(
  id: any,
  data?: any,
  limit?: number,
  page?: number
) {
  const endpoint = `/marketing/target-audience?retail_store_id=${id}&limit=${limit}&page=${page}`;
  const result = await axios
    .post(url + endpoint, data, config)
    .then((res) => res.data);
  return result;
}

export async function getFilteredAilments(id: any, filterType: any) {
  const endpoint = `/marketing/filtered-ailments?retail_store_id=${id}&filter_type=${filterType}`;
  const result = await axios
    .get(url + endpoint, config)
    .then((res) => res.data);
  return result;
}
