import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Card from "antd/es/card";
import Title from "antd/es/typography/Title";
import message from "antd/es/message";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { addProduct, createCoupon, insertProduct } from "../../api/invoiceApis";
import AddProductForm from "../../components/forms/RaseetProductForm";
import { useLocation } from "react-router-dom";
import AddRetailerProductForm from "../../components/forms/RetailerProductForm";

const defaultValues = {
  retail_store_id: undefined,
  product_name: "",
  brand_id: undefined,
  category_id: undefined,
  prescription: undefined,
  catalogue_type: undefined,
  pack_size: "",
  vendor: "",
  vendor_code: "",
  product_domain: "",
  dosage_form: "",
  pack: "",
  price: "",
  pack_price: "",
  description: "",
  manufacturer: "",
  salt_composition: "",
  drug_info: "",
  when_to_use: "",
  how_to_use: "",
  benefits: "",
  side_effects: "",
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AddProduct() {
  const query = useQuery();
  const classes = useStyles();
  const [storeId, setStoreId] = useState(0);

  const handleOnSubmitOC = async ({ product_name, ...values }: any) => {
    const data = {
      ...values,
      status: 2,
      name: product_name,
      retail_store_id: storeId,
    };

    await insertProduct(data)
      .then((res) => {
        console.log(res);
        if (!!res?.[0]?.id) {
          message.success("Product created successfully!");
          // window.location.reload();
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  const handleOnSubmitRC = async ({
    drug_info,
    when_to_use,
    how_to_use,
    benefits,
    side_effects,
    ...values
  }: any) => {
    const data = {
      ...values,
      catalogue_type: "RASEET",
      product_attributes: {
        drug_info,
        when_to_use,
        how_to_use,
        benefits,
        side_effects,
      },
    };

    if (data.prescription === "false") {
      data.prescription = null;
    }

    await addProduct(data)
      .then((res) => {
        console.log(res);
        if (!!res?.product?.id) {
          message.success("Product created successfully!");
          // window.location.reload();
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  useEffect(() => {
    let queryStore = query.get("store");
    // @ts-ignore
    setStoreId(+queryStore ?? 0);
  }, []);

  return (
    <div className={classes.addCouponPage}>
      <Card>
        <Title level={3} className={classes.pageTitle}>
          <PlusCircleOutlined />
          Add Product
        </Title>
        {!!storeId ? (
          <AddRetailerProductForm
            onSubmit={handleOnSubmitOC}
            defaultValues={defaultValues}
          />
        ) : (
          <AddProductForm
            onSubmit={handleOnSubmitRC}
            defaultValues={defaultValues}
          />
        )}
      </Card>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCouponPage: {
    height: "100vh",
    padding: [20, "7vw"],
    background: "#f9f9f9",

    "& .ant-card": {
      boxShadow:
        "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    },
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    "& .anticon": {
      marginRight: 10,
    },
  },
}));

export default AddProduct;
