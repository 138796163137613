import { message, Modal } from "antd";
import { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import { getCustomer, updateAddresses } from "../api/invoiceApis";
import CustomerAddressForm from "./forms/CustomerAddressForm";

function AddressModal({ visible, onSave, onClose, customerUserId }: any) {
  const classes = useStyles();
  const [pageData, setPageData] = useState({
    loading: true,
    customer: {
      id: "",
    },
    defaultValues: {
      streetAddress: "",
      locality: "",
      pincode: "",
      city: "",
      state: "",
    },
  });

  useEffect(() => {
    if (pageData.loading) {
      fetchInitialData();
    }
  }, []);

  const checkEmpty = (value: any) => (value === " " ? "" : value);

  const fetchInitialData = async () => {
    const customer = await getCustomer(customerUserId);
    if (customer.length) {
      setPageData({
        loading: false,
        customer: customer[0],
        defaultValues: {
          streetAddress: checkEmpty(customer[0].line1),
          locality: checkEmpty(customer[0].landmark),
          pincode: checkEmpty(customer[0].pincode),
          city: checkEmpty(customer[0].city),
          state: checkEmpty(customer[0].state),
        },
      });
    }
  };

  const handleFormSubmit = async (values: any) => {
    let address = {
      line1: `'${values?.streetAddress || " "}'`,
      line2: "''",
      landmark: `'${values?.locality || " "}'`,
      pincode: `'${values?.pincode || " "}'`,
      city: `'${values?.city || " "}'`,
      state: `'${values?.state || " "}'`,
    };
    console.log(values);
    let updatedAddress = await updateAddresses(
      +pageData.customer?.id,
      "Customer",
      address
    );
    message.info("Successfully Updated");
    onSave(updatedAddress[0]);
  };

  return (
    <Modal
      visible={visible}
      title={`Complete Your Address`}
      centered
      footer={false}
      onCancel={onClose}
    >
      <CustomerAddressForm
        onSubmit={handleFormSubmit}
        defaultValues={pageData.defaultValues}
      />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({}));

export default AddressModal;
