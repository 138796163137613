import { Button, Card, Col, DatePicker, Input, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import InvoiceTable from "../../components/InvoiceTable";
import { useParams } from "react-router";
import getInvoiceDetails from "../../utils/getInvoiceDetails";
import { generateInvoiceImageUrl } from "../../api/invoiceApis";
import moment from "moment";
import saveAsDraft from "../../utils/saveAsDraft";
import { useHistory } from "react-router-dom";
import saveAndPublished from "../../utils/saveAndPublish";
import Loader from "./../../components/Loader";
import ImageContainer from "./../../components/ImageContainer";
import InvoiceFormContainer from "./../../components/InvoiceFormContainer";
import { getSession, Session } from "../../client/reactives/session";

function AddInvoice({
  nextInvoice,
  prevInvoice,
  refreshInvoices,
  setActiveInvoiceType,
}: any) {
  const classes = useStyles();
  const [invoice, setInvoice] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [invoiceImageUrl, setInvoiceImageUrl] = useState<any>("");
  const { replace } = useHistory();
  const { recordId } = useParams<any>();
  const { userName } = getSession() as Session;

  const keysToIncludeCalc = ["sub_total", "gst", "discount", "round_off"];

  useEffect(() => {
    setActiveInvoiceType("Incoming");
  }, []);

  // fetching invoice details
  useEffect(() => {
    (async () => {
      const result = await getInvoiceDetails(recordId, true);
      if (result?.order_id !== null && result?.order_id !== undefined) {
        return replace("/admin/dashboard/review-invoices/" + result.order_id);
      }
      const imageKey = result.filename || result.s3_key;
      const invoiceImagURL = await generateInvoiceImageUrl(imageKey);
      setInvoice(result);
      setInvoiceImageUrl(invoiceImagURL);
      setLoading(false);
    })();
  }, [recordId]);

  // set Invoice calculations
  const setInvoiceCalc = (newData: any) => {
    setInvoice({ ...invoice, ...newData });
  };

  const calculateInvoice = (data: any) => {
    let newData: any = JSON.parse(JSON.stringify(data));
    let tempData: any = JSON.parse(JSON.stringify(data));

    for (var key in tempData) {
      if (tempData.hasOwnProperty(key) && keysToIncludeCalc.includes(key)) {
        tempData[key] =
          tempData[key] === "" || tempData[key] === null
            ? 0
            : parseFloat(tempData[key]);
      }
    }
    const { sub_total } = tempData;

    let overalldiscount =
      newData?.discount !== undefined ? parseFloat(newData.discount) : 0;
    let roundOff =
      newData?.round_off !== undefined ? parseFloat(newData.round_off) : 0;
    let newTotal = sub_total - overalldiscount + roundOff;
    newData["total"] = newTotal.toFixed(2);
    setInvoice(newData);
  };

  // handle items edit/change
  const handleItemsChange = (items: any, id: number) => {
    setProducts([...items]);
  };

  // handle input fields change
  const onInputChange = (key: string) => (e: any) => {
    let newData: any = { ...invoice };
    newData[key] = e.target.value;
    if (keysToIncludeCalc.includes(key)) {
      calculateInvoice(newData);
    } else {
      setInvoice(newData);
    }
  };

  // handle dateTime Change
  const onDateTimeChange = (value: any, dateString: any) => {
    setInvoice({ ...invoice, invoice_date: dateString, moment_date: value });
  };

  const handleSaveAsDraft = async () => {
    const {
      mobile_number,
      customer_name,
      retail_store_id,
      filename,
      incoming_order_id,
    } = invoice;
    const data = {
      incoming_order_id,
      order: {
        s3_key: filename,
        invoice_mapping_id: "1",
        ...invoice,
        status: 0,
        last_action_by: userName,
      },
      customer: {
        name: customer_name,
        mobile_number,
      },
      products: products.map((obj: any) => ({
        ...obj,
        retail_store_id,
        status: "2",
      })),
      retail_store_id,
    };

    const result = await saveAsDraft(data, (res: any) => {
      refreshInvoices();
      replace("/admin/dashboard/review-invoices/" + res.order);
    });
  };

  const handleSaveAndPublish = async () => {
    const {
      mobile_number,
      customer_name,
      retail_store_id,
      filename,
      incoming_order_id,
    } = invoice;
    const data = {
      incoming_order_id,
      order: {
        s3_key: filename,
        invoice_mapping_id: "1",
        ...invoice,
        status: 0,
        last_action_by: userName,
      },
      customer: {
        name: customer_name,
        mobile_number,
      },
      products: products.map((obj: any) => ({
        ...obj,
        retail_store_id,
        status: "2",
      })),
      retail_store_id,
    };

    const result = await saveAndPublished(data, (res: any) => {
      refreshInvoices();
      replace("/admin/dashboard/review-invoices/" + res.order);
    });
  };

  return (
    <div className={classes.addInvoicePage}>
      {!loading ? (
        <Card size="small" className={classes.addInvoicePageInner}>
          <ImageContainer
            images={invoice?.file_urls}
            imageSrc={invoiceImageUrl}
            nextInvoice={nextInvoice}
            prevInvoice={prevInvoice}
            setPageLoading={setLoading}
          />
          <InvoiceFormContainer
            invoice={invoice}
            setInvoiceCalc={setInvoiceCalc}
            products={products}
            nextInvoice={nextInvoice}
            prevInvoice={prevInvoice}
            setPageLoading={setLoading}
            onInputChange={onInputChange}
            onDateTimeChange={onDateTimeChange}
            onSaveAsDraft={handleSaveAsDraft}
            onSaveAndPublish={handleSaveAndPublish}
            onItemsChange={handleItemsChange}
            onItemsDelete={handleItemsChange}
          />{" "}
        </Card>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addInvoicePage: {
    minHeight: "100%",
    width: "100%",
    padding: 10,
    background: "#F7F7F7",
  },
  addInvoicePageInner: {
    height: "100%",
    rowGap: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFFFF",
    boxShadow:
      "1px 1px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",

    "& .ant-card-body": {
      height: "100%",
      width: "100%",
    },
  },
}));

export default AddInvoice;
