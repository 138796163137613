import Button from "antd/es/button";
import React from "react";
import { createUseStyles } from "react-jss";
import { message } from "antd";
import FormBox from "../components/FormBox"
import OnboardingFormFooter from "../components/OnboardingFormFooter";
import LoginForm from "../components/forms/LoginForm";
import OnboardingWrapper from "../components/OnboardingWrapper";
//import useSubmitHandler from "../hooks/useSubmitHandler";
import { getSession, setSession } from "../client/reactives/session";
import { useCreateSessionMutation } from "../generated/schema";
import Space from "antd/es/space";
import RaseetFinalLogo from "../assets/Raseet-final-logo.jpg"

import { useHistory } from 'react-router-dom'

const useStyles = createUseStyles(({ colors }: Theme) => ({
  finalLogo: {
    width: "7rem",
    height: "8rem",
    marginBottom: "2.5rem",
  },
}));

type LoginPageProps = {
  isAdmin?: boolean;
};

function LoginPage({ isAdmin }: LoginPageProps) {
  const { push } = useHistory()
  const [createSession] = useCreateSessionMutation({
    onCompleted: ({ createSession: { token } }) => {
      //setSession(token)
    },
  });

  const classes = useStyles();

  ///const handleCreateSession = useSubmitHandler(createSession)
  const handleCreateSession = async ({ ...values }) => {
    const url = process.env.REACT_APP_API_NODE_BASE_URL;
    let data = {
      email: `${values.email}`,
      password: `${values.password}`,
    };
    await fetch(url + "/adminLogin", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.auth) {
          setSession(json.token, json.user_id, json.user_type, json.user_name, json.mobile_number);
        } else {
          message.info(json.error);
        }
      }).finally(() => {
        push('/admin/dashboard')
      });
  };
  return (
    <OnboardingWrapper height='full'>
      <Space direction='vertical' align='center' style={{ width: "100%" }}>
        <img
          className={classes.finalLogo}
          src={RaseetFinalLogo}
        />
      </Space>
      <FormBox title=''>
        <LoginForm
          onSubmit={handleCreateSession}
          isAdmin={isAdmin}
          defaultValues={{
            isAdmin,
          }}
        />
        <OnboardingFormFooter>
          <Button style={{ color: "#2A9D8F" }} type='link' href='/forgot-password'>
            Forgot password?
          </Button>
        </OnboardingFormFooter>
      </FormBox>
    </OnboardingWrapper>
  );
}

export default LoginPage;
