import {
  Route,
  useRouteMatch,
  Redirect,
  Switch,
  useParams,
  useHistory,
} from "react-router-dom";
import AdminDashboardLayout from "../../components/AdminDashboardLayout";
import ReviewInvoices from "./ReviewInvoices";
import InvoiceDetails from "./InvoiceDetails";
import AddInvoice from "./AddInvoice";
import { message } from "antd";
import { useEffect, useState } from "react";
import { getInvoicesByType } from "../../utils/getInvoices";
import PlaceOrder from "./PlaceOrder";
import CartPage from "./CartPage";
import ReordersPage from "./ReordersPage";
import ReorderSummary from "./ReorderSummary";
import { getAllRetailStores } from "../../api/invoiceApis";
import AddCoupon from "./AddCoupon";
import CouponsPage from "./CouponsPage";
import EditCoupon from "./EditCoupon";
import AddProduct from "./AddProduct";
import ManageProducts from "./ManageProducts";
import EditProduct from "./EditProduct";
import MarketingCampaigns from "./MarketingCampaigns";
import MarketingCampaignSetupPage from "./MarketingCampaignSetup";

function Index() {
  const { path } = useRouteMatch("/admin/dashboard") || {};
  const { recordId } = useParams<any>();
  const { push } = useHistory();

  const [activeInvoiceType, setActiveInvoiceType] = useState("");
  const [activeInvoiceIndex, setActiveInvoiceIndex] = useState(0);
  const [orders, setOrders] = useState<any>({
    Incoming: [],
    Draft: [],
    Published: [],
  });

  const refreshInvoices = async () => {
    let incomingOrders = await getInvoicesByType("Incoming", null, 50);
    let incomingOrdersIds = incomingOrders.map(
      (obj: any) => obj.incoming_order_id
    );
    setOrders((curr: any) => ({ ...curr, Incoming: incomingOrdersIds }));
  };

  useEffect(() => {
    refreshInvoices();
  }, []);

  useEffect(() => {
    if (activeInvoiceType !== "") {
      if (recordId) {
        let index = orders[activeInvoiceType].findIndex(recordId);
        index !== -1 && setActiveInvoiceIndex(index);
      } else if (orders[activeInvoiceType].length) {
        setActiveInvoiceIndex(0);
      }
    }
  }, [activeInvoiceType]);

  const getBaseUrl = () => {
    if (activeInvoiceType === "Incoming") {
      return "/admin/dashboard/add-invoice/";
    } else {
      return "/admin/dashboard/review-invoices/";
    }
  };

  const nextInvoice = () => {
    console.log(orders[activeInvoiceType], activeInvoiceType);
    if (orders[activeInvoiceType].length - activeInvoiceIndex !== 1) {
      setActiveInvoiceIndex(activeInvoiceIndex + 1);
      push(getBaseUrl() + orders[activeInvoiceType][activeInvoiceIndex + 1]);
    } else {
      setActiveInvoiceIndex(0);
      push(getBaseUrl() + orders[activeInvoiceType][0]);
    }
  };

  const prevInvoice = () => {
    if (activeInvoiceIndex !== 0) {
      setActiveInvoiceIndex(activeInvoiceIndex - 1);
      push(getBaseUrl() + orders[activeInvoiceType][activeInvoiceIndex - 1]);
    } else if (orders[activeInvoiceType].length) {
      setActiveInvoiceIndex(orders[activeInvoiceType].length - 1);
      push(
        getBaseUrl() +
          orders[activeInvoiceType][orders[activeInvoiceType].length - 1]
      );
    }
  };

  return (
    <Switch>
      <AdminDashboardLayout>
        <Route
          path={path}
          exact
          render={() => <Redirect to={"/admin/dashboard/review-invoices"} />}
        />
        <Route
          exact
          path={"/admin/dashboard/review-invoices"}
          render={() => <ReviewInvoices setOrders={setOrders} />}
        />
        <Route
          exact
          path={"/admin/dashboard/review-invoices/:recordId"}
          render={() => (
            <InvoiceDetails
              nextInvoice={nextInvoice}
              prevInvoice={prevInvoice}
              refreshInvoices={refreshInvoices}
              setActiveInvoiceType={setActiveInvoiceType}
            />
          )}
        />
        <Route
          exact
          path={"/admin/dashboard/add-invoice"}
          render={() => {
            if (orders.Incoming.length) {
              return (
                <Redirect
                  to={`/admin/dashboard/add-invoice/${orders.Incoming[activeInvoiceIndex]}`}
                />
              );
            } else {
              message.info("No Incoming Orders.!");
              return <Redirect to={"/admin/dashboard/review-invoices"} />;
            }
          }}
        />
        <Route
          exact
          path={"/admin/dashboard/add-invoice/:recordId"}
          render={() => (
            <AddInvoice
              nextInvoice={nextInvoice}
              prevInvoice={prevInvoice}
              refreshInvoices={refreshInvoices}
              setActiveInvoiceType={setActiveInvoiceType}
            />
          )}
        />
        <Route
          exact
          path={"/admin/dashboard/place-order"}
          render={() => <PlaceOrder />}
        />
        <Route
          exact
          path={"/admin/dashboard/place-order/:cartId"}
          render={() => <CartPage />}
        />
        <Route
          exact
          path={"/admin/dashboard/reorders"}
          render={() => <ReordersPage />}
        />
        <Route
          exact
          path={"/admin/dashboard/reorders/:reorderId"}
          render={() => <ReorderSummary />}
        />
        <Route
          exact
          path={"/admin/dashboard/add-coupon"}
          render={() => <AddCoupon />}
        />
        <Route
          exact
          path={"/admin/dashboard/coupons"}
          render={() => <CouponsPage />}
        />
        <Route
          exact
          path={"/admin/dashboard/coupons/:couponId"}
          render={() => <EditCoupon />}
        />
        <Route
          exact
          path={"/admin/dashboard/add-product"}
          render={() => <AddProduct />}
        />
        <Route
          exact
          path={"/admin/dashboard/edit-product/:productSku"}
          render={() => <EditProduct />}
        />
        <Route
          exact
          path={"/admin/dashboard/manage-products"}
          render={() => <ManageProducts />}
        />
        <Route
          exact
          path={"/admin/dashboard/marketing-campaigns"}
          render={() => <MarketingCampaigns />}
        />
        <Route
          exact
          path={"/admin/dashboard/marketing-campaigns/new"}
          render={() => <MarketingCampaignSetupPage />}
        />
      </AdminDashboardLayout>
    </Switch>
  );
}

export default Index;
