import { ShopOutlined } from "@ant-design/icons";
import { Card, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { getAllRetailStores } from "../api/invoiceApis";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface StoreSelectorCardProps {
  onStoreChange: (val: number) => void;
}

export default function StoreSelectorCard({
  onStoreChange,
}: StoreSelectorCardProps) {
  const classes = useStyle();
  const query = useQuery();
  const history = useHistory();
  const [stores, setStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [loading, setLoading] = useState(true);

  const handleStoreChange = (val: number) => {
    setSelectedStoreId(val);
    onStoreChange(val);
    history.replace(`${window.location.pathname}?store=${val}`);
  };

  const fetchRetailStores = async () => {
    let result = await getAllRetailStores(500);

    setStores(result);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchRetailStores();
    }
  }, []);

  useEffect(() => {
    let queryStore = query.get("store");
    //   @ts-ignore
    if (!!queryStore && +queryStore > 0) {
      setSelectedStoreId(+queryStore);
      onStoreChange(+queryStore);
    }
  }, []);

  return (
    <div className={classes.storeSelectorCard}>
      <div className={classes.cardHeader}>
        <ShopOutlined />
        <Typography.Text strong>Select Store</Typography.Text>
      </div>
      <Select
        showSearch
        value={+selectedStoreId || undefined}
        placeholder="Select Retailer"
        optionFilterProp="children"
        onChange={handleStoreChange}
        filterOption={(input, option: any) =>
          option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className={classes.searchSelect}
      >
        {stores.map((store: any) => (
          <Select.Option
            key={`#${store.id} - ${store.name}`}
            value={+store?.id}
          >
            #{store.id} - {store.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  storeSelectorCard: {
    width: "100%",
    marginBottom: 15,
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  cardHeader: {
    padding: [5, 15, 10],
    fontSize: 22,
    color: colors.textPrimary,

    "& .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  searchSelect: {
    width: "94%",
    margin: "5px 3% 10px",
    padding: 0,
    fontWeight: 600,
  },

  //   For smaller devices
  "@media (max-width: 768px)": {
    retailStoresCard: {
      padding: "0.25rem",
    },
    retailStoresCardTitle: {
      fontSize: 18,
      padding: "5px 15px 10px",

      "& .anticon": {
        fontSize: 18,
      },
    },
    searchSelect: {
      fontSize: 12,
    },
  },
}));
