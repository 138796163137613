import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {
  getAllRetailStores,
  getCoupons,
  getRetailStoreCustomers,
} from "../../api/invoiceApis";
import Loader from "../../components/Loader";
import CouponsCard from "../../components/CouponsCard";
import CustomersCard from "../../components/CustomersCard";
import RetailStoresCard from "../../components/RetailStoresCard";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CouponsPage() {
  // ------ other hooks
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const screen = useBreakpoint();
  const isLG = screen.lg;

  // ------ states
  const [retailStores, setRetailStores] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [pageData, setPageData] = useState({
    loading: true,
    tableLoading: false,
    customers: [],
    coupons: [],
    selectedStoreId: "",
    selectedCustomerUserId: "",
    searchInput: "",
    pageSize: 200,
    currentPage: 1,
    totalPages: 0,
  });

  // Fetch initial retail stores data
  useEffect(() => {
    fetchRetailStores();
    setPageData((curr) => ({
      ...curr,
      loading: false,
      selectedStoreId: query.get("store") || "",
      selectedCustomerUserId: query.get("customer") || "",
    }));
  }, []);

  // Fetch customers data on selected store change
  useEffect(() => {
    if (pageData.selectedStoreId !== "") {
      setCustomersLoading(true);
      history.replace(
        `/admin/dashboard/coupons?store=${pageData.selectedStoreId}&customer=${pageData.selectedCustomerUserId}`
      );
    }
  }, [pageData.selectedStoreId]);

  useEffect(() => {
    if (customersLoading) {
      fetchRetailStoreCustomers();
    }
  }, [customersLoading]);

  // Fetch coupons data on selected store change
  useEffect(() => {
    if (pageData.selectedStoreId !== "") {
      fetchCustomerCoupons();
      history.replace(
        `/admin/dashboard/coupons?store=${pageData.selectedStoreId}&customer=${pageData.selectedCustomerUserId}`
      );
    }
  }, [pageData.selectedCustomerUserId, pageData.selectedStoreId]);

  // ---
  // ---
  // ---

  const fetchRetailStores = async () => {
    let result = await getAllRetailStores(500);
    setRetailStores(result);
    await fetchCustomerCoupons();
  };

  const fetchRetailStoreCustomers = async (search?: string) => {
    let customerId = query.get("customer") || "";

    let result = await getRetailStoreCustomers(
      +pageData.selectedStoreId,
      search || ""
    );

    if (
      result.filter((customer: any) => customer.user_id === customerId)
        .length === 0
    ) {
      customerId = "";
      history.replace(
        `/admin/dashboard/coupons?store=${pageData.selectedStoreId}&customer=`
      );
    }

    setTimeout(() => {
      setCustomersLoading(false);
      setPageData((curr) => ({
        ...curr,
        selectedCustomerUserId: customerId,
        customers: result,
      }));
    }, 1000);
  };

  console.log(pageData.selectedStoreId);

  const fetchCustomerCoupons = async () => {
    let couponsList = await getCoupons(
      +pageData.selectedStoreId || "",
      +pageData.selectedCustomerUserId || "",
      pageData.searchInput,
      pageData.pageSize,
      pageData.currentPage - 1
    );
    setPageData((curr) => ({
      ...curr,
      loading: false,
      tableLoading: false,
      totalPages: couponsList.length,
      coupons: couponsList.reverse(),
    }));
  };

  const handleSelectedStoreChange = (value: string) => {
    setPageData((curr) => ({
      ...curr,
      selectedStoreId: value,
      selectedCustomerUserId: "",
    }));
  };

  const handleSelectedCustomerUserId = (value: string) => {
    setPageData((curr) => ({
      ...curr,
      tableLoading: true,
      selectedCustomerUserId: value,
    }));
  };

  const handlePaginationChange = (page: number) => {
    setPageData((curr) => ({
      ...curr,
      currentPage: page,
      tableLoading: true,
    }));
  };

  const handlePageSizeChange = async (current: number, size: number) => {
    setPageData((curr) => ({
      ...curr,
      currentPage: 1,
      pageSize: size,
    }));
  };

  const handleCouponsSearch = (value: string) =>
    setPageData((curr) => ({
      ...curr,
      currentPage: 1,
      tableLoading: true,
      searchInput: value,
    }));

  const handleCustomerSearch = async (searchValue: any) => {
    if (pageData.selectedStoreId !== "") {
      fetchRetailStoreCustomers(searchValue);
    }
  };

  if (pageData.loading) {
    return <Loader />;
  }

  return (
    <Row className={classes.couponsPage} gutter={20}>
      <Col span={isLG ? 7 : 24} className={classes.pageLeft}>
        <RetailStoresCard
          retailStores={retailStores}
          selectedStoreId={pageData.selectedStoreId}
          setSelectedStoreId={handleSelectedStoreChange}
        />
        <CustomersCard
          hideAddCustomer
          isLoading={customersLoading}
          onSearch={handleCustomerSearch}
          customers={pageData.customers}
          selectedStoreId={pageData.selectedStoreId}
          selectedCustomerUserId={pageData.selectedCustomerUserId}
          setSelectedCustomerUserId={handleSelectedCustomerUserId}
        />
      </Col>
      <Col span={isLG ? 17 : 24} className={classes.tableCard}>
        <CouponsCard
          coupons={pageData.coupons}
          tableLoading={pageData.tableLoading}
          handleCouponsSearch={handleCouponsSearch}
          selectedCustomerUserId={pageData.selectedCustomerUserId}
          paginationProps={{
            pageSize: pageData.pageSize,
            current: pageData.currentPage,
            onChange: handlePaginationChange,
            onShowSizeChange: handlePageSizeChange,
            total: pageData.totalPages,
          }}
        />
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  couponsPage: {
    padding: 15,
  },
  pageLeft: {
    display: "flex",
    flexDirection: "column",
  },
  tableCard: {
    width: "100%",
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    position: "relative",
    backgroundColor: "#fff",
  },

  "@media (max-width: 768px)": {
    couponsPage: {
      padding: 10,
      margin: "0 !important",
    },
    pageLeft: {
      padding: "0 !important",
      marginBottom: 15,
    },
    tableCard: {
      padding: "0.25rem !important",

      "& .ant-table *": {
        fontSize: 12,
      },
    },
  },
}));

export default CouponsPage;
