import Button from 'antd/es/button'
import InternalContext from '../contexts/InternalContext'
import Menu from 'antd/es/menu'
import Popover from 'antd/es/popover'
import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { UserOutlined, LogoutOutlined } from '@ant-design/icons'

const useStyles = createUseStyles({
  navMenu: {
    borderRight: 'none'
  }
})

const navItems = [
  {
    title: 'Profile',
    to: '/profile',
    icon: UserOutlined
  },
  {
    danger: true,
    title: 'Logout',
    to: '/logout',
    icon: LogoutOutlined
  }
]

function HeaderMenu() {
  const classes = useStyles()
  const { currentAdminUser, currentUser } = useContext(InternalContext)
  const { name } = currentAdminUser || currentUser || {}

  const navMenu = (
    <Menu className={classes.navMenu}>
      {navItems.map(({ title, danger, to, icon: Icon }) => (
        <Menu.Item key={title} icon={<Icon />} danger={danger}>
          <Link to={to}>{title}</Link>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Popover
      content={navMenu}
      placement="bottomRight"
      trigger="click"
    >
      <Button shape="circle">{name && name[0]}</Button>
    </Popover>
  )
}

export default HeaderMenu
