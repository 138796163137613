import React from 'react'
import type { RouteProps } from 'react-router-dom'

import ProtectedRoute, { RestrictedSection, SessionState, UserType } from './ProtectedRoute'
import AdminLayout from '../layouts/AdminLayout'

function AdminRoute(props: RouteProps) {
  return (
    <ProtectedRoute
      layout={AdminLayout}
      requiredSessionState={SessionState.LOGGED_IN}
      requiredRestrictedSection={RestrictedSection.ADMIN}
      requiredUserType={UserType.ADMIN}
      {...props}
    />
  )
}

export default AdminRoute
