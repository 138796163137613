import { Button, message, Modal, Table, Typography } from "antd";
import { ModalProps } from "antd/lib/modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getOrdersByUserId, reconcileMap } from "../../api/invoiceApis";
import Loader from "../Loader";
import OrderItemsModal from "./OrderItemsModal";

interface MapOrderModalProps extends ModalProps {
  storeId: number;
  reorderId: number;
  customerUserId: number;
  mappedOrders: number[];
  isVerificationPending: boolean;
  onStatusChange: () => void;
}

export default function MapOrderModal({
  storeId,
  reorderId,
  mappedOrders,
  customerUserId,
  isVerificationPending,
  onStatusChange,
  ...props
}: MapOrderModalProps) {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);
  const [pastPurchases, setPastPurchases] = useState<any[]>([]);
  //   const [reorders, setReorders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalOrderId, setModalOrderId] = useState("");
  const [modalInvoiceNumber, setModalInvoiceNumber] = useState("");

  const handleMapOrder = async () => {
    if (!selectedOrders.length)
      return message.error("Please select atleast 1 order to map.");

    let dataToSend = {
      change_to: "verification pending",
      reorders: [reorderId],
      orders: selectedOrders.map((i) => +i.id),
    };

    await reconcileMap(dataToSend).then((res) => {
      if (res.mapped) {
        message.success("Mapped order successfully! Sent to verification.");
        onStatusChange();
        // @ts-ignore
        props?.onCancel(null);
      }
    });
  };

  const handleMarkAsReconciledOrder = async () => {
    if (!selectedOrders.length)
      return message.error("Please select atleast 1 order to map.");

    let dataToSend = {
      change_to: "reconciled",
      reorders: [reorderId],
      orders: selectedOrders.map((i) => +i.id),
    };

    await reconcileMap(dataToSend).then((res) => {
      if (res.mapped) {
        message.success("Order marked as Reconciled sucessfully!");
        onStatusChange();
        // @ts-ignore
        props?.onCancel(null);
      }
    });
  };

  const handleMapOrderClick = () => {
    Modal.confirm({
      centered: true,
      title: `Map #${reorderId} with these orders?`,
      onOk: handleMapOrder,
    });
  };

  const handleMarkAsReconciledOrderClick = () => {
    Modal.confirm({
      centered: true,
      title: `Are you sure you want to mark this reorder as "Reconciled"?`,
      okText: "Confirm",
      onOk: handleMarkAsReconciledOrder,
    });
  };

  const handleRowSelectionChange = (
    selectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedOrders(selectedRows);
  };

  const onOrderIdClick = (orderId: any, invoiceNumber: any) => {
    setModalOrderId(orderId);
    setModalInvoiceNumber(invoiceNumber);
    setModalVisible(true);
  };

  const fetchInitialData = async () => {
    await getOrdersByUserId(+customerUserId, +storeId).then((res: any[]) => {
      if (res?.length) {
        let ordersArray = res.map((obj, i) => ({ ...obj, key: i + 1 }));

        if (mappedOrders?.length) {
          console.log(mappedOrders);
          let selectedOrdersArr = ordersArray.filter((i) =>
            mappedOrders.includes(+i.id)
          );
          setSelectedOrders(selectedOrdersArr);
        }

        setPastPurchases(ordersArray);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    setPastPurchases([]);
    setSelectedOrders([]);
  }, [reorderId]);

  useEffect(() => {
    if (loading && props.visible) {
      fetchInitialData();
    }
  }, [loading, props.visible]);

  const pastPurchasesTableColumns = [
    {
      title: "#",
      key: "key",
      dataIndex: "key",
      width: "45px",
    },
    {
      title: "Invoice No.",
      key: "invoice_number",
      dataIndex: "invoice_number",
      width: "100px",
      render: (val: string) => (val?.length ? val : " - "),
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: "150px",
      render: (val: string) => moment(val).format("hh:mm MMM DD, YYYY"),
    },
    {
      title: "Items",
      dataIndex: "invoice_number",
      width: "140px",
      render: (invoice_number: any, record: any) => (
        <Button
          type="text"
          onClick={() => onOrderIdClick(record.id, invoice_number)}
        >
          Show details
        </Button>
      ),
    },
  ];

  return (
    <Modal
      {...props}
      title="Map Order"
      okText={isVerificationPending ? "Mark As Reconciled" : "Map"}
      okButtonProps={{
        danger: isVerificationPending,
      }}
      onOk={
        isVerificationPending
          ? handleMarkAsReconciledOrderClick
          : handleMapOrderClick
      }
    >
      <Typography.Text style={{ marginBottom: 20, display: "block" }}>
        Please select one or more orders to map with the Reorder.
      </Typography.Text>

      {!loading ? (
        <Table
          size="small"
          scroll={{ y: 400 }}
          pagination={false}
          dataSource={pastPurchases}
          columns={pastPurchasesTableColumns}
          rowSelection={{
            hideSelectAll: true,
            selections: selectedOrders,
            selectedRowKeys: selectedOrders.map((i) => i.key),
            onChange: handleRowSelectionChange,
          }}
        />
      ) : (
        <Loader height="50vh" />
      )}
      <OrderItemsModal
        orderId={modalOrderId}
        order={pastPurchases.find((i: any) => i?.id === modalOrderId)}
        invoiceNumber={modalInvoiceNumber}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalOrderId("");
        }}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
