import Button from 'antd/es/button'
import Input from 'antd/es/input'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import type { CreateSessionInput } from '../../generated/schema'

import Field from '../field/Field'
import useForm from '../../hooks/useForm'
import User from '../../models/User'
import type { FormProps } from '../../hooks/useForm'

type LoginPageProps = FormProps<CreateSessionInput> & {
  isAdmin?: boolean
}

function LoginForm({ isAdmin, ...rest }: LoginPageProps) {
  const { isSubmitting, methods, submitHandler } = useForm<CreateSessionInput>({
    ...rest,
    resolver: isAdmin ? User.validateAdminLogin() : User.validateLogin()
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <Field as="input" name="isAdmin" type="hidden" />

        {isAdmin ? (
          <Field
            as={Input}
            name="email"
            label="Email"
            placeholder="Email"
          />
        ) : (
          <Field
            as={Input}
            name="mobileNumber"
            type="number"
            label="Mobile Number"
          />
        )}

        <Field
          as={Input.Password}
          name="password"
          label="Password"
          type="password"
        />

        <Button type="primary" htmlType="submit" loading={isSubmitting} block>
          Log in
        </Button>

      </form>
    </FormProvider>
  )
}

export default LoginForm
