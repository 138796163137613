import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "react-jss";
import theme from "./constants/theme";
import App from "./App";
import ApolloClientProvider from "./ApolloClientProvider";

function Root() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ApolloClientProvider>
          <App />
        </ApolloClientProvider>
      </ThemeProvider>
    </Router>
  );
}

export default Root;
