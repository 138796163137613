import * as yup from "yup";

import BaseModel from "./BaseModel";

class Coupon extends BaseModel {
  static schema = yup.object().shape({
    retail_store_id: yup.string().required().nullable(),
    coupon_name: yup.string().required(),
    coupon_code: yup.string().required(),
    type: yup.string().required().nullable(),
    value: yup.string().required().nullable(),
    max_value: yup
      .string()
      .when("type", {
        is: "percentage",
        then: yup.string().required().nullable(),
        else: yup.string().nullable(),
      })
      .nullable(),
    min_cart_amount: yup.string().required().nullable(),
    is_active: yup.string().required().nullable(),
    description: yup.string(),
  });

  static addCoupon<TInput>() {
    return Coupon.validationResolver<TInput>([
      "retail_store_id",
      "coupon_name",
      "coupon_code",
      "type",
      "value",
      "max_value",
      "min_cart_amount",
      "is_active",
      "description",
    ]);
  }
}

export default Coupon;
