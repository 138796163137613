import * as yup from "yup";

import BaseModel from "./BaseModel";

class Campaign extends BaseModel {
  static schema = yup.object().shape({
    total_messages_delivered: yup.string().required(),
    total_messages_sent: yup.string().required(),
    open_rate: yup.string().required(),
    response_rate: yup.string().required(),

    campaign_type: yup.string().required(),
    campaign_medium: yup.string().required(),
  });

  static markAsCompleted<TInput>() {
    return Campaign.validationResolver<TInput>([
      "total_messages_delivered",
      "total_messages_sent",
      "open_rate",
      "response_rate",
    ]);
  }

  static addCampaign<TInput>() {
    return Campaign.validationResolver<TInput>([
      "campaign_type",
      "campaign_medium",
    ]);
  }
}

export default Campaign;
