import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';

export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };

export type Response = {
  __typename?: 'Response';
  success: Scalars['Boolean'];
};

export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    File: any;
    /** Represents untyped JSON */
    Hash: any;
    Upload: any;
  };

export type Session = {
    __typename?: 'Session';
    createdAt: Scalars['String'];
    expiresAt: Scalars['String'];
    id: Scalars['ID'];
    ipAddress: Scalars['String'];
    location: Scalars['String'];
    token: Scalars['String'];
    updatedAt: Scalars['String'];
    userAgent: Scalars['String'];
  };

export type CreateSessionMutation = (
    { __typename?: 'Mutation' }
    & { createSession: (
      { __typename?: 'Session' }
      & Pick<Session, 'token'>
    ) }
  );

/** CreateSessionInput */
export type CreateSessionInput = {
    mobileNumber?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    password: Scalars['String'];
    isAdmin?: Maybe<Scalars['Boolean']>;
  };

export type CreateSessionMutationVariables = Exact<{
input: CreateSessionInput;
}>;

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  landmark?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  pincode: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  addresses?: Maybe<Array<Address>>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isConfirmed: Scalars['Boolean'];
  isMobileNumberConfirmed: Scalars['Boolean'];
  mobileNumber: Scalars['String'];
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type AdminUser = {
  __typename?: 'AdminUser';
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type RetailStore = {
  __typename?: 'RetailStore';
  address?: Maybe<Address>;
  contacts?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  gstin: Scalars['String'];
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primaryMobileNumber: Scalars['String'];
  updatedAt: Scalars['String'];
  user: User;
};


export const CreateSessionDocument = gql`
    mutation CreateSession($input: CreateSessionInput!) {
  createSession(input: $input) {
    token
  }
}
    `;

export function useCreateSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
    return ApolloReactHooks.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, baseOptions);
  }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export const CreateUserDocument = gql`
mutation CreateUser($input: CreateUserInput!) {
createUser(input: $input) {
token
}
}
`;

export type DestroySessionMutationVariables = Exact<{ [key: string]: never; }>;

export type DestroySessionMutation = (
  { __typename?: 'Mutation' }
  & { destroySession: (
    { __typename?: 'Response' }
    & Pick<Response, 'success'>
  ) }
);

export const DestroySessionDocument = gql`
    mutation DestroySession {
  destroySession {
    success
  }
}
    `;

export function useDestroySessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DestroySessionMutation, DestroySessionMutationVariables>) {
  return ApolloReactHooks.useMutation<DestroySessionMutation, DestroySessionMutationVariables>(DestroySessionDocument, baseOptions);
}