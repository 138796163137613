import { Select } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface StoresSelectorProps {
  retailStores: any[];
  selectedStoreId: number;
  setSelectedStoreId: (storeId: number) => void;
}

export default function StoresSelector({
  retailStores,
  selectedStoreId,
  setSelectedStoreId,
  ...props
}: StoresSelectorProps) {
  const classes = useStyle();

  return (
    <Select
      showSearch
      value={+selectedStoreId ?? undefined}
      placeholder="Select Retailer"
      optionFilterProp="children"
      onChange={setSelectedStoreId}
      filterOption={(input, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      className={classes.searchSelect}
      {...props}
    >
      <Select.Option key={"Select retailer"} value={0}>
        Select Retailer
      </Select.Option>
      {retailStores.map((store: any) => (
        <Select.Option key={`#${store.id} - ${store.name}`} value={+store?.id}>
          #{store.id} - {store.name}
        </Select.Option>
      ))}
    </Select>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  searchSelect: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
    minWidth: 250,
    fontWeight: 600,
  },
  "@media (max-width: 768px)": {
    searchSelect: {
      fontSize: 12,
    },
  },
}));
