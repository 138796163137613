import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { createUseStyles } from "react-jss";
import Col from "antd/es/col";
import Row from "antd/es/row";
import message from "antd/es/message";
import {
  getAllRetailStores,
  getCartItems,
  deleteCartItems,
  getProductCatalogue,
  getCheckCart,
  getRetailStoreCustomers,
  insertCart,
  insertCartItems,
  updateCartItemsByProductId,
} from "../../api/invoiceApis";
import Loader from "../../components/Loader";
import CartFab from "../../components/CartFab";
import ProductsCard from "../../components/ProductsCard";
import CustomersCard from "../../components/CustomersCard";
import RetailStoresCard from "../../components/RetailStoresCard";
import CartOverviewCard from "../../components/CartOverviewCard";

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PlaceOrder() {
  // ------ other hooks
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const screen = useBreakpoint();
  const isLG = screen.lg;

  // ----- states
  const [retailStores, setRetailStores] = useState([]);
  const [cartId, setCartId] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [customersLoading, setCustomersLoading] = useState(false);
  const [cartOverviewLoading, setCartOverviewLoading] = useState(true);
  const [pageData, setPageData] = useState({
    loading: true,
    tableLoading: false,
    endOfList: false,
    products: [],
    customers: [],
    selectedStoreId: "",
    selectedCustomerUserId: "",
    pageSize: 20,
    currentPage: 1,
    totalPages: 0,
  });

  useEffect(() => {
    if (pageData.products.length) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [pageData.products.length]);

  // Fetch initial retail stores data
  useEffect(() => {
    fetchRetailStores();
    setPageData({
      ...pageData,
      loading: false,
      selectedStoreId: query.get("store") || "",
      selectedCustomerUserId: query.get("customer") || "",
    });
  }, []);

  // Fetch customers data on selected store change
  useEffect(() => {
    if (pageData.selectedStoreId !== "") {
      setCustomersLoading(true);
      history.replace(
        `/admin/dashboard/place-order?store=${pageData.selectedStoreId}&customer=${pageData.selectedCustomerUserId}`
      );
    }
  }, [pageData.selectedStoreId]);

  useEffect(() => {
    if (customersLoading) {
      fetchRetailStoreCustomers();
    }
  }, [customersLoading]);

  // Fetch CartData and ProductsData on selected customer change
  useEffect(() => {
    (async () => {
      if (pageData.selectedCustomerUserId !== "") {
        setPageData((current) => ({
          ...current,
          tableLoading: true,
        }));
        history.replace(
          `/admin/dashboard/place-order?store=${pageData.selectedStoreId}&customer=${pageData.selectedCustomerUserId}`
        );
      }
    })();
  }, [pageData.selectedCustomerUserId]);

  // Fetch products data on when table loading is true
  useEffect(() => {
    if (
      pageData.selectedCustomerUserId !== "" &&
      pageData.selectedStoreId !== ""
    ) {
      fetchProducts();
    }
  }, [
    pageData.tableLoading,
    pageData.currentPage,
    pageData.selectedCustomerUserId,
  ]);

  // ---
  // ---
  // ---

  const handleScroll = async (e: any) => {
    if (pageData.endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPageData((curr) => ({
        ...curr,
        currentPage: curr.currentPage + 1,
      }));
    }
  };

  const fetchRetailStores = async () => {
    let result = await getAllRetailStores(500);
    setRetailStores(result);
  };

  const fetchRetailStoreCustomers = async () => {
    let result = await getRetailStoreCustomers(+pageData.selectedStoreId);
    let customerId = query.get("customer") || "";

    if (
      result.filter((customer: any) => customer.user_id === customerId)
        .length === 0
    ) {
      customerId = "";
      history.replace(
        `/admin/dashboard/place-order?store=${pageData.selectedStoreId}&customer=`
      );
    }
    setCartId(0);
    setCustomersLoading(false);
    setPageData({
      ...pageData,
      loading: false,
      customers: result,
      selectedCustomerUserId: customerId,
      totalPages: 0,
      currentPage: 1,
      products: [],
    });
  };

  const fetchProducts = async () => {
    let [count, ...products] = await getProductCatalogue(
      +pageData.selectedStoreId,
      +pageData.selectedCustomerUserId,
      pageData.currentPage,
      pageData.pageSize,
      searchInput
    );

    setTimeout(() => {
      setCartId(products[0]?.cart_id ?? 0);
      setPageData((curr) => ({
        ...curr,
        products:
          pageData.currentPage > 1 ? [...curr.products, ...products] : products,
        endOfList: products.length < 20,
        totalPages: +count,
        tableLoading: false,
      }));
    }, 100);
  };

  const fetchCartId = async () => {
    await getCheckCart(
      +pageData.selectedStoreId,
      +pageData.selectedCustomerUserId
    ).then(async (cart) => {
      if (cart.length) {
        setCartId(cart[0]?.id);
      } else {
        let cartDetails = await insertCart({
          retail_store_id: pageData.selectedStoreId,
          payment_status: false,
          discount: 0,
          gst: 0,
          price_with_gst: 0,
          price_with_out_gst: 0,
          user_id: pageData.selectedCustomerUserId,
        });

        if (cartDetails[0]?.id) {
          setCartId(+cartDetails[0].id);
        }
      }
    });
  };

  const triggerFetchProducts = () => {
    setPageData((curr) => ({ ...curr, tableLoading: true }));
  };

  const getCartId = async () => {
    let cartId = 0;
    let cartRes = await getCheckCart(
      +pageData.selectedStoreId,
      +pageData.selectedCustomerUserId
    );

    if (cartRes.length) {
      cartId = cartRes[0].id;
    } else {
      let cartData = {
        retail_store_id: +pageData.selectedStoreId,
        payment_status: false,
        discount: 0,
        gst: 0,
        price_with_gst: 0,
        price_with_out_gst: 0,
        user_id: +pageData.selectedCustomerUserId,
      };
      // Create the cart
      const insertCartRes = await insertCart(cartData);
      if (insertCartRes?.length) {
        cartId = insertCartRes[0].id;
      }
    }

    setCartId(cartId);
    return cartId;
  };

  const handleCustomerSearch = async (searchValue: any) => {
    if (pageData.selectedStoreId !== "") {
      await getRetailStoreCustomers(
        +pageData.selectedStoreId,
        searchValue
      ).then((result) => {
        setPageData({ ...pageData, customers: result });
      });
    }
  };

  const handleQuantityChange = async (qty: number, product: any) => {
    product.quantity = qty;
    let ACTION;

    if (+qty === 0 && !!product?.cart_item_id) {
      ACTION = "REMOVE_ITEM";
    } else if (
      !!product?.cart_item_id &&
      +product?.cart_item_id > 0 &&
      +qty > 0
    ) {
      ACTION = "UPDATE_ITEM";
    } else {
      ACTION = "ADD_ITEM";
    }

    console.log(ACTION);

    switch (ACTION) {
      case "ADD_ITEM":
        await addItemToCart(product);
        break;
      case "UPDATE_ITEM":
        await updateItemToCart(product);
        break;
      case "REMOVE_ITEM":
        await removeCartItem(+product.cart_item_id || 0);
        break;
      default:
        alert("Something Went Wrong.!");
        break;
    }

    setPageData({ ...pageData, tableLoading: true });
  };

  const handlePaginationChange = (page: number) => {
    setPageData({ ...pageData, currentPage: page, tableLoading: true });
  };

  const addItemToCart = async (product: any) => {
    let productPrice = product?.strike_price ?? product.pack_price;

    let sub_total = getPriceWithoutGst(
      +product.sgst + +product.cgst,
      +productPrice * +product.quantity
    );
    let newCartItem = {
      product_section: "admin",
      product_sku: product?.product_sku,
      product_id: product?.product_id ?? product?.id,
      quantity: product.quantity,
      cart_id: product.cart_id,
      cgst: product.cgst,
      sgst: product.sgst,
      discount: product.discount,
      total: (+productPrice * +product.quantity).toFixed(2),
      sub_total: sub_total.toFixed(2),
    };

    if (!!!product?.cart_id || product?.cart_id === "0") {
      await getCartId().then(async (cart_id) => {
        await insertCartItems({ ...newCartItem, cart_id }).then((res) => {
          message.success("Item Added Successfully.!");
          fetchProducts();
          setCartOverviewLoading(true);
        });
      });
    } else {
      await insertCartItems(newCartItem).then((res) => {
        message.success("Item Added Successfully.!");
        fetchProducts();
        setCartOverviewLoading(true);
      });
    }
  };

  const updateItemToCart = async (updatedProduct: any) => {
    let productPrice =
      updatedProduct?.strike_price ?? updatedProduct.pack_price;

    let sub_total = getPriceWithoutGst(
      +updatedProduct.sgst + +updatedProduct.cgst,
      +productPrice * +updatedProduct.quantity
    );
    let data = {
      product_section: "admin",
      product_sku: updatedProduct?.product_sku,
      quantity: updatedProduct.quantity,
      sub_total: sub_total.toFixed(2),
      total: (+productPrice * +updatedProduct.quantity).toFixed(2),
    };

    await updateCartItemsByProductId(
      +updatedProduct.cart_id,
      updatedProduct.product_id ?? updatedProduct.id,
      data
    );

    setCartOverviewLoading(true);
  };

  const removeCartItem = async (itemId: number) => {
    await deleteCartItems(itemId).then(async (result) => {
      if (pageData.selectedCustomerUserId !== "") {
        let [count, ...products] = await getProductCatalogue(
          +pageData.selectedStoreId,
          +pageData.selectedCustomerUserId,
          pageData.currentPage,
          pageData.pageSize,
          searchInput
        );

        setPageData({ ...pageData, totalPages: +count, products });
        setCartOverviewLoading(true);
      }
    });
  };

  const handleAddCustomer = (customer: any) => {
    let newList: any = [customer, ...pageData.customers];
    setPageData({ ...pageData, customers: newList });
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageData({ ...pageData, pageSize: size, currentPage: 1 });
  };

  const handleProductsSearch = (value: string) => {
    if (searchInput !== value) {
      setSearchInput(value);
      setPageData({ ...pageData, tableLoading: true, currentPage: 1 });
    }
  };

  const handleSelectedCustomerUserId = (value: string) => {
    setPageData({
      ...pageData,
      tableLoading: value !== "" ? true : false,
      selectedCustomerUserId: value,
    });
  };

  const handleSelectedStoreChange = (value: string) => {
    setPageData({
      ...pageData,
      products: [],
      selectedStoreId: value,
      selectedCustomerUserId: "",
    });
  };

  if (pageData.loading) {
    return <Loader />;
  }

  return (
    <Row className={classes.placeOrderPage} gutter={20}>
      <Col span={isLG ? 7 : 24} className={classes.firstWrapper}>
        <RetailStoresCard
          retailStores={retailStores}
          selectedStoreId={pageData.selectedStoreId}
          setSelectedStoreId={handleSelectedStoreChange}
        />
        <CustomersCard
          isLoading={customersLoading}
          onCustomerAdd={handleAddCustomer}
          onSearch={handleCustomerSearch}
          customers={pageData.customers}
          selectedStoreId={pageData.selectedStoreId}
          selectedCustomerUserId={pageData.selectedCustomerUserId}
          setSelectedCustomerUserId={handleSelectedCustomerUserId}
        />
      </Col>
      <ProductsCard
        cartId={cartId}
        products={pageData.products}
        isLoading={pageData.tableLoading}
        onQuantityChange={handleQuantityChange}
        onProductsSearch={handleProductsSearch}
        selectedCustomerUserId={pageData.selectedCustomerUserId}
        paginationProps={{
          pageSize: pageData.pageSize,
          current: pageData.currentPage,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePageSizeChange,
          total: pageData.totalPages,
        }}
      />
      {!!cartId && +cartId !== 0 && (
        <>
          <CartOverviewCard
            cartId={cartId}
            loading={cartOverviewLoading}
            onChange={triggerFetchProducts}
            onLoaded={() => setCartOverviewLoading(false)}
          />
          <CartFab cartId={cartId} />
        </>
      )}
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  placeOrderPage: {
    padding: 15,
    marginRight: "0 !important",
  },
  firstWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  "@media (max-width: 768px)": {
    placeOrderPage: {
      padding: 10,
      margin: "0 !important",
    },
    firstWrapper: {
      padding: "0 !important",
      marginBottom: 15,
    },
  },
}));

export default PlaceOrder;
