import { ShopOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "../styles/CustomScrollbar";
import { Select } from "antd/es";

function RetailStoresCard({
  retailStores,
  selectedStoreId,
  setSelectedStoreId,
}: any) {
  const classes = useStyles();

  return (
    <div className={classes.retailStoresCard}>
      <div className={classes.retailStoresCardTitle}>
        <ShopOutlined /> Choose Retail Store
      </div>
      <Select
        showSearch
        defaultValue={+selectedStoreId || undefined}
        placeholder="Select Retailer"
        optionFilterProp="children"
        onChange={setSelectedStoreId}
        filterOption={(input, option: any) =>
          option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        className={classes.searchSelect}
      >
        {retailStores.map((store: any) => (
          <Select.Option
            key={`#${store.id} - ${store.name}`}
            value={+store?.id}
          >
            #{store.id} - {store.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  retailStoresCard: {
    width: "100%",
    marginBottom: 15,
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  retailStoresCardTitle: {
    color: "#505050",
    fontSize: 22,
    padding: "5px 15px 10px",
    fontWeight: 600,

    "& .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  itemsWrapper: {
    overflow: "auto",
    height: "calc(100vh - 95px)",
    ...customeScrollbarStyle,
  },
  retailStoreItem: {
    padding: [5, 20],
    fontSize: 15,
    fontWeight: 600,
    color: "#505050",
    opacity: 0.85,
    display: "flex",
    cursor: "pointer",
    transition: "all 0.3s",

    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(0,0,0,0.035)",
    },

    "& b": {
      fontWeight: "700 !important",
      color: "#111",
      paddingRight: 7,
    },
  },
  searchSelect: {
    width: "94%",
    margin: "5px 3% 10px",
    padding: 0,
    fontWeight: 600,
  },
  "@media (max-width: 768px)": {
    retailStoresCard: {
      padding: "0.25rem",
    },
    retailStoresCardTitle: {
      fontSize: 18,
      padding: "5px 15px 10px",

      "& .anticon": {
        fontSize: 18,
      },
    },
    searchSelect: {
      fontSize: 12,
    },
  },
}));

export default RetailStoresCard;
