import { Card } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { generateInvoiceImageUrl } from "../../api/invoiceApis";
import Loader from "../../components/Loader";
import getInvoiceDetails from "../../utils/getInvoiceDetails";
import saveAndPublish from "../../utils/saveAndPublish";
import saveAsDraft from "../../utils/saveAsDraft";
import ImageContainer from "./../../components/ImageContainer";
import InvoiceFormContainer from "./../../components/InvoiceFormContainer";
import { getSession, Session } from "../../client/reactives/session";

function InvoiceDetails({
  nextInvoice,
  prevInvoice,
  setActiveInvoiceType,
  refreshInvoices,
}: any) {
  const classes = useStyles();
  const [invoice, setInvoice] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [itemsDeleted, setItemsDeleted] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [invoiceImageUrl, setInvoiceImageUrl] = useState<any>("");
  const { recordId } = useParams<any>();
  const { push } = useHistory();
  const { userName } = getSession() as Session;

  const keysToIncludeCalc = ["sub_total", "gst", "discount", "round_off"];

  // fetching invoice details
  useEffect(() => {
    (async () => {
      const result = await getInvoiceDetails(recordId, false);
      const imageKey = result.s3_key || result.filename;
      const invoiceImagURL = await generateInvoiceImageUrl(imageKey);
      const { items, ...data } = result;
      setInvoice(data);
      setProducts(items);
      setLoading(false);
      if (result?.order_status === 1) {
        setActiveInvoiceType("Published");
      } else if (result?.order_status === 0) {
        setActiveInvoiceType("Draft");
      }
      setInvoiceImageUrl(invoiceImagURL);
    })();
  }, [recordId]);

  // set Invoice calculations
  const setInvoiceCalc = (newData: any) => {
    setInvoice({ ...invoice, ...newData });
  };

  const calculateInvoice = (data: any) => {
    let newData: any = JSON.parse(JSON.stringify(data));
    let tempData: any = JSON.parse(JSON.stringify(data));

    for (var key in tempData) {
      if (tempData.hasOwnProperty(key) && keysToIncludeCalc.includes(key)) {
        tempData[key] =
          tempData[key] === "" || tempData[key] === null
            ? 0
            : parseFloat(tempData[key]);
      }
    }
    const { sub_total } = tempData;

    let overalldiscount =
      newData?.discount !== undefined && newData?.discount !== undefined
        ? parseFloat(newData.discount)
        : 0;
    let roundOff =
      newData?.round_off !== undefined && newData?.round_off !== undefined
        ? parseFloat(newData.round_off)
        : 0;

    let newTotal = sub_total - overalldiscount + roundOff;
    newData["total"] = newTotal.toFixed(2);
    setInvoice(newData);
  };

  // handle input fields change
  const onInputChange = (key: string) => (e: any) => {
    let newData: any = { ...invoice };
    newData[key] = e.target.value;
    if (keysToIncludeCalc.includes(key)) {
      calculateInvoice(newData);
    } else {
      setInvoice(newData);
    }
  };

  // handle dateTime Change
  const onDateTimeChange = (value: any, dateString: any) => {
    setInvoice({ ...invoice, invoice_date: dateString, moment_date: value });
  };

  // handle items edit/change
  const handleItemsChange = (items: any, id: number) => {
    setProducts(items);
  };

  // handle item delete
  const handleItemsDelete = (items: any, id: number) => {
    setProducts(items);
    if (itemsDeleted.indexOf(id)) {
      setItemsDeleted([...itemsDeleted, id]);
    }
  };

  // handle save as draft
  const handleSaveAsDraft = async () => {
    const { order_id, customer_name, retail_store_name, ...rest } = invoice;
    let data: any = {
      order: {
        id: order_id,
        ...rest,
        last_action_by: userName,
      },
      customer: {
        name: customer_name,
        mobile_number: invoice.mobile_number,
      },
      products,
      retail_store_id: invoice.retail_store_id,
    };
    if (itemsDeleted.length) {
      data = { ...data, deleted_order_items: itemsDeleted };
    }

    const result = await saveAsDraft(data, (res: any) => {
      refreshInvoices();
      window.location.reload();
    });
  };

  // handle save and publish
  const handleSaveAndPublish = async () => {
    const { order_id, customer_name, retail_store_name, ...rest } = invoice;
    const data = {
      order: {
        id: order_id,
        ...rest,
        last_action_by: userName,
      },
      customer: {
        name: customer_name,
        mobile_number: invoice.mobile_number,
      },
      products,
      deleted_order_items: itemsDeleted,
      retail_store_id: invoice.retail_store_id,
    };

    const result = await saveAndPublish(
      data,
      (res: any) => {
        refreshInvoices();
        window.location.reload();
      },
      invoice.order_status === 1
    );
  };

  return (
    <div className={classes.invoiceDetailsPage}>
      {!loading ? (
        <Card size="small" className={classes.invoiceDetailsPageInner}>
          <ImageContainer
            nextInvoice={nextInvoice}
            prevInvoice={prevInvoice}
            images={invoice?.file_urls}
            imageSrc={invoiceImageUrl}
            setPageLoading={setLoading}
          />
          <InvoiceFormContainer
            invoice={invoice}
            setInvoiceCalc={setInvoiceCalc}
            products={products}
            nextInvoice={nextInvoice}
            prevInvoice={prevInvoice}
            setPageLoading={setLoading}
            onInputChange={onInputChange}
            onDateTimeChange={onDateTimeChange}
            onSaveAsDraft={handleSaveAsDraft}
            onSaveAndPublish={handleSaveAndPublish}
            onItemsChange={handleItemsChange}
            onItemsDelete={handleItemsDelete}
          />
        </Card>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  invoiceDetailsPage: {
    minHeight: "100%",
    width: "100%",
    padding: 10,
    background: "#F7F7F7",
  },
  invoiceDetailsPageInner: {
    height: "100%",
    rowGap: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    background: "#FFFFFF",
    boxShadow:
      "1px 1px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2px",

    "& .ant-card-body": {
      height: "100%",
      width: "100%",
    },
  },
}));

export default InvoiceDetails;
