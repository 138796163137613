import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Select from "antd/es/select";
import Field from "../field/Field";
import useForm from "../../hooks/useForm";
import Product from "../../models/Product";
import { useEffect, useState } from "react";
import {
  addBrand,
  addProductCategory,
  getBrands,
  getProductCategories,
} from "../../api/invoiceApis";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
const { Option } = Select;

interface RaseetProductFormProps {
  submitBtnText?: string;
  defaultValues: any;
  onSubmit: (values: any) => void;
}

function RaseetProductForm({
  submitBtnText = "Add",
  defaultValues,
  onSubmit,
  ...props
}: RaseetProductFormProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brandInput, setBrandInput] = useState("");
  const [categoryInput, setCategoryInput] = useState("");

  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    onSubmit,
    defaultValues,
    resolver: Product.AddProducts(),
  });

  const handleBrandAdd = async () => {
    let brandAddRes = await addBrand(brandInput);
    if (brandAddRes?.brand_created) {
      setBrands((curr) => [...curr, brandAddRes.brand_data]);
      message.success("Brand added successfully!");
    } else {
      message.error(brandAddRes.err);
    }
  };

  const handleCategoryAdd = async () => {
    let categoryAddRes = await addProductCategory(categoryInput);
    if (categoryAddRes?.brand_created) {
      setCategories((curr) => [...curr, categoryAddRes.brand_data]);
      message.success("Category added successfully!");
    } else {
      message.error(categoryAddRes.err);
    }
  };

  const fetchInitialData = async () => {
    let brandsList = await getBrands();

    if (brandsList.length) {
      setBrands(brandsList);
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    let vendorCode = methods.watch().vendor_code;
    if (vendorCode?.length === 5) {
      let categoriesList = await getProductCategories(vendorCode);
      setCategories(categoriesList);
    }
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [methods.watch().vendor_code]);

  if (loading) {
    return null;
  }

  console.log(methods.errors);

  const PrescriptionSelector = (
    <Select placeholder="Prescription">
      <Option value="PRESCRIPTION">Required</Option>
      <Option value="false">Not Required</Option>
    </Select>
  );
  const CatalogueTypeSelector = (
    <Select placeholder="Catalogue Type">
      <Option value="RASEET">RASEET</Option>
      <Option value="RETAILER">RETAILER</Option>
    </Select>
  );

  const BrandSelector = (props: any) => (
    <Select
      {...props}
      showSearch
      value={+methods.watch().brand_id}
      placeholder="Select Brand"
      optionFilterProp="children"
      onSearch={setBrandInput}
      onChange={(val) => methods.setValue("brand_id", val)}
      filterOption={(input, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      notFoundContent={
        <Button
          type="text"
          block
          icon={<PlusOutlined />}
          onClick={handleBrandAdd}
        >
          Add Brand
        </Button>
      }
    >
      {brands.map((brand: any) => (
        <Select.Option key={brand.brand_name} value={+brand?.id}>
          {brand.brand_name}
        </Select.Option>
      ))}
    </Select>
  );

  const CategorySelector = (props: any) => (
    <Select
      {...props}
      showSearch
      value={+methods.watch().category_id}
      placeholder="Select Category"
      optionFilterProp="children"
      onChange={(val) => methods.setValue("category_id", val)}
      onSearch={setCategoryInput}
      filterOption={(input, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      notFoundContent={
        <Button
          type="text"
          block
          icon={<PlusOutlined />}
          onClick={handleCategoryAdd}
        >
          Add Category
        </Button>
      }
    >
      {categories.map((category: any) => (
        <Select.Option key={category.name} value={+category?.id}>
          {category.name}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler} className={classes.RaseetProductForm}>
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="product_name"
                placeholder="Product name"
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={BrandSelector}
                name="brand_id"
                // placeholder="Brand name"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={Input} name="vendor" placeholder="Vendor" />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={Input} name="vendor_code" placeholder="Vendor Code" />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={CategorySelector}
                name="category_id"
                // placeholder="Category name"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={PrescriptionSelector} name="prescription" />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={Input} name="pack_size" placeholder="Pack Size" />
            </Col>
            <Col xs={24} lg={12}>
              <Field as={Input} name="pack_price" placeholder="Pack price" />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="product_domain"
                placeholder="Product Domain"
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field as={Input} name="dosage_form" placeholder="Dosage Form" />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="manufacturer"
                placeholder="Manufacturer"
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="salt_composition"
                placeholder="Salt Composition"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="description"
                placeholder="Description"
                rows={3}
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="drug_info"
                placeholder="Drug Info"
                rows={3}
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="when_to_use"
                placeholder="When to use"
                rows={3}
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="how_to_use"
                placeholder="How to use"
                rows={3}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="benefits"
                placeholder="Benefits"
                rows={3}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Field
                as={Input.TextArea}
                name="side_effects"
                placeholder="Sideeffects"
                rows={3}
              />
            </Col>

            <Col xs={24}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                style={{ width: "50%" }}
              >
                {submitBtnText}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  RaseetProductForm: {
    padding: [20, 0],

    "& *": {
      fontWeight: 600,
    },
    "& .ant-picker": {
      width: "100%",
    },
  },
  searchSelect: {
    width: "100%",
    padding: 0,
    fontWeight: 600,
  },
  customerSelect: {
    width: "100%",
    marginBottom: 24,
    padding: 0,
    fontWeight: 600,
  },
}));

export default RaseetProductForm;
