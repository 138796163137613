import { Input, message, Modal, Typography } from "antd";
import { ModalProps } from "antd/lib/modal";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { reconcileSettle } from "../../api/invoiceApis";

interface DisputeResolveModalProps extends ModalProps {
  reorderId: number;
  disputeReason: string;
  settleReason: string;
  onSettled: () => void;
}

export default function DisputeResolveModal({
  reorderId,
  disputeReason,
  settleReason,
  onSettled,
  ...props
}: DisputeResolveModalProps) {
  const classes = useStyle();
  const [inputValue, setInputValue] = useState("");

  const handleSubmitReport = async () => {
    if (!inputValue.length)
      return message.info("Please input your report reason");

    await reconcileSettle({
      reorder_id: +reorderId,
      settle_reason: inputValue,
    }).then((res) => {
      if (res?.settled) {
        message.success("Settled successfully!");
        onSettled();
        //  @ts-ignore
        props.onCancel(null);
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  useEffect(() => {
    setInputValue(settleReason ?? "");
  }, [reorderId]);

  return (
    <Modal {...props} title="Report" okText="Settle" onOk={handleSubmitReport}>
      <Typography.Text style={{ marginBottom: 20, display: "block" }}>
        <Typography.Text strong>Report Reason:</Typography.Text>
        <br />
        <Typography.Text>{disputeReason}</Typography.Text>
      </Typography.Text>

      <Input.TextArea
        rows={5}
        autoFocus
        value={inputValue}
        placeholder="Settle message"
        onChange={(e) => setInputValue(e.target.value)}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
