import { Button, DatePicker, Input, Select, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import useForm from "../../hooks/useForm";
import Campaign from "../../models/Campaign";
import Field from "../field/Field";

interface SetupMarketingCampaignFormProps {
  hideTitle?: boolean;
  onSubmit: (values: any) => void;
  onMediumChange: (value: any) => void;
}

export default function SetupMarketingCampaignForm({
  onMediumChange,
  hideTitle = false,
  ...props
}: SetupMarketingCampaignFormProps) {
  const classes = useStyle();
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    resolver: Campaign.addCampaign(),
    defaultValues: {
      campaign_name: "",
      campaign_type: "general",
      campaign_medium: "Whatsapp",
      campaign_time: moment(),
    },
  });

  useEffect(() => {
    onMediumChange(methods.watch().campaign_medium);
  }, [methods.watch().campaign_medium]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={submitHandler}>
        <div className={classes.campaignFormWrapper}>
          {!hideTitle && (
            <Typography.Title level={5} style={{ marginBottom: 15 }}>
              Campaign Details
            </Typography.Title>
          )}

          {/* <Space size={2} direction="vertical">
            <Typography.Text className={classes.label}>
              Campaign Name
            </Typography.Text>
            <Field
              as={Input}
              placeholder="Campaign Name"
              name="campaign_name"
            />
          </Space> */}

          <Space size={2} direction="vertical">
            <Typography.Text className={classes.label}>
              Campaign Type
            </Typography.Text>
            <Field
              as={Select}
              placeholder="Campaign Type"
              style={{ width: "100%" }}
              name="campaign_type"
            >
              <Select.Option value="general">General</Select.Option>
              <Select.Option value="product_promotion">
                Product Promotion
              </Select.Option>
              <Select.Option value="service_promotion_current">
                Service Promotion Current
              </Select.Option>
            </Field>
          </Space>

          <Space size={2} direction="vertical">
            <Typography.Text className={classes.label}>
              Campaign Medium
            </Typography.Text>
            <Field
              as={Select}
              placeholder="Campaign Medium"
              style={{ width: "100%" }}
              name="campaign_medium"
            >
              <Select.Option value="Sms">SMS</Select.Option>
              <Select.Option value="Whatsapp">Whatsapp</Select.Option>
            </Field>
          </Space>

          <Space size={2} direction="vertical">
            <Typography.Text className={classes.label}>
              Campaign Date
            </Typography.Text>
            <Field
              as={DatePicker}
              format="Do MMM, YYYY"
              placeholder="Campaign Date"
              name="campaign_time"
              style={{ width: "100%" }}
              disabledDate={(current: any) =>
                current && current < moment().subtract(1, "days")
              }
            />
          </Space>

          <Button block type="primary" htmlType="submit">
            Submit Campaign
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  campaignFormWrapper: {
    width: "100%",
    marginBottom: 20,
    "& .ant-space": {
      width: "100%",
    },
  },
  label: {
    fontSize: 12,
    paddingLeft: 2,
    marginTop: -20,
  },
}));
