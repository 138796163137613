import React from 'react'
import Typography from 'antd/es/typography'
import { createUseStyles } from 'react-jss'

type FormBoxProps = React.PropsWithChildren<{
  title: string
}>

const useStyles = createUseStyles(({ colors }: Theme) => ({
  formBox: {
    padding: [ 40, -28 ]
  },
  title: {
    fontSize: '1.5rem !important',
    fontWeight: 'bold',
    marginBottom: '2rem !important',
    marginTop:'-2rem !important',
    marginLeft:'4rem'
  }
}))

function FormBox({ children, title }: FormBoxProps) {
  const classes = useStyles()

  return (
    <div className={classes.formBox}>
      <Typography.Title level={1} className={classes.title}>
        {title}
      </Typography.Title>
      {children}
    </div>
  )
}

export default FormBox
