import { Col, Row } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import CustomerSelectorCard from "../../components/CustomerSelectorCard";
import StoreSelectorCard from "../../components/StoreSelectorCard";
import ReorderListCard from "../../components/ReorderListCard";
import ReorderOverviewCard from "../../components/ReorderOverviewCard";

export default function ReordersPage() {
  const classes = useStyle();
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [openedReorder, setOpenedReorder] = useState(0);

  return (
    <Row gutter={[15, 15]} className={classes.reordersPage}>
      <Col xs={24} lg={6}>
        <StoreSelectorCard onStoreChange={setSelectedStoreId} />
        <CustomerSelectorCard
          storeId={selectedStoreId}
          onCustomerChange={setSelectedUserId}
        />
      </Col>
      <Col xs={24} lg={!!openedReorder ? 12 : 18}>
        <ReorderListCard
          storeId={selectedStoreId}
          userId={selectedUserId}
          onReorderView={setOpenedReorder}
        />
      </Col>
      <Col xs={24} hidden={!!!openedReorder} lg={6}>
        <ReorderOverviewCard reorderId={openedReorder} />
      </Col>
    </Row>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  reordersPage: {
    height: "100vh",
    padding: 15,
    marginRight: "0 !important",
    "& > .ant-col": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
  },
}));
