import "antd/dist/antd.css";
import { Switch, Redirect, Route } from "react-router-dom";
import ReviewInvoices from "./pages/adminDashboard/ReviewInvoices";
import InvoiceDetails from "./pages/adminDashboard/InvoiceDetails";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import AuthRoute from "./components/routes/AuthRoute";
import AdminRoute from "./components/routes/AdminRoute";
import Index from "./pages/adminDashboard";

function App() {
  return (
    <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/admin/login" />}
        />
        <AuthRoute path="/admin/login" render={() => <LoginPage isAdmin />} />
        <AdminRoute exact path="/admin" render={() => <Redirect to="/admin/dashboard"/>} />
        <AdminRoute path="/admin/dashboard" component={Index}/>
        <Route path='/logout' component={LogoutPage} />
    </Switch>
  );
}

export default App;
