import { Input } from "antd";
import Button from "antd/es/button";
import Field from "../field/Field";
import hideNumberInputArrows from "../../styles/hideNumberInputArrows";
import useForm from "../../hooks/useForm";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import Campaign from "../../models/Campaign";

function CampaignMarkAsCompletedForm({ onSkip, ...props }: any) {
  const classes = useStyles();
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    // resolver: Campaign.markAsCompleted(),
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={submitHandler}
          className={classes.customerAddressFormView}
        >
          <Field
            as={Input}
            type="number"
            name="planned_messages"
            placeholder="Planned Messages"
          />
          <Field
            as={Input}
            type="number"
            name="total_messages_sent"
            placeholder="Total Messages Sent"
          />
          <Field
            as={Input}
            type="number"
            name="total_messages_delivered"
            placeholder="Total Messages Delivered"
          />
          <Field
            as={Input}
            type="number"
            name="open_rate"
            placeholder="Open Rate"
          />
          <Field
            as={Input}
            type="number"
            name="response_rate"
            placeholder="Response Sent"
          />

          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Mark As Completed
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerAddressFormView: {
    ...hideNumberInputArrows,

    "& .ant-btn": {
      fontWeight: "500",
      letterSpacing: "0.45px",
    },
  },
}));

export default CampaignMarkAsCompletedForm;
