import { Input, message, Modal, Typography } from "antd";
import { ModalProps } from "antd/lib/modal";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { editMarketingCampaign, reconcileSettle } from "../../api/invoiceApis";
import CampaignMarkAsCompletedForm from "../forms/CampaignMarkAsCompletedForm";

interface CampaignMarkAsCompletedModalProps extends ModalProps {
  data: any;
  onUpdateDone: () => void;
}

export default function CampaignMarkAsCompletedModal({
  data,
  onUpdateDone,
  ...props
}: CampaignMarkAsCompletedModalProps) {
  const classes = useStyle();
  const [defaultValues] = useState({
    planned_messages: "",
    total_messages_sent: "",
    total_messages_delivered: "",
    open_rate: "",
    response_rate: "",
  });

  const handleSubmit = async (values: any) => {
    Object.keys(values).forEach((k) => values[k] == "" && delete values[k]);

    await editMarketingCampaign(data.id, {
      ...values,
      status: "COMPLETED",
    }).then((res) => {
      onUpdateDone();
      message.success(res?.message);
      // @ts-ignore
      props?.onCancel();
    });
  };

  return (
    <Modal {...props} title="Mark Campaign As Completed" footer={false}>
      <CampaignMarkAsCompletedForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
