import React, { useEffect } from "react";
import { Input, Table, Popconfirm, Button, DatePicker } from "antd";
import { useState } from "react";
import { ColumnProps } from "antd/lib/table";
import { createUseStyles } from "react-jss";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

function InvoiceTable({
  items,
  onChange,
  onDelete,
  calculateInvoiceTotal,
  disableAddRow = false,
  required = false,
}: any) {
  const classes = useStyles();
  const [tableData, setTableData] = useState<any>([]);
  const monthFormat = "MM/YY";
  const keysToIncludeCalc = [
    "sgst",
    "cgst",
    "total",
    "discount",
    "pack_price",
    "pack",
    "bill_quantity",
  ];
  const emptyData: any = {
    retail_store_id: items[0]?.retail_store_id,
    status: "2",
    name: "",
    pack: "",
    pack_price: "0",
    hsn: "",
    batch: "",
    expiry: "",
    quantity: "1:0",
    bill_quantity: "1:0",
    discount: "0",
    cgst: "0",
    sgst: "0",
    total: "0",
  };

  useEffect(() => {
    if (items) {
      tableData.length === 0 && setTableData([...items]);
    } else {
      setTableData([emptyData]);
    }
  }, [items]);

  // // remove this
  // useEffect(() => {
  // }, [tableData]);

  const addItemRow = () => {
    setTableData([...tableData, emptyData]);
  };

  const getQuantity = (quantity: any, pack_size: any) => {
    if (quantity === "" && pack_size !== "") {
      return 0;
    }

    let newQuantity: any = "";
    // if (pack_size === "") {
    //   newQuantity = quantity
    //   return newQuantity
    // } else {
    // let quantity_length = quantity.length;
    if (quantity.includes(":")) {
      var q = quantity.split(":");
      if (parseInt(q[1]) === NaN || q[1] === "") {
        newQuantity = parseInt(q[0]);
      } else {
        newQuantity = parseInt(q[0]) + parseInt(q[1]) / pack_size;
      }
    } else {
      newQuantity = parseInt(quantity);
    }

    if (newQuantity === NaN || newQuantity === "") {
      newQuantity = 0;
    }
    return newQuantity;
  };

  const getPackSize = (pack: any, pack_size: any) => {
    // let packSize = 1;

    // if (pack_size && pack_size !== undefined) {
    //   packSize = pack_size;
    // } else if (pack !== "") {
    //   try {
    //     packSize = eval(pack);
    //   } catch {
    //     packSize = 1;
    //   }
    // }
    // return packSize;

    let packSize = 1;
    if (pack !== "") {
      if (pack.includes("*")) {
        var p = pack.split("*");
        var s = p[1].split(" ");
        let isnum = /^\d+$/.test(s[0]);
        if (isnum) {
          packSize = parseInt(p[0]) * parseInt(p[1]);
        }
      } else if (pack.includes("ML")) {
        if (pack.includes("*")) {
          var p = pack.split("*");
          packSize = parseInt(p[0]);
        } else {
          packSize = 1;
        }
      } else if (pack == "") {
        packSize = 1;
      } else {
        packSize = parseInt(pack);
        if (Number.isNaN(packSize)) {
          packSize = 1;
        }
      }
    }
    return packSize;
  };

  // const calculateTotal = (data: any) => {
  //   let newData: any = JSON.parse(JSON.stringify(data));
  //   let tempData: any = JSON.parse(JSON.stringify(data));

  //   for (let i = 0; i < newData.length; i++) {
  //     let dataObj = tempData[i];
  //     for (var key in dataObj) {
  //       if (
  //         dataObj.hasOwnProperty(key) &&
  //         !keysToIncludeCalc.includes(key) &&
  //         key !== "quantity" &&
  //         key !== "bill_quantity"
  //       ) {
  //         dataObj[key] = dataObj[key] === "" ? 0 : parseFloat(dataObj[key]);
  //       }
  //     }
  //     const {
  //       pack,
  //       pack_size,
  //       pack_price,
  //       bill_quantity,
  //       discount,
  //       sgst,
  //       cgst,
  //     } = dataObj;

  //     const packSize = getPackSize(pack, pack_size);
  //     const calcQuantity = getQuantity(bill_quantity, packSize);

  //     let total = pack_price * calcQuantity - discount;
  //     let gst = (total * (cgst + sgst)) / 100;
  //     // total += gst;
  //     if (total === NaN || calcQuantity === 0) total = 0;

  //     newData[i]["total"] = total;
  //   }

  //   setTableData(newData);
  //   calculateInvoiceTotal(newData);
  //   onChange(newData);
  // };

  const onInputChange =
    (key: any, index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newData: any = [...tableData];
      let value = e.target.value;
      newData[index][key] = value;
      setTableData(newData);
      if (keysToIncludeCalc.includes(key)) {
        calculateInvoiceTotal(newData);
      }
      onChange(newData);
    };

  const deleteItem = (id: any, index: any) => {
    let newData = tableData.filter(
      (item: any, itemIndex: number) => itemIndex !== index
    );
    setTableData(newData);
    onDelete(newData, id);
    calculateInvoiceTotal(newData);
  };

  const AddRowButton = () => (
    <Button
      type="link"
      icon={<PlusOutlined />}
      disabled={disableAddRow}
      onClick={addItemRow}
    >
      Add Item
    </Button>
  );

  const onExpiryDateChange = (index: any) => (value: any, dateString: any) => {
    let newData: any = [...tableData];
    newData[index]["expiry"] = moment(value).format();
    setTableData(newData);
    onChange(newData, newData[index]["id"]);
  };

  const columns: ColumnProps<any>[] = [
    {
      title: "#",
      key: "Serial no.",
      align: "center",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
      render: (text, record, index) => (
        <Input
          size="middle"
          required
          value={text}
          onChange={onInputChange("name", index)}
        />
      ),
    },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
      width: 90,
      render: (text, record, index) => (
        <Input
          size="middle"
          required={required}
          value={text}
          onChange={onInputChange("pack", index)}
        />
      ),
    },

    {
      title: "HSN",
      dataIndex: "hsn",
      key: "hsn",
      width: 120,
      render: (text, record, index) => (
        <Input
          size="middle"
          value={text}
          onChange={onInputChange("hsn", index)}
        />
      ),
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      width: 120,
      render: (text, record, index) => (
        <Input
          size="middle"
          value={text}
          onChange={onInputChange("batch", index)}
        />
      ),
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
      key: "expiry",
      width: 150,
      render: (date, record, index) =>
        date !== "" && date !== null ? (
          <DatePicker
            inputReadOnly
            value={moment(date)}
            allowClear={false}
            format={monthFormat}
            picker="month"
            onChange={onExpiryDateChange(index)}
          />
        ) : (
          <DatePicker
            inputReadOnly
            format={monthFormat}
            picker="month"
            onChange={onExpiryDateChange(index)}
          />
        ),
    },
    {
      title: "Qty",
      dataIndex: "bill_quantity",
      key: "bill_quantity",
      width: 90,
      render: (text, record, index) => (
        <Input
          size="middle"
          required={required}
          value={text}
          onChange={onInputChange("bill_quantity", index)}
        />
      ),
    },
    {
      title: "MRP",
      dataIndex: "pack_price",
      key: "pack_price",
      width: 120,
      render: (text, record, index) => (
        <Input
          size="middle"
          type="number"
          step="any"
          required={required}
          prefix="₹"
          value={text}
          onChange={onInputChange("pack_price", index)}
        />
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 120,
      render: (amount, record, index) => (
        <Input
          size="middle"
          required={required}
          prefix="₹"
          type="number"
          step="any"
          value={amount}
          onChange={onInputChange("discount", index)}
        />
      ),
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      width: 90,
      render: (text, record, index) => (
        <Input
          size="middle"
          type="number"
          step="any"
          required={required}
          value={text}
          onChange={onInputChange("sgst", index)}
        />
      ),
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      width: 90,
      render: (text, record, index) => (
        <Input
          size="middle"
          type="number"
          step="any"
          required={required}
          value={text}
          onChange={onInputChange("cgst", index)}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 120,
      render: (amount, record, index) => (
        <Input
          size="middle"
          type="number"
          step="any"
          required={required}
          prefix="₹"
          value={amount}
          onPressEnter={addItemRow}
          onChange={onInputChange("total", index)}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      width: 80,
      render: (text, record, index) => (
        <div style={{ width: "100%" }}>
          <Popconfirm
            disabled={disableAddRow}
            title="Sure to delete?"
            onConfirm={() => deleteItem(record.order_item_id, index)}
          >
            <DeleteTwoTone disabled={disableAddRow} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <div style={{ padding: 20 }}>
        <Table
          bordered
          size="small"
          className={classes.invoiceTable}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{ y: 320 }}
          footer={AddRowButton}
        />
      </div>
    </>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  invoiceTable: {
    // customizing horizontal scrollbar for antBody
    "& .ant-table-body": {
      scrollbarWidth: "thin",

      "&:hover": {
        "&::-webkit-scrollbar": {
          width: 8,
          height: 8,
        },
      },
      "&::-webkit-scrollbar": {
        width: 4,
        height: 4,
        backgroundColor: "transparent",
        transition: "all 0.5s",
        "&:hover": {
          width: 8,
          height: 8,
        },
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        WebkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: "#d8d8d8",
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: "#c4c4c4",
      },
    },
  },
}));

export default InvoiceTable;
