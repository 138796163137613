import { UserOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import customeScrollbarStyle from "../styles/CustomScrollbar";
import { Input, Empty, Button } from "antd/es";
import { Select } from "antd/es";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import AddCustomerModal from "./AddCustomerModal";
import Loader from "./Loader";

function CustomersCard({
  isLoading,
  hideAddCustomer,
  customers,
  onCustomerAdd,
  onSearch,
  selectedStoreId,
  selectedCustomerUserId,
  setSelectedCustomerUserId,
}: any) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [addCustomerModalVisible, setAddCustomerModalVisible] = useState(false);

  const screen = useBreakpoint();
  const isLG = screen.lg;

  const handleSearchCHange = (e: any) => {
    let searchVal = e.target.value;
    setSearchValue(searchVal);
    onSearch(searchVal);
  };

  const handleSelectSearchChange = (searchVal: any) => {
    setSearchValue(searchVal);
    onSearch(searchVal);
  };

  const toggleAddCustomerModal = () => {
    setAddCustomerModalVisible(!addCustomerModalVisible);
  };
  const handleAddCustomerFormSave = (customer: any) => {
    setSelectedCustomerUserId(customer?.user_id);
    toggleAddCustomerModal();
    onCustomerAdd(customer);
  };

  const handleCustomerClick = (userId: any) => () => {
    if (userId === selectedCustomerUserId) setSelectedCustomerUserId("");
    else setSelectedCustomerUserId(userId);
  };

  const getCustomers = () => {
    if (searchValue !== "") {
      return customers.filter(
        (customer: any) =>
          customer?.user_id.toLowerCase().indexOf(searchValue.toLowerCase()) >=
            0 ||
          customer?.name.toLowerCase().indexOf(searchValue.toLowerCase()) >=
            0 ||
          customer?.phone_no.toLowerCase().indexOf(searchValue.toLowerCase()) >=
            0
      );
    }
    return customers;
  };

  return (
    <div className={classes.customersCard}>
      <div className={classes.customersCardTitle}>
        <div>
          <UserOutlined /> Choose Customer
        </div>
        {!hideAddCustomer && (
          <Button
            type="link"
            size="small"
            icon={<PlusOutlined />}
            onClick={toggleAddCustomerModal}
            className={classes.addCustomerBtn}
            disabled={selectedStoreId === ""}
          >
            Add
          </Button>
        )}
        <AddCustomerModal
          retailStoreId={selectedStoreId}
          visible={addCustomerModalVisible}
          onClose={toggleAddCustomerModal}
          onSave={handleAddCustomerFormSave}
        />
      </div>
      {isLG ? (
        <>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search customer"
            onChange={handleSearchCHange}
            className={classes.searchField}
          />
          {!!selectedStoreId ? (
            !isLoading ? (
              getCustomers().length ? (
                <div className={classes.itemsWrapper}>
                  {getCustomers().map((customer: any) => (
                    <div
                      key={customer?.user_id}
                      className={classes.customerItem}
                      onClick={handleCustomerClick(customer?.user_id)}
                      style={
                        customer?.user_id === selectedCustomerUserId
                          ? { background: "rgba(0,0,0,0.1)" }
                          : {}
                      }
                    >
                      <div className={classes.itemHeader}>
                        <b>#{customer?.user_id}</b>
                        <div>{customer?.name}</div>
                      </div>
                      <div className={classes.customerPhone}>
                        {customer?.phone_no ?? customer?.mobile_number}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className={classes.emptyContainer}>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No customers found."
                  />
                </div>
              )
            ) : (
              <Loader height="60vh" />
            )
          ) : (
            <div className={classes.emptyContainer}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Please select a store"
              />
            </div>
          )}
        </>
      ) : (
        <Select
          showSearch
          placeholder="Select Customer"
          optionFilterProp="children"
          value={
            selectedCustomerUserId !== "" ? selectedCustomerUserId : undefined
          }
          onSearch={handleSelectSearchChange}
          onChange={setSelectedCustomerUserId}
          // filterOption={(input: any, option: any) => {
          //   handleSelectSearchChange(input);
          //   return true;
          // }}
          className={classes.searchSelect}
        >
          {customers.map((customer: any) => (
            <Select.Option
              key={`#${customer.user_id} - ${customer.name}`}
              value={customer?.user_id}
            >
              #{customer.user_id} - {customer.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customersCard: {
    width: "100%",
    height: "100%",
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  customersCardTitle: {
    color: "#505050",
    fontSize: 22,
    padding: "5px 15px 10px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& > div .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  addCustomerBtn: {
    fontWeight: 600,
  },
  itemsWrapper: {
    overflow: "auto",
    height: "calc(100vh - 270px)",
    ...customeScrollbarStyle,
  },
  customerItem: {
    padding: [5, 20],
    fontSize: 15,
    fontWeight: 600,
    color: "#505050",
    opacity: 0.85,
    cursor: "pointer",
    transition: "all 0.3s",

    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(0,0,0,0.035)",
    },

    "& b": {
      fontWeight: "700 !important",
      color: "#111",
      paddingRight: 10,
    },
  },
  itemHeader: {
    display: "flex",
  },
  customerPhone: {
    fontSize: 10,
    color: "#000",
    opacity: 0.9,
  },
  searchField: {
    width: "94%",
    margin: "5px 3% 10px",

    "& .anticon": {
      paddingRight: 5,
    },
  },
  emptyContainer: {
    height: "calc(100vh - 270px)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchSelect: {
    width: "94%",
    margin: "5px 3% 10px",
    padding: 0,
    fontWeight: 600,
  },
  "@media (max-width: 768px)": {
    customersCard: {
      padding: "0.25rem",
    },
    customersCardTitle: {
      fontSize: 18,
      padding: "5px 15px 10px",

      "& > div .anticon": {
        fontSize: 18,
      },
    },
    searchSelect: {
      fontSize: 12,
    },
  },
}));

export default CustomersCard;
