import { createUseStyles } from "react-jss";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Image, Button } from "antd";
import { useState } from "react";
import Loader from "./Loader";

function ImageContainer({
  images,
  imageSrc,
  prevInvoice,
  nextInvoice,
  setPageLoading,
  hideNextPrevButtons = false,
}: any) {
  const classes = useStyles({ hideNextPrevButtons });
  const [imgHeight, setImgHeight] = useState(225);
  const [imgRotateDeg, setImgRotateDeg] = useState<any>(0);
  const [loading, setLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);

  const ZoomIn = () => imgHeight < 500 && setImgHeight(imgHeight + 25);
  const ZoomOut = () => imgHeight > 100 && setImgHeight(imgHeight - 25);

  const RotateLeft = () =>
    imgRotateDeg > -270 && setImgRotateDeg(imgRotateDeg - 90);
  const RotateRight = () =>
    imgRotateDeg < 270 && setImgRotateDeg(imgRotateDeg + 90);

  return (
    <div className={classes.invoiceImageContainer}>
      <div className={classes.invoiceNextPrevButtons}>
        <Button
          shape="circle"
          size="large"
          icon={<LeftOutlined />}
          disabled={!imageIndex}
          onClick={() => setImageIndex(imageIndex - 1)}
        />
        <Button
          shape="circle"
          size="large"
          icon={<RightOutlined />}
          disabled={imageIndex === images.length - 1}
          onClick={() => setImageIndex(imageIndex + 1)}
        />
      </div>
      <div className={classes.imageContainerInner}>
        <Image
          height={`${imgHeight}%`}
          style={{
            transform: `rotate(${imgRotateDeg}deg)`,
            display: loading ? "none" : "",
          }}
          src={images[imageIndex]}
          onLoad={() => setLoading(false)}
        />

        {loading ? <Loader height="100%" /> : <></>}
        <div className={classes.imageOptions}>
          <Button
            type="primary"
            shape="circle"
            size="small"
            icon={<ZoomInOutlined />}
            onClick={ZoomIn}
          />
          <Button
            type="primary"
            shape="circle"
            size="small"
            icon={<ZoomOutOutlined />}
            onClick={ZoomOut}
          />
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<RotateLeftOutlined />}
            onClick={RotateLeft}
          />
          <Button
            type="text"
            shape="circle"
            size="small"
            icon={<RotateRightOutlined />}
            onClick={RotateRight}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  invoiceImageContainer: {
    height: "40vh",
    width: "100%",
    padding: 4,
    position: "relative",
    background: "rgba(206, 206, 206, 0.3)",
    boxShadow:
      "inset 1px 1px 4px rgba(0, 0, 0, 0.25), inset -1px -1px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
  },
  invoiceNextPrevButtons: {
    height: "100%",
    width: "100%",
    padding: 10,
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageContainerInner: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    background: "transparent",
    display: "flex",
    justifyContent: "center",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& img": {
      width: "auto",
      objectFit: "contain",
      transition: "all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s",
    },
  },
  imageOptions: {
    width: ({ hideNextPrevButtons }) => (hideNextPrevButtons ? 150 : 200),
    position: "absolute",
    top: 10,
    right: 5,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",

    "& > .ant-btn-text": {
      color: "#333",
      "& .anticon": { fontSize: "1.15rem" },
    },

    "& > .ant-btn-primary": {
      background: "#333",
    },
  },
}));

export default ImageContainer;
