import {
  EditOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Input,
  message,
  Pagination,
  Table,
  Typography,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { getProductCatalogue } from "../api/invoiceApis";
import copy from "copy-text-to-clipboard";
import ShareProductModal from "./modals/ShareProductModal";
import Loader from "./Loader";

interface ProductsListCardProps {
  storeId: number;
  extraRight: React.ReactNode;
}

export default function ProductsListCard({
  storeId,
  extraRight,
}: ProductsListCardProps) {
  const classes = useStyle();
  const isLG = useBreakpoint().lg;

  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [sharedProductSku, setSharedProductSku] = useState("");
  const [shareType, setShareType] = useState<"search" | "product">("search");

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const [endOfList, setEndOfList] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
  });

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPagination((curr) => ({
        ...curr,
        currentPage: curr.currentPage + 1,
      }));
    }
  };

  const handleProductShare = (product: any) => () => {
    if (!!storeId) {
      let url = `https://app.raseet.com/dashboard/stores/${storeId}/product/${
        product?.product_id ?? product.id
      }`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }

    setShareType("product");
    setShareModalVisible(true);
    setSharedProductSku(product?.product_sku);
  };

  const handleShareModalCancel = () => {
    setShareModalVisible(false);
    setSharedProductSku("");
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setPagination({
      ...pagination,
      currentPage: page,
      pageSize: pageSize ?? 20,
    });
  };

  const handleSearch = (val: string) => setSearchInput(val);

  const handleShareSearchResults = () => {
    if (!searchInput.length) return message.info("Please search something...");

    if (!!storeId) {
      let url = `https://app.raseet.com/dashboard/stores/${storeId}/shop?catalog_key=2&search=${searchInput}`;
      copy(url);

      message.success("URL copied to clipboard!");
      return;
    }

    setShareType("search");
    setShareModalVisible(true);
  };

  const fetchProducts = async () => {
    let [count, ...products] = await getProductCatalogue(
      storeId,
      undefined,
      pagination.currentPage,
      pagination.pageSize,
      searchInput
    );

    if (pagination.currentPage > 1) {
      setProducts((curr) => [...curr, ...products]);
    } else {
      setProducts(products);
    }

    setEndOfList(products.length < pagination.pageSize);
    setLoading(false);
    setPagination((current) => ({
      ...current,
      totalPages: +count,
    }));
  };

  useEffect(() => {
    setLoading(true);
  }, [storeId, searchInput, pagination.pageSize]);

  useEffect(() => {
    fetchProducts();
  }, [loading, pagination.currentPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const productsTablecolums: any = [
    {
      title: "#",
      dataIndex: "index",
      width: "60px",
      align: "center",
      render: (value: any, row: any, index: any) =>
        pagination.currentPage === 1
          ? index + 1
          : +pagination.currentPage * 20 - 20 + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "160px",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      width: "160px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "SKU",
      dataIndex: "product_sku",
      key: "product_sku",
      width: "100px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      width: "65px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Domain",
      dataIndex: "product_domain",
      key: "product_domain",
      width: "65px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
      width: "65px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Price",
      dataIndex: "pack_price",
      key: "pack_price",
      width: "100px",
      render: (val: any, row: any) => (+row?.pack_price).toFixed(2),
    },
    {
      title: "Selling Price",
      dataIndex: "strike_price",
      key: "strike_price",
      width: "100px",
      render: (val: any, row: any) => (!!val ? (+val).toFixed(2) : " - "),
    },
    {
      title: "Share",
      dataIndex: "share",
      width: "80px",
      render: (val: any, row: any) => (
        <Button
          type="link"
          icon={<ShareAltOutlined />}
          onClick={handleProductShare(row)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "80px",
      render: (val: any, row: any) => (
        <Link to={`/admin/dashboard/edit-product/${row?.product_sku}`}>
          <Button type="link" icon={<EditOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <Card className={classes.productsListCard}>
      <div className={classes.cardHeader}>
        <div>
          <Typography.Title level={3} className={classes.cardTitle}>
            Manage Products
          </Typography.Title>
          <Link
            to={`/admin/dashboard/add-product?store=${
              !!storeId ? storeId : ""
            }`}
          >
            <Button size="small" type="primary" icon={<PlusOutlined />}>
              New Product
            </Button>
          </Link>

          <Button
            type="link"
            icon={<ShareAltOutlined />}
            hidden={searchInput === "" || !products.length}
            onClick={handleShareSearchResults}
          >
            Share Search Results
          </Button>
        </div>
        <div>
          <Input.Search
            allowClear
            placeholder="Search products..."
            onSearch={handleSearch}
          />
        </div>
        <div>{extraRight}</div>
      </div>

      {!loading ? (
        <Table
          dataSource={products}
          columns={productsTablecolums}
          size={isLG ? "middle" : "small"}
          scroll={{ y: "calc(100vh - 180px)" }}
          pagination={false}
          // pagination={{
          //   total: pagination.totalPages,
          //   pageSize: pagination.pageSize,
          //   current: pagination.currentPage,
          //   onChange: handlePageChange,
          // }}
        />
      ) : (
        <Loader height="70vh" />
      )}

      <ShareProductModal
        type={shareType}
        searchInput={searchInput}
        productSku={sharedProductSku}
        visible={shareModalVisible}
        onCancel={handleShareModalCancel}
      />
    </Card>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  productsListCard: {
    flex: 1,
    width: "100%",
    padding: "0.5rem",
    backgroundColor: "#fff",
    borderRadius: 5,
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
  },
  paginationWrapper: {
    padding: [20, 25, 10],
  },
  cardHeader: {
    paddingBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  cardTitle: {
    margin: "0 !important",
    paddingRight: 20,
  },
}));
