import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Typography } from "antd";
import { ModalProps } from "antd/es/modal";
import copyTextToClipboard from "copy-text-to-clipboard";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getAllRetailStores } from "../../api/invoiceApis";
import StoresSelector from "../field/StoresSelector";

interface ShareProductModalProps extends ModalProps {
  productSku: string;
  searchInput: string;
  type: "search" | "product";
}

export default function ShareProductModal({
  type,
  productSku,
  searchInput,
  ...props
}: ShareProductModalProps) {
  const classes = useStyle();
  const [retailStores, setRetailStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(0);
  const [copyUrl, setCopyUrl] = useState("");

  const fetchRetailStores = async () => {
    let result = await getAllRetailStores(500, 0, "RASEET");
    setRetailStores(result);
  };

  useEffect(() => {
    fetchRetailStores();

    return () => setSelectedStoreId(0);
  }, []);

  useEffect(() => {
    let newCopyUrl = "";
    if (type === "product") {
      newCopyUrl = `https://app.raseet.com/dashboard/stores/${selectedStoreId}/product/${productSku}`;
    } else if (type === "search") {
      newCopyUrl = `https://app.raseet.com/dashboard/stores/${selectedStoreId}/shop?catalog_key=2&search=${searchInput}`;
    }
    setCopyUrl(newCopyUrl);
  }, [type, productSku, selectedStoreId, searchInput]);

  return (
    <Modal
      {...props}
      title={"Please select a store"}
      footer={false}
      className={classes.shareProductModal}
      onCancel={() => {
        setSelectedStoreId(0);
        setCopyUrl("");
        // @ts-ignore
        props.onCancel(null);
      }}
    >
      <StoresSelector
        retailStores={retailStores}
        selectedStoreId={selectedStoreId}
        setSelectedStoreId={setSelectedStoreId}
      />
      {!!selectedStoreId && (
        <Input
          readOnly
          value={copyUrl}
          suffix={
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                copyTextToClipboard(copyUrl);
                message.success("URL copied to clipboard!");
              }}
            >
              COPY
            </Button>
          }
          className={classes.copyUrlInput}
        />
      )}
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  shareProductModal: {
    "& .ant-modal-body": {
      //   padding: [60, 20, 40],
      paddingBottom: 40,
    },
  },
  copyUrlInput: {
    marginTop: 20,
  },
}));
