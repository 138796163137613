import { Input } from "antd";
import Button from "antd/es/button";
import Field from "../field/Field";
import FormBox from "../FormBox";
import hideNumberInputArrows from "../../styles/hideNumberInputArrows";
import React from "react";
import useForm from "../../hooks/useForm";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import type { FormProps } from "../../hooks/useForm";
import { AnyARecord } from "dns";
import User from "../../models/User";

function CustomerAddressForm({ onSkip, ...props }: any) {
  const classes = useStyles();
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    resolver: User.customerAddress(),
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={submitHandler}
          className={classes.customerAddressFormView}
        >
          {/* <Field as="input" name="isAdmin" type="hidden" /> */}

          <Field
            as={Input}
            name="streetAddress"
            type="text"
            placeholder="Street Address"
          />

          <Field as={Input} name="state" type="text" placeholder="State" />

          <Field as={Input} name="city" type="text" placeholder="City" />

          <Field
            as={Input}
            name="locality"
            type="text"
            placeholder="Locality (or nearest Landmark)"
          />

          <Field
            as={Input}
            name="pincode"
            type="number"
            placeholder="Pincode"
          />

          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Save
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerAddressFormView: {
    ...hideNumberInputArrows,

    "& .ant-btn": {
      fontWeight: "500",
      letterSpacing: "0.45px",
    },
  },
}));

export default CustomerAddressForm;
