import {
  BlockOutlined,
  ContainerOutlined,
  EditOutlined,
  FundViewOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Drawer, Menu } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Link, useRouteMatch } from "react-router-dom";
import PlaceholderImage from "../assets/svg/placeholder-profile-img.svg";
import RaseetLogo from "../assets/svg/raseet-bw-logo-with-name.png";

function Topbar() {
  const classes = useStyles();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { path } = useRouteMatch("/admin/dashboard") || {};
  // const { userName } = getSession() as Session;

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const navLinks = [
    {
      to: path + "/review-invoices",
      label: "Review Invoices",
      icon: <FundViewOutlined />,
    },
    {
      to: path + "/add-invoice",
      label: "Add Invoice",
      icon: <EditOutlined />,
    },
    {
      to: path + "/place-order",
      label: "Place Reorder",
      icon: <ShoppingCartOutlined />,
    },
    {
      to: path + "/reorders",
      label: "Reorder List",
      icon: <ShoppingOutlined />,
    },
    {
      to: path + "/add-coupon",
      label: "Add Coupon",
      icon: <ContainerOutlined />,
    },
    {
      to: path + "/coupons",
      label: "Coupons List",
      icon: <BlockOutlined />,
    },
    {
      to: path + "/settings",
      label: "Settings",
      icon: <SettingOutlined />,
    },
    {
      to: "/logout",
      label: "Logout",
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className={classes.topbar}>
      <div className={classes.topbarLeft}>
        <Button
          type="text"
          size="large"
          icon={<MenuOutlined />}
          onClick={toggleDrawer}
          className={classes.drawerToggler}
        />
        <div className={classes.logo}>
          <img src={RaseetLogo} alt="Raseet Logo" />
        </div>

        <Drawer
          title={false}
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          visible={drawerVisible}
          className={classes.drawer}
        >
          {navLinks.map((navItem, index) => (
            <div key={index} className={classes.sidebarOption}>
              <Link to={navItem.to} onClick={toggleDrawer}>
                {navItem.icon} {navItem.label}
              </Link>
            </div>
          ))}
        </Drawer>
      </div>
      <div className={classes.topbarRight}>
        {/* ---User--- */}
        <div className={classes.user}>
          <img
            className={classes.profileImg}
            src={PlaceholderImage}
            alt="Profile Image"
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  topbar: {
    width: "100%",
    padding: "0.7rem",
    minHeight: "65px",
    borderBottom: `3px solid ${colors.light500}`,
    color: "#FFF",
    background: "#000",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topbarLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  drawerToggler: {
    margin: [0, 5],
    color: "#fff",
  },
  searchIcon: {
    height: "40px",
    width: "40px",
    margin: "0 0.7rem",
    backgroundColor: "#ebebeb",
    borderRadius: "50%",
    fontSize: "1.2rem",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchInput: {
    lineHeight: "40px",
    fontWeight: 600,
    border: "none",
    outline: "none",
  },
  topbarRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  topbarIconBtn: {
    height: "40px",
    width: "40px",
    fontSize: "2rem",
    borderRadius: "50%",
    backgroundColor: "#ebebeb",
    position: "relative",

    "& .anticon": {
      fontSize: "1.12rem",
    },
    "& .ant-badge": {
      position: "absolute",
      bottom: "11px",
      left: "11px",
    },
    "& .ant-scroll-number": {
      fontSize: "0.8rem",
    },
  },

  user: {
    flex: 0.85,
    marginLeft: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileImg: {
    padding: 4,
    width: 45,
  },
  buinessName: {
    paddingLeft: 5,
    fontSize: "0.85rem",
    fontWeight: 600,
    lineHeight: "20px",
  },
  userName: {
    paddingLeft: 5,
    fontSize: 10,
    color: colors.light500,
  },
  drawer: {
    "& .ant-drawer-body": {
      padding: [20, 0],
    },
  },
  logo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      height: 20,
      margin: "auto",
      objectFit: "contain",
    },
  },
  sidebarOption: {
    margin: "20px 20px 0px",
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "14px",

    "& *": {
      color: "#505050",
    },
    "& .anticon": {
      marginRight: 15,
      fontSize: 16,
    },
  },
  "@media (max-width: 768px)": {
    topbar: {
      padding: "0.5rem",
    },
    profileImg: {
      width: 40,
    },
  },
}));

export default Topbar;
