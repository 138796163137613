import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { createUseStyles } from "react-jss";
import Card from "antd/es/card";
import Title from "antd/es/typography/Title";
import moment from "moment";
import message from "antd/es/message";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import {
  getAllRetailStores,
  getCouponById,
  updateCoupon,
} from "../../api/invoiceApis";
import AddCouponForm from "../../components/forms/AddCouponForm";

const defaultValues = {
  id: 0,
  retail_store_id: 0,
  coupon_name: "",
  value: "",
  coupon_code: "",
  type: null,
  min_cart_amount: "",
  valid_till: "",
  is_active: null,
  description: "",
  max_value: "",
  usage_per_customer: "",
  quantity: "",
};

function EditCoupon() {
  // ------ other hooks
  const classes = useStyles();
  const { couponId } = useParams<any>();

  // ------ states
  const [retailStores, setRetailStores] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [coupon, setCoupon] = useState(defaultValues);

  // Fetch initial data for coupon and retail stores
  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    await getCouponById(+couponId).then(async (res) => {
      if (res?.length && res[0].id) {
        let stores = await getAllRetailStores();

        let { valid_till, start_date, customers, is_active } = res[0];
        let couponData = {
          ...coupon,
          ...res[0],
          is_active: is_active.toString(),
          start_date: !!start_date ? moment(start_date) : undefined,
          valid_till: !!valid_till ? moment(valid_till) : undefined,
        };

        setRetailStores(stores);
        setSelectedCustomers(customers);
        setCoupon(couponData);
      }
    });
  };

  const handleOnSubmit = async ({ max_value, ...values }: any) => {
    const data = {
      ...values,
      start_date: values.start_date
        ? moment(values.start_date).format()
        : undefined,
      valid_till: values.valid_till
        ? moment(values.valid_till).format()
        : undefined,
      customers: selectedCustomers,
    };

    if (!!!data.start_date) {
      delete data.start_date;
    }
    if (!!!data.valid_till) {
      delete data.valid_till;
    }

    if (values.type === "percentage") {
      data.max_value = max_value;
    }

    await updateCoupon(+coupon?.id, +coupon?.retail_store_id, data)
      .then((res) => {
        if (res?.length && res[0].id) {
          message.success("Coupon updated successfully!");
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  return (
    <div className={classes.addCouponPage}>
      <Card>
        <Title level={3} className={classes.pageTitle}>
          <PlusCircleOutlined />
          Add Coupon
        </Title>
        {coupon.coupon_code && (
          <AddCouponForm
            submitBtnText="Update"
            defaultValues={coupon}
            onSubmit={handleOnSubmit}
            retailStores={retailStores}
            selectedCustomers={selectedCustomers}
            onSelectedCustomerChange={setSelectedCustomers}
          />
        )}
      </Card>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCouponPage: {
    height: "100vh",
    padding: [20, "7vw"],
    background: "#f9f9f9",

    "& .ant-card": {
      boxShadow:
        "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    },
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    "& .anticon": {
      marginRight: 10,
    },
  },
}));

export default EditCoupon;
