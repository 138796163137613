import React from "react";
import { Table } from "antd";
import { createUseStyles } from "react-jss";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { ColumnsType } from "antd/es/table";
import customeScrollbarStyle from "../../styles/CustomScrollbar";

type OrderSummaryTableCardProps = {
  size: SizeType;
  items: Array<Object>;
  summary?: any;
  customColumns?: ColumnsType[];
  hideBg?: boolean;
};

function OrderSummaryTableCard({
  size,
  items,
  hideBg,
  summary,
  customColumns,
}: OrderSummaryTableCardProps) {
  const classes = useStyles({ hideBg });

  const columns: any = [
    {
      title: "#",
      dataIndex: "key",
      align: "center",
      width: "45px",
      render: (key: number) => `${key}`,
    },
    {
      title: "Item",
      dataIndex: "name",
      width: "160px",
      render: (name: string) => `${name}`,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      width: "60px",
      render: (quantity: string) => `${+quantity}`,
      // sorter: (a: any, b: any) => +a.quantity - +b.quantity,
    },
    {
      title: "Availability",
      dataIndex: "isAvailable",
      width: "120px",
      render: (isAvailable: boolean) =>
        isAvailable ? (
          <CheckCircleFilled className={classes.availableIcon} />
        ) : (
          <CloseCircleFilled className={classes.unavailableIcon} />
        ),
      filters: [
        {
          text: "Available",
          value: true,
        },
        {
          text: "Unavailable",
          value: false,
        },
      ],
      onFilter: (value: any, item: any) => item.isAvailable === value,
    },
  ];

  return (
    <div className={classes.orderItemsTableContainer}>
      <div hidden={hideBg}>
        <div className={classes.tableTitle}>Order Summary</div>
        <div className={classes.itemsCount}>{items.length} ITEMS</div>
      </div>
      <Table
        className={classes.orderItemsTable}
        size="small"
        scroll={{ y: 200 }}
        pagination={false}
        columns={customColumns ? customColumns : columns}
        dataSource={items}
        summary={summary}
      />
    </div>
  );
}

// Styles
const trBorderRadius = "15px";
const useStyles = createUseStyles(({ colors }: Theme) => ({
  orderItemsTableContainer: {
    width: "100%",
    padding: "0.8rem",
    borderRadius: "5px",
    backgroundColor: ({ hideBg }) => (hideBg ? "" : "rgba(34, 94, 119, 0.1)"),

    "& > div": {
      minHeight: "25px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  orderItemsTable: {
    width: "100%",

    "& *": {
      fontSize: 12,
    },

    // auto resizing table bug fix
    "& .ant-spin-nested-loading": {
      overflow: "hidden",
    },

    // customizing
    "& .ant-table": {
      width: "100%",
      background: "transparent",
      overflow: "hidden",
      borderRadius: trBorderRadius,
    },

    "& .ant-table-header": {
      overflow: "hidden",
      borderRadius: trBorderRadius,
      borderBottomRightRadius: 15,
    },

    "& thead": {
      background: colors.light100,
      "& .anticon *": {
        fontSize: "10px !important",
      },
      "& th": {
        height: 25,
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        color: "#838383",
      },
    },

    "& tbody .ant-table-row td": {
      paddingTop: "6.5px !important",
      paddingBottom: "6.5px !important",
      color: "#000",
    },

    // customizing horizontal scrollbar for antBody
    "& .ant-table-body": {
      ...customeScrollbarStyle,
    },

    "& .ant-table-row:hover": {
      "& td": {
        background: "transparent !important",
        "&:first-child": {
          borderBottomLeftRadius: trBorderRadius,
          borderTopLeftRadius: trBorderRadius,
        },
        "&:last-child": {
          borderTopRightRadius: trBorderRadius,
          borderBottomRightRadius: trBorderRadius,
        },
      },
    },
  },
  tableTitle: {
    margin: "0 0.7rem",
    fontSize: 14,
    fontWeight: 500,
    color: colors.dark1000,
  },
  unavailableItem: {
    color: "#8D7F7F",
  },
  itemsCount: {
    fontSize: 12,
    color: "#E76F51",
    fontWeight: 500,
  },
  availableIcon: {
    marginLeft: "5px",
    color: "#359C10",
  },
  unavailableIcon: {
    marginLeft: "5px",
    color: "#D10B0B",
  },
  textCenter: {
    textAlign: "center",
  },
  textUnderline: {
    textDecoration: "underline",
  },
  "@media (max-width: 1050px)": {
    orderItemsTable: {
      "& .ant-table-thead, & .ant-table-tbody": {
        fontSize: 12,
      },
    },
  },
}));

export default OrderSummaryTableCard;
