import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

function CartFab({ cartId }: any) {
  const classes = useStyles();

  return (
    <Link to={`/admin/dashboard/place-order/${cartId}`}>
      <Button
        type="primary"
        size="large"
        shape="circle"
        className={classes.cartFab}
        icon={<ShoppingCartOutlined />}
      />
    </Link>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  cartFab: {
    width: "55px !important",
    height: "55px !important",
    right: "20vh",
    bottom: "10vh",
    position: "fixed",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",

    "& .anticon": {
      fontSize: 26,
      lineHeight: "50px",
    },
  },
  "@media (max-width: 768px)": {
    cartFab: {
      width: "50px !important",
      height: "50px !important",
      right: "8vh",
      bottom: "7vh",

      "& .anticon": {
        fontSize: 24,
        lineHeight: "45px",
      },
    },
  },
}));

export default CartFab;
