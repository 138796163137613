import { message } from "antd";
import {
  createOrUpdateInvoice,
  updateOrderStatus,
  sendPublishSMS,
} from "../api/invoiceApis";

export default async function saveAndPublished(
  data: any,
  onSuccess: any,
  noSMS?: boolean
) {
  message.loading({ content: "Wait a sec...", key: "message" });
  const result = await createOrUpdateInvoice({
    ...data,
    saveAsType: "Publish",
  })
    .then(async (res) => {
      await updateOrderStatus(res.order, { status: 1 });
      if (res.order) {
        message.success(
          {
            content: "Published successfully.!",
            key: "message",
          },
          0
        );
        !noSMS && (await sendPublishSMS(res.smsData));
        onSuccess(res);
      } else {
        message.error({ content: "Something went wrong.!", key: "message" }, 0);
      }
    })
    // .then(response => response.json()).then(response => { console.log(response)})
    .catch((e) =>
      message.error({ content: "Something went wrong.!", key: "message" })
    );

  return result;
}
