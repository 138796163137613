import { useEffect, useState } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHistory, useRouteMatch } from "react-router";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Search from "antd/lib/input/Search";
import AutoComplete from "antd/lib/auto-complete";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import Button from "antd/es/button";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {
  deleteAllCartItems,
  deleteCart,
  deleteCartItems,
  generatePutUrl,
  getCart,
  getCartItems,
  getCartLoyaltyInfo,
  getCustomer,
  getProducts,
  insertPrescription,
  insertReorder,
  insertReorderItems,
  insertReorderLoyalty,
  updateCartItemsByProductId,
  uploadImage,
  applyCoupon,
  getCustomerCoupons,
  getUserById,
  getShipping,
} from "../../api/invoiceApis";
import Loader from "../../components/Loader";
import EmptyCart from "../../components/EmptyCart";
import NumberSelect from "../../components/NumberSelect";
import AddressModal from "../../components/AddressModal";
import { CashIcon, GoldenCrownIcon } from "../../components/customIcons";
import { MobileOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Radio, Space, Typography } from "antd";

// --- custom functions

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

const getAddressString = (customer: any) =>
  (customer.line1 ? customer.line1 + ", " : "") +
  (customer.line2 ? customer.line2 + ", " : "") +
  (customer.landmark ? customer.landmark + ", " : "") +
  (customer.city ? customer.city + ", " : "") +
  (customer.state ? customer.state + ", " : customer.state) +
  (customer.pincode ? customer.pincode + ", " : customer.pincode);

const getReorderItemDataObject = (item: any, reorder_id: any) => ({
  reorder_id,
  product_id: item?.product_id,
  quantity: item?.quantity,
  discount: item?.discount,
  sub_total: item?.sub_total,
  total: item?.total,
  sgst: item?.sgst,
  cgst: item?.cgst,
});

function CartPage() {
  // ----- other hooks
  const classes = useStyles();
  const history = useHistory();
  const screen = useBreakpoint();
  const isLG = screen.lg;
  const {
    params: { cartId },
  } = useRouteMatch<any>() || {};

  // ----- states
  const [couponOptions, setCouponOptions] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("online");
  const [pageData, setPageData] = useState<any>({
    loading: true,
    cart: [],
    cartItems: [],
    products: [],
    loyaltyInfo: {},
    prescriptions: [],
    shippingCharge: 0,
    redeemLoyaltyPoints: false,
    addressModalVisible: false,
    delivery: {
      deliverTo: "",
      address: "",
    },
  });

  // fetch Initial data
  useEffect(() => {
    fetchInitialData();
  }, []);

  // fetch cart detail on cart items change in state
  useEffect(() => {
    if (!pageData.loading) {
      fetchCart();
    }
  }, [pageData.cartItems]);

  // ---------
  // ---------
  // ---------

  const handlePaymentMethodChange = (e: any) => {
    setPaymentMethod(e.target.value);

    if (e.target.value === "cod" && pageData.redeemLoyaltyPoints) {
      setPageData({ ...pageData, redeemLoyaltyPoints: false });
    }
  };

  const fetchInitialData = async () => {
    let data: any = {},
      items = [];

    if (pageData.loading && cartId !== "undefined") {
      const cart = await getCart(+cartId);

      //  execute only if cart exists

      if (cart?.length > 0 && cart !== undefined) {
        // fetching customer details
        const customer = await getCustomer(cart[0].user_id);
        const user = await getUserById(cart[0].user_id);

        // fetch coupons suggestions
        const coupons = await getCustomerCoupons(
          +cart[0].retail_store_id,
          +cart[0].price_with_gst,
          +cart[0].user_id
        );

        // fetching loyalty details
        const loyaltyInfo = await getCartLoyaltyInfo(
          +cartId,
          +cart[0]?.retail_store_id,
          +cart[0]?.user_id
        );

        // fetching cart items
        const cartItems = await getCartItems(+cartId);

        // fetch shippingCharge
        const { shippingCharge } = await getShipping(
          +cart[0]?.retail_store_id,
          +cartId
        );

        // fetching pack_price from products
        for (let i = 0; i < cartItems.length; i++) {
          const productDetails = await getProducts(
            cartItems[i]?.product_id,
            +cart[0]?.retail_store_id,
            +cart[0]?.user_id
          );
          items.push({
            ...productDetails[0],
            ...cartItems[i],
          });
        }

        const delivery = {
          deliverTo: user?.name ?? customer[0]?.name,
          address: getAddressString(customer[0]),
        };

        data = {
          shippingCharge,
          couponsList: coupons,
          cartItems: items,
          delivery: delivery,
          loyaltyInfo,
          cart: cart[0],
        };
      }

      setCouponOptions(data.couponsList);
      setPageData({
        ...pageData,
        loading: false,
        cart: data.cart,
        shippingCharge: data.shippingCharge,
        cartItems: data.cartItems,
        delivery: data.delivery,
        loyaltyInfo: data.loyaltyInfo,
      });
    }
  };

  const fetchCart = async () => {
    const cart = await getCart(+cartId);
    const { shippingCharge } = await getShipping(
      +cart[0]?.retail_store_id,
      +cartId
    );

    setPageData({ ...pageData, cart: cart[0], shippingCharge });
  };

  // clear cart - delete cart and its all cart items
  const clearCart = async () => {
    await deleteAllCartItems(parseInt(cartId));
    await deleteCart(parseInt(cartId));
    setPageData({ ...pageData, cartItems: [] });
  };

  const placeReorder = async () => {
    if (!pageData.loading) {
      // push uploaded prescription
      let presId = await pushPrescription();

      // data to send in inserReorder()
      let reorderData = {
        cart_id: pageData.cart.id,
        retail_store_id: pageData.cart.retail_store_id,
        payment_mode: paymentMethod,
        payment_status: `'${pageData.cart.payment_status}'`,
        discount: pageData.cart.discount,
        gst: pageData.cart.gst,
        prescription_id: presId,
        subtotal: pageData.cart.price_with_out_gst,
        grandtotal: +getTotalAmount(),
        user_id: pageData.cart.user_id,
      };

      // data to send in insertReorderLoyalty()
      let loyaltyData = {
        cart_id: pageData.cart.id,
        reorder_amount: getTotalAmount(true),
        retail_store_id: +pageData?.cart?.retail_store_id,
        customer_id: +pageData?.cart?.user_id,
        use_loyalty_points: `${pageData.redeemLoyaltyPoints}`,
      };

      setPageData({ ...pageData, loading: true });

      await insertReorder(reorderData).then(async (result) => {
        // insert loyaly data only if redeem checkbox is checked
        console.log("LP", pageData.redeemLoyaltyPoints);
        if (pageData.redeemLoyaltyPoints) {
          await insertReorderLoyalty({
            ...loyaltyData,
            reorder_id: +result[0].id,
          });
        }

        // inserting reorder items
        for (var i = 0; i < pageData.cartItems?.length; i++) {
          let itemData = getReorderItemDataObject(
            pageData.cartItems[i],
            result[0].id
          );
          await insertReorderItems(itemData);
        }

        await clearCart();
        message.success("Order Placed Successfully.!");
      });
    }
  };

  const pushPrescription = async () => {
    const fileName = [];

    // execute only if there's at least 1 prescription
    if (pageData.prescriptions?.length > 0) {
      for (let i = 0; i < pageData.prescriptions?.length; i++) {
        if (!pageData.prescriptions[i].available) {
          const putUrl = await generatePutUrl(
            pageData.prescriptions[i]?.name,
            pageData.prescriptions[i]?.file?.type
          );
          await uploadImage(putUrl, pageData.prescriptions[i].file);
        }
        fileName.push(pageData.prescriptions[i].name);
      }

      const data = {
        userId: pageData.cart.user_id,
        retailStoreId: pageData.cart.retail_store_id,
        prescriptionData: `'{${fileName}}'`,
      };
      const prescription = await insertPrescription(data);

      return prescription[0]?.id;
    }
    return null;
  };

  const removeCartItem = async (itemId: number) => {
    await deleteCartItems(itemId).then((result) => {
      let newItemsArray = pageData.cartItems.filter(
        (cartItem: any) => cartItem.id !== result[0].id
      );
      setPageData({ ...pageData, cartItems: newItemsArray });
    });
  };

  const handleQuantityChange = async (newQty: number, item: any) => {
    let productPrice = item?.strike_price ?? item.pack_price;
    let sub_total = getPriceWithoutGst(
      +item.sgst + +item.cgst,
      +productPrice * +newQty
    );

    console.log(item);

    let newData = {
      product_section: "cart",
      product_sku: item?.product_sku,
      sub_total: sub_total.toFixed(2),
      quantity: +newQty,
      total: (+productPrice * +newQty).toFixed(2),
    };

    await updateCartItemsByProductId(+cartId, +item.product_id, newData).then(
      (result) => {
        if (result[0]?.product_id) {
          updateItemInState(result[0]?.product_id, newData);
        }
      }
    );
  };

  const updateItemInState = (productId: string, newData: any) => {
    let newItems: any = [...pageData.cartItems];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].product_id === productId) {
        newItems[i] = { ...newItems[i], ...newData };
      }
    }
    setPageData({ ...pageData, cartItems: newItems });
  };

  const handleRedeemCheckboxChange = (e: CheckboxChangeEvent) => {
    setPageData({
      ...pageData,
      redeemLoyaltyPoints: e.target.checked,
    });
  };

  const getLoyaltyPointsDeduction = (getRedeemable = false) => {
    let totalAmount = +pageData?.cart?.price_with_gst;

    let loyaltyPointsDeduction = 0.0;

    if (+pageData.loyaltyInfo?.loyalty_points > 100) {
      let redeemableLP = +pageData.loyaltyInfo?.loyalty_points - 100;

      if (pageData.redeemLoyaltyPoints || getRedeemable) {
        if (pageData.loyaltyInfo && redeemableLP >= totalAmount) {
          loyaltyPointsDeduction = totalAmount;
        } else {
          loyaltyPointsDeduction = redeemableLP;
        }
      }
    }

    return (+loyaltyPointsDeduction).toFixed(2);
  };

  const getTotalAmount = (dontAddLoyalty = false) => {
    let totalAmount = +pageData?.cart?.price_with_gst;

    let loyaltyPointsDeduction = 0.0;

    if (pageData.redeemLoyaltyPoints && !dontAddLoyalty) {
      loyaltyPointsDeduction = +getLoyaltyPointsDeduction();
      totalAmount -= loyaltyPointsDeduction;
    }

    if (totalAmount < 0) {
      totalAmount = 0;
    }

    return (+totalAmount + +pageData?.shippingCharge).toFixed(2);
  };

  const handleAddressFormSave = (address: any) => {
    setPageData({
      ...pageData,
      addressModalVisible: false,
      delivery: {
        deliverTo: pageData.delivery.deliverTo,
        address: getAddressString(address),
      },
    });
  };

  // handle prescription upload
  const handleGallery = (target: any) => {
    const filesArr = Array.from(target.files);

    if (target.files.length >= 0) {
      filesArr.forEach((file: any) => {
        if (file.size < 2097152) {
          const data = {
            file: file,
            imageUrl: URL.createObjectURL(file),
            available: false,
            name: pageData.cart.user_id + "-" + Date.now(),
          };

          setPageData({
            ...pageData,
            prescriptions: [...pageData.prescriptions, data],
          });
        } else {
          alert("File size > 2MB");
        }
      });
    }

    // clear upload box
    target.value = "";
  };

  const deletePreview = (deleteIndex: number) => {
    return Modal.confirm({
      title: "Do you Want to delete this Prescription?",
      icon: false,
      centered: true,
      width: "250px",
      className: classes.deletePrescriptionModal,
      okText: "Delete",
      cancelText: "No",
      okButtonProps: {
        type: "default",
        size: "small",
        danger: true,
        style: {
          color: "#DD1100",
          borderColor: "#DD1100",
        },
      },
      cancelButtonProps: {
        size: "small",
        style: {
          color: "#2A9D8F",
          borderColor: "#2A9D8F",
        },
      },
      onOk() {
        const newPrescriptions = pageData.prescriptions.filter(
          (p: any, index: any) => index !== deleteIndex
        );
        setPageData({ ...pageData, prescriptions: newPrescriptions });
      },
    });
  };

  const handlePlaceOrderClick = () => {
    Modal.confirm({
      title: "Confirm Placing This Reorder.",
      okText: "Confirm",
      onOk: placeReorder,
    });
  };

  const toggleAddressModal = () => {
    setPageData({
      ...pageData,
      addressModalVisible: !pageData.addressModalVisible,
    });
  };

  const handleCouponCodeApply = async (couponCode: string) => {
    if (
      pageData.cart.coupon_code !== couponCode &&
      (pageData.cart.coupon_code !== null || couponCode !== "")
    ) {
      await applyCoupon(couponCode, +cartId).then((res) => {
        if (res?.length && res[0].id) {
          setPageData({ ...pageData, cart: res[0] });
        } else {
          message.error(res.err || res);
        }
      });
    }
  };

  const goBack = () => {
    history.goBack();
  };

  if (pageData?.loading) {
    return <Loader />;
  }

  const CartSection = () => (
    <>
      <div className={classes.pageRightHeader}>
        <span>{pageData.cartItems.length} Items</span>
        <Button
          size="small"
          icon={<PlusOutlined />}
          className={classes.addMoreItemsBtn}
          onClick={history.goBack}
        >
          Add Items
        </Button>
        <Button
          danger
          size="small"
          className={classes.clearCartButton}
          onClick={clearCart}
        >
          <DeleteOutlined /> Clear Cart
        </Button>
      </div>
      <div className={classes.cartItemsWrapper}>
        {pageData.cartItems.map((cartItem: any) => (
          <div className={classes.cartItem}>
            <div className={classes.cartItemLeft}>
              <div className={classes.itemName}>{cartItem?.name}</div>

              <div className={classes.cartItemLeftBottom}>
                <NumberSelect
                  till={50}
                  size="small"
                  value={cartItem?.quantity}
                  onChange={(value: any) =>
                    handleQuantityChange(value, cartItem)
                  }
                />
                <div className={classes.cartItemTotal}>
                  ₹ {parseFloat(cartItem?.total).toFixed(2)}
                </div>
              </div>
            </div>
            <div className={classes.cartItemRight}>
              <Button
                type="text"
                size="small"
                className={classes.removeItemButton}
                onClick={() => removeCartItem(parseInt(cartItem.id))}
              >
                REMOVE
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  );

  const CouponACLabel = ({ coupon }: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {coupon.coupon_code}
      <span>
        {coupon.value}
        {coupon.type === "value" ? "₹" : "%"}
      </span>
    </div>
  );

  return (
    <Row className={classes.cartPage}>
      {pageData?.cartItems?.length ? (
        <>
          <Col span={isLG ? 12 : 24} className={classes.pageLeft}>
            <div className={classes.pageLeftHeader}>Cart Summary</div>

            <div className={classes.deliveryAddressWrapper}>
              <div>
                <div className={classes.deliverTo}>
                  Deliver To: <b>{pageData.delivery.deliverTo}</b>
                </div>
                <div className={classes.deliveryAddressText}>
                  {pageData.delivery.address}
                </div>
              </div>

              <Button type="link" size="small" onClick={toggleAddressModal}>
                Edit Address
              </Button>
            </div>

            <AddressModal
              visible={pageData.addressModalVisible}
              onClose={toggleAddressModal}
              onSave={handleAddressFormSave}
              customerUserId={pageData?.cart?.user_id}
            />

            <div className={classes.addPresWrapper}>
              <div className={classes.addPresWrapperHeader}>
                <div>Upload Prescription Here</div>
                <input
                  id="gallery"
                  accept="image/*"
                  type="file"
                  multiple={true}
                  onChange={(e) => handleGallery(e.target)}
                />
              </div>

              {pageData.prescriptions.length > 0 ? (
                <Row>
                  <Col span={24} className={classes.uploadsBoxTitle}>
                    Uploaded prescriptions
                  </Col>
                  {pageData.prescriptions.map(
                    (prescription: any, index: number) => (
                      <Col style={{ padding: "6px" }}>
                        <div className={classes.previewBox}>
                          <div
                            className={classes.previewBoxCutIcon}
                            onClick={() => deletePreview(index)}
                          >
                            <span>x</span>
                          </div>
                          <img
                            src={prescription.imageUrl}
                            width="100%"
                            height="100%"
                            alt={prescription.name}
                            style={{ objectFit: "contain" }}
                            // onClick={() => setPreviewModalVisible(true)}
                          />
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              ) : (
                <></>
              )}
            </div>
            <div className={classes.applyCouponWrapper}>
              <div className={classes.applyCouponTitle}>
                <span>Have any coupon code?</span>

                {pageData.cart.coupon_id !== null && (
                  <span style={{ fontSize: 12 }}>
                    APPLIED COUPON : <b>{pageData.cart.coupon_code}</b>
                  </span>
                )}
              </div>

              <AutoComplete
                style={{ width: "100%" }}
                onSelect={handleCouponCodeApply}
                options={couponOptions.map((item: any) => ({
                  value: item.coupon_code,
                  label: <CouponACLabel coupon={item} />,
                }))}
              >
                <Search
                  allowClear
                  size="large"
                  enterButton="Apply"
                  onSearch={handleCouponCodeApply}
                  placeholder="Enter your code here..."
                />
              </AutoComplete>
            </div>

            {!isLG && <CartSection />}

            {pageData.loyaltyInfo &&
            pageData.loyaltyInfo.loyalty_points &&
            +pageData.loyaltyInfo?.loyalty_points > 100 ? (
              <div className={classes.redeemLoyaltyPointsWrapper}>
                <div className={classes.redeemLoyaltyPointsCheckbox}>
                  <Checkbox
                    disabled={paymentMethod !== "online"}
                    checked={pageData.redeemLoyaltyPoints}
                    onChange={handleRedeemCheckboxChange}
                  />
                </div>
                <div className={classes.redeemLoyaltyPointsContent}>
                  <div className={classes.redeemLoyaltyPointsText}>
                    Redeem Loyalty Points
                  </div>
                  <div className={classes.redeemLoyaltyPointsValue}>
                    You can redeem {parseFloat(getLoyaltyPointsDeduction(true))}{" "}
                    Loyalty Points on this order
                  </div>
                </div>
                <div className={classes.redeemLoyaltyPointsIcon}>
                  <GoldenCrownIcon />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className={classes.priceDetailsWrapper}>
              <div className={classes.priceDetailsTitle}>Price Details</div>
              <div className={classes.priceDetailsItem}>
                <span>Total MRP</span>
                <span>
                  ₹{" "}
                  {(
                    +pageData?.cart?.price_with_gst +
                    +pageData?.cart?.coupon_discount
                  ).toFixed(2)}
                </span>
              </div>
              <div className={classes.priceDetailsItem}>
                <span>Loyalty Point Deduction</span>
                <span>₹ {getLoyaltyPointsDeduction()}</span>
              </div>
              <div className={classes.priceDetailsItem}>
                <span>Coupon Discount</span>
                <span>₹ {(+pageData?.cart?.coupon_discount).toFixed(2)}</span>
              </div>
              <div className={classes.priceDetailsItem}>
                <span>Shipping Charge</span>
                <span>₹ {(+pageData?.shippingCharge).toFixed(2)}</span>
              </div>
              <div className={classes.totalAmount}>
                <span>Total Amount</span>
                <span>₹ {getTotalAmount()}</span>
              </div>
            </div>

            {pageData.loyaltyInfo?.retail_store_id ? (
              <div className={classes.loyaltyPointsSummaryWrapper}>
                <GoldenCrownIcon />
                <div className={classes.loyaltyPointsSummary}>
                  You will earn{" "}
                  <span>
                    {pageData.loyaltyInfo?.loyalty_points_to_be_earned}
                  </span>{" "}
                  Loyalty points on this purchase
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className={classes.selectPaymentMethodWrapper}>
              <Typography.Text className={classes.selectPaymentMethodText}>
                Select Payment Method
              </Typography.Text>

              <Radio.Group
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
              >
                <Space direction="vertical" size="small">
                  <Card>
                    <Space>
                      <Radio value="online" />
                      <MobileOutlined />
                      <Typography.Text>Pay Online</Typography.Text>
                    </Space>
                  </Card>

                  <Card>
                    <Space>
                      <Radio value="cod" />
                      <div>
                        <Space>
                          <CashIcon />
                          <Typography.Text>Cash On Delivery</Typography.Text>
                        </Space>
                        <Typography.Text
                          type="secondary"
                          style={{
                            fontSize: 8,
                            marginTop: 3,
                            display: "block",
                          }}
                        >
                          You cannot redeem loyalty points on COD orders.
                        </Typography.Text>
                      </div>
                    </Space>
                  </Card>
                </Space>
              </Radio.Group>
            </div>

            <Button
              type="primary"
              block
              onClick={handlePlaceOrderClick}
              className={classes.checkoutButton}
              // disabled={pageData.delivery.address === false}
            >
              Place Order
            </Button>
          </Col>
          {isLG && (
            <Col span={isLG ? 12 : 24} className={classes.pageRight}>
              <CartSection />
            </Col>
          )}
        </>
      ) : (
        <EmptyCart onButtonClick={goBack} />
      )}
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  cartPage: {
    height: "100vh",
    overflow: "hidden",
  },
  pageLeft: {
    height: "100vh",
    overflow: "auto",
    padding: [0, 20],
  },
  pageRight: {
    padding: [0, 15],
  },
  pageLeftHeader: {
    height: 80,
    padding: 20,
    lineHeight: "40px",
    fontSize: 20,
    fontWeight: 600,
    color: "#505050",
  },
  pageRightHeader: {
    height: 80,
    padding: 20,
    lineHeight: "40px",
    fontSize: 20,
    fontWeight: 600,
    color: "#505050",
    display: "flex",
    alignItems: "center",
  },
  cartItemsCount: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    fontVariant: "small-caps",
    color: "#505050",
  },
  addMoreItemsBtn: {
    marginLeft: 20,
    "& .anticon": {
      fontSize: 12,
    },
  },
  clearCartButton: {
    marginLeft: "auto",
    color: "#D62D20",
    border: "1px solid #D62D20",
    borderRadius: 3,
    fontSize: 9,
    fontWeight: 500,
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .anticon": {
      fontSize: 10,
    },

    "& span:last-child": {
      marginLeft: 4,
    },
  },
  addPresWrapper: {
    width: "100%",
    marginBottom: 15,
    padding: [8, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    fontSize: 16,
    fontWeight: 600,
    color: "#505050",

    "& > .ant-row": {
      padding: [20, 0],
    },
  },
  addPresWrapperHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& input": {
      fontSize: 14,
    },
  },
  completeYourAddressWrapper: {
    width: "100%",
    height: 50,
    marginBottom: 15,
    padding: [8, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    fontSize: 16,
    fontWeight: 600,
    color: "#505050",
  },
  deliveryAddressWrapper: {
    width: "100%",
    marginBottom: 15,
    padding: [8, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deliverTo: {
    paddingBottom: 3,
    fontSize: "12px",
    lineHeight: "22px",
    color: "#505050",

    "& b": {
      fontWeight: "500 !important",
    },
  },
  deliveryAddressText: {
    fontSize: "10px",
    lineHeight: "18px",
    color: "#505050",
  },
  uploadsBox: {
    marginTop: "1rem",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
    boxShadow:
      "-1px -1px 2px rgba(42, 157, 143, 0.2), 1px 1px 2px rgba(42, 157, 143, 0.2)",
    borderRadius: "5px",

    "& .ant-card-body": {
      minHeight: 120,
      "& > .ant-row": {
        minHeight: 120,
      },
    },
  },
  uploadsBoxTitle: {
    marginLeft: 7,
    paddingBottom: 30,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "12px",
    color: "#505050",
  },
  uploadsBoxEmptyText: {
    fontSize: "12px",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    color: "#505050",
    opacity: 0.5,
    fontStyle: "italic",
  },
  previewBox: {
    width: "8rem",
    height: "8rem",
    position: "relative",
  },
  previewBoxCutIcon: {
    position: "absolute",
    width: "15px",
    height: "15px",
    borderRadius: 5,
    top: 0,
    right: 0,
    backgroundColor: "#D62D20",
    color: "white",
    transform: "translate(50%, -50%)",
    "& span": {
      top: 0,
      right: 0,
      position: "absolute",
      fontSize: 14,
      transform: "translate(-50%,-22%)",
    },
  },
  deletePrescriptionModal: {
    "& .ant-modal-content": {
      borderRadius: 5,
    },

    "& .ant-modal-body": {
      padding: "20px !important",
    },

    "& .ant-modal-confirm-title": { fontSize: 11, textAlign: "center" },

    "& .ant-modal-confirm-btns": {
      width: "100%",
      marginTop: 15,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },

    "& .ant-btn": {
      width: 70,
      height: 20,
      fontSize: 10,
    },
  },
  cartItemsWrapper: {
    height: "calc(100vh - 60px)",
    overflowY: "auto",
    paddingBottom: 15,

    "& > div": {
      marginBottom: 10,
    },
  },
  cartItem: {
    width: "100%",
    height: 65,
    padding: [7, 23],
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  cartItemLeft: {},
  cartItemRight: {},
  cartItemLeftBottom: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemName: {
    paddingBottom: 12,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  cartItemTotal: {
    marginLeft: 18,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  removeItemButton: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },

  applyCouponWrapper: {
    width: "100%",
    margin: [10, 0, 15],
    padding: [10, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    "& input": {
      lineHeight: "26px",
      fontSize: 12,
    },
    "& button": {
      fontSize: 12,
      background: "#505050",
    },
  },
  applyCouponTitle: {
    marginBottom: 18,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  redeemLoyaltyPointsWrapper: {
    width: "100%",
    margin: [10, 0, 15],
    padding: [10, 25],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  redeemLoyaltyPointsCheckbox: {
    width: 40,

    "& .ant-checkbox-wrapper": {
      transform: "scale(0.9)",
    },
  },
  redeemLoyaltyPointsContent: {
    flex: 1,
  },
  redeemLoyaltyPointsText: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "14px",
    color: "#505050",
  },
  redeemLoyaltyPointsValue: {
    paddingTop: 6,
    fontWeight: "bold",
    fontSize: 12,
    color: "#2A9D8F",
  },
  redeemLoyaltyPointsIcon: {
    fontSize: 20,
    width: 40,
    textAlign: "center",
  },
  priceDetailsWrapper: {
    width: "100%",
    marginBottom: 18,
    padding: [13, 23],
    background: "#fff",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  priceDetailsTitle: {
    marginBottom: 18,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#505050",
  },
  priceDetailsItem: {
    width: "100%",
    marginBottom: 10,
    fontSize: "14px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalAmount: {
    width: "100%",
    marginTop: 20,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  loyaltyPointsSummaryWrapper: {
    minHeight: 40,
    marginBottom: 18,
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .anticon": {
      fontSize: 22,
    },
  },
  loyaltyPointsSummary: {
    marginLeft: 15,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",

    "& span": {
      color: "#9c9c9c",
    },
  },

  checkoutButton: {
    marginBottom: 15,
    fontWeight: "500",
    fontSize: "10px",
  },
  "@media (max-width: 768px)": {
    cartPage: {
      height: "auto",
    },
    pageLeftHeader: {
      height: 70,
      fontSize: 16,
      padding: 15,
    },
    pageRightHeader: {
      height: 60,
      fontSize: 16,
      padding: 15,
    },
    applyCouponTitle: {
      fontSize: 12,
    },
    priceDetailsTitle: {
      fontSize: 12,
    },
    priceDetailsItem: {
      fontSize: 12,
    },
    totalAmount: {
      fontSize: 12,
    },
    cartItemsWrapper: {
      height: "auto",
    },
    addPresWrapper: {
      fontSize: 14,
    },
    addPresWrapperHeader: {
      display: "block",
      "& input": {
        fontSize: 12,
        padding: "7px 0",
      },
    },
  },

  selectPaymentMethodWrapper: {
    margin: [20, 0, 25],
    "& .ant-space, & .ant-radio-group": {
      width: "100%",
    },
    "& .ant-card-body": {
      padding: [14, 25],
    },
  },
  selectPaymentMethodText: {
    paddingLeft: 25,
    paddingBottom: 8,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "22px",
    color: "#505050",
    display: "block",
  },
}));

export default CartPage;
