import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getAllRetailStores } from "../../api/invoiceApis";
import StoresSelector from "../../components/field/StoresSelector";
import ProductsListCard from "../../components/ProductsListCard";

export default function ManageProducts() {
  const classes = useStyle();
  const [retailStores, setRetailStores] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(0);

  const fetchRetailStores = async () => {
    let result = await getAllRetailStores(500, 0, "RETAILER");
    setRetailStores(result);
  };

  useEffect(() => {
    fetchRetailStores();
  }, []);

  return (
    <Row className={classes.manageProductsPage} gutter={20}>
      <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
        <ProductsListCard
          extraRight={
            <StoresSelector
              retailStores={retailStores}
              selectedStoreId={selectedStoreId}
              setSelectedStoreId={setSelectedStoreId}
            />
          }
          storeId={selectedStoreId}
        />
      </Col>
    </Row>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  manageProductsPage: {
    minHeight: "100vh",
    padding: 15,
    marginRight: "0 !important",
  },
  pageLeft: {
    display: "flex",
    flexDirection: "column",
  },
}));
