import {
  EditOutlined,
  EyeOutlined,
  FilterOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Input, Radio, Space, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getSession, Session } from "../../client/reactives/session";
import { getInvoicesByType, OrderTypes } from "../../utils/getInvoices";

const sortDirections: SortOrder[] = ["descend"];

interface ReviewInvoiceTableProps {
  onInvoicesChange: (invoices: Array<any>) => void;
  storeId: any;
  type: OrderTypes;
}

export type TableSearchFilters = Array<{
  column: string;
  column_value: string;
}>;

export type TableSortBy = {
  column?: string;
  column_value?: string;
};

export type TableFilters = {
  orders_search: TableSearchFilters;
  orders_order_by?: { column?: string; column_value?: string };
  incoming_orders_search?: any;
  incoming_orders_order_by?: { column?: string; column_value?: string };
};

export default function ReviewInvoiceTable({
  onInvoicesChange,
  storeId,
  type,
}: ReviewInvoiceTableProps) {
  const { userType } = getSession() as Session;
  const searchInput = useRef<Input>(null);
  const pageSize = 20;

  const [ordersSearch, setOrdersSearch] = useState<TableSearchFilters>([]);
  const [ordersSortBy, setOrdersSortBy] = useState<TableSortBy>({});

  const [invoices, setInvoices] = useState<any>([]);
  const [endOfList, setEndOfList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    //check if reached bottom
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPage((pageNumber: any) => pageNumber + 1);
      setLoading(true);
    }
  };

  const fetchInitialData = async () => {
    let filters: TableFilters = {
      orders_search: ordersSearch,
    };

    if (!!ordersSortBy?.column) {
      filters.orders_order_by = ordersSortBy;
    }

    let res = await getInvoicesByType(
      type,
      storeId,
      pageSize,
      pageSize * (page - 1),
      filters
    );
    if (!!res) {
      setInvoices((curr: any) => (page > 1 ? [...curr, ...res] : res));
      if (res.length < pageSize) setEndOfList(true);
    }
    setLoading(false);
  };

  const updateOrdersSearch = (value: string, dataIndex: string) => {
    // check if this is duplicate of the any exisiting data (both column & column_value)
    let isDuplicate =
      ordersSearch.filter(
        (item) =>
          item.column === dataIndex && item.column_value === "'" + value + "'"
      ).length > 0;
    // return and end function here if its duplicate
    if (isDuplicate) return;

    // check if any data with the column already exist and needs to be updated
    let isExist =
      ordersSearch.filter((item) => item.column === dataIndex).length > 0;

    // if data exist but value isn't same then find and update data
    if (isExist) {
      setOrdersSearch((curr) =>
        curr.map((item) =>
          item.column === dataIndex
            ? { ...item, column_value: "'" + value + "'" }
            : item
        )
      );
    }
    // else add new one
    else {
      setOrdersSearch((curr) => [
        ...curr,
        { column: dataIndex, column_value: "'" + value + "'" },
      ]);
    }

    // reset pagination and start loading to trigget ferch intial data
    setPage(1);
    setLoading(true);
  };

  useEffect(() => {
    let className = ".review-invoice-table-" + type + " .ant-table-body";
    document.querySelector(className)?.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    onInvoicesChange(invoices);
  }, [invoices]);

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, [loading]);

  useEffect(() => {
    setPage(1);
    setLoading(true);
  }, [storeId]);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    updateOrdersSearch(selectedKeys[0], dataIndex);
  };

  const handleFilter = (value: string, dataIndex: string, confirm: any) => {
    confirm();
    updateOrdersSearch(value, dataIndex);
  };

  const handleSort = (dataIndex: any, sort: any, key: any) => {
    // run this function only one time if the key is 1
    if (key !== 1) return;

    let sortDirection;
    if (sort === "ascend") sortDirection = "ASC";
    else sortDirection = "DESC";

    // If data already exists then return the function
    if (
      ordersSortBy.column === dataIndex &&
      ordersSortBy.column_value === sortDirection
    ) {
      return;
    }

    // update states
    setOrdersSortBy({
      column: dataIndex,
      column_value: sortDirection,
    });
    setPage(1);
    setLoading(true);
  };

  const handleReset = (clearFilters: any, dataIndex: any) => {
    clearFilters();
    setOrdersSearch((curr) => curr.filter((item) => item.column !== dataIndex));
    setPage(1);
    setLoading(true);
  };

  const getColumnFilterProps = (dataIndex: string, render?: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      filters,
    }: any) => (
      <div style={{ padding: 15 }}>
        <Radio.Group
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        >
          <Space direction="vertical">
            {filters.map((item: any) => (
              <Radio value={item.value}>{item.text}</Radio>
            ))}
          </Space>
        </Radio.Group>
        <br />
        <br />
        <Space>
          <Button
            type="primary"
            onClick={() => handleFilter(selectedKeys[0], dataIndex, confirm)}
            icon={<FilterOutlined />}
            size="small"
            style={{ width: 40 }}
          />
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 40 }}
            icon={<SyncOutlined />}
          />
        </Space>
      </div>
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
  });

  const getColumnSearchProps = (dataIndex: string, render?: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 40 }}
          />
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 40 }}
            icon={<SyncOutlined />}
          />
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
  });

  const columns: any = [
    {
      title: "Record No",
      dataIndex: "key",
      key: "key",
      // sorter: (a: any, b: any, sort: string) => handleSort("key", sort, b?.key),
      // sortDirections,
    },
    {
      title: "Retailer",
      dataIndex: "retail_store_name",
      key: "retail_store_name",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("retail_store_name", sort, b?.key),
      ...getColumnSearchProps("retail_store_name"),
    },
    {
      title: "Invoice ID",
      dataIndex: "order_id",
      key: "order_id",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("order_id", sort, b?.key),
      ...getColumnSearchProps("order_id", (text: any, record: any) =>
        record.order_id !== null ? record.order_id : record.incoming_order_id
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("invoice_date", sort, b?.key),
      render: (date: any) =>
        date !== null ? moment(date).format("DD MMMM, hh:mm A") : " - ",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("created_at", sort, b?.key),
      render: (date: any) => moment(date).format("DD MMMM, hh:mm A"),
    },
    {
      title: "Last Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("updated_at", sort, b?.key),
      render: (date: any) => moment(date).format("DD MMMM, hh:mm A"),
    },
    {
      title: "Last Action By",
      dataIndex: "last_action_by",
      key: "last_action_by",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("last_action_by", sort, b?.key),
      ...getColumnSearchProps("last_action_by", (last_action_by: any) =>
        last_action_by !== "" ? last_action_by : " - "
      ),
    },
    {
      title: "Published By",
      dataIndex: "published_by",
      key: "published_by",
      sorter: (a: any, b: any, sort: string) =>
        handleSort("published_by", sort, b?.key),
      ...getColumnSearchProps("published_by", (published_by: any) =>
        published_by !== "" ? published_by : " - "
      ),
    },
    {
      title: "Type",
      dataIndex: "incoming_order_type",
      key: "incoming_order_type",
      render: (type: any) => (type !== null ? type : " - "),
      filters: [
        {
          text: "Auto",
          value: "Auto",
        },
        {
          text: "Manual",
          value: "Manual",
        },
      ],
      ...getColumnFilterProps("incoming_order_type"),
    },
    {
      title: "Incoming Order Status",
      dataIndex: "incoming_order_status",
      key: "incoming_order_status",
      render: (incoming_order_status: any) =>
        incoming_order_status !== null ? incoming_order_status : " - ",
      filters: [
        {
          text: "New",
          value: "New",
        },
        {
          text: "Processed",
          value: "Processed",
        },
        {
          text: "Fetch",
          value: "Fetch",
        },
        {
          text: "Fetch Failed",
          value: "Fetch Failed",
        },
        {
          text: "Incomplete",
          value: "Incomplete",
        },
        {
          text: "Processing",
          value: "Processing",
        },
        {
          text: "Verified",
          value: "Verified",
        },
        {
          text: "Discount Failed",
          value: "Discount Failed",
        },
        {
          text: "Line Item Processing",
          value: "Line Item Processing",
        },
      ],
      ...getColumnFilterProps("incoming_order_status"),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "order_status",
      render: (status: any) =>
        status === 1 ? "Published" : status === 0 ? "Draft" : " - ",
      filters: [
        {
          text: "Draft",
          value: 0,
        },
        {
          text: "Published",
          value: 1,
        },
      ],
      ...getColumnFilterProps("order_status"),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => {
        let to =
          record.order_id !== null
            ? `review-invoices/${record.order_id}`
            : `add-invoice/${record.incoming_order_id}`;
        return (
          <Link to={to}>
            {record.order_status === 1 && userType !== "super_admin" ? (
              <EyeOutlined style={{ fontSize: "1.3rem" }} />
            ) : (
              <EditOutlined style={{ fontSize: "1.3rem" }} />
            )}
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        columns={columns}
        dataSource={invoices}
        className={"review-invoice-table-" + type}
        scroll={{ y: 700 }}
        pagination={false}
      />
    </>
  );
}
