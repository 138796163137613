import { useForm as useHookForm } from "react-hook-form";
import type {
  ErrorOption,
  FieldName,
  SubmitHandler,
  UseFormOptions,
} from "react-hook-form";

type FormProps<TInput> = UseFormOptions<TInput> & {
  onSubmit: (
    values: TInput,
    setError?: (name: FieldName<TInput>, error: ErrorOption) => void
  ) => void;
};

function useForm<TInput>({ onSubmit, ...rest }: FormProps<TInput>) {
  const methods = useHookForm<TInput>(rest);
  const { handleSubmit, setError, formState } = methods;
  const { isSubmitting } = formState;

  const proxySubmitHandler = ((values: TInput) =>
    onSubmit(values, setError)) as SubmitHandler<TInput>;
  const submitHandler = handleSubmit(proxySubmitHandler);

  return {
    isSubmitting,
    methods,
    submitHandler,
  };
}

export type { FormProps };

export default useForm;
