import Modal from "antd/lib/modal/Modal";
import Table from "antd/lib/table";
import React, { useEffect, useState } from "react";
import Text from "antd/lib/typography/Text";
import { createUseStyles } from "react-jss";
import { getOrderItems } from "../../api/invoiceApis";
import Loader from "../Loader";
import OrderSummaryTableCard from "../tables/OrderSummaryTableCard";

const columns: any = [
  {
    title: "#",
    dataIndex: "key",
    align: "center",
    width: "45px",
    render: (key: number) => `${key}`,
  },
  {
    title: "Item",
    dataIndex: "name",
    width: "160px",
    render: (name: string) => `${name}`,
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    width: "50px",
    align: "center",
    render: (quantity: string) => `${+quantity}`,
  },
  {
    title: "Total",
    dataIndex: "total",
    width: "60px",
    render: (total: string) => `₹ ${(+total).toFixed(0)}/-`,
  },
];

function OrderItemsModal({
  small = false,
  orderId,
  order,
  invoiceNumber,
  visible,
  onCancel,
}: any) {
  const classes = useStyles({ small });
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (orderId !== "" && orderId !== undefined) {
        const result = await getOrderItems(orderId);
        const newData = [];
        if (result?.length) {
          for (let i = 0; i < result.length; i++) {
            const item = result[i];
            newData.push({
              key: i + 1,
              name: item.name,
              quantity: item.quantity,
              total: item.total,
            });
          }
        }
        setData(newData);
        setLoading(false);
      }
    })();
  }, [orderId]);

  if (!orderId) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={false}
      title={`Invoice Number : ${invoiceNumber}`}
      centered
      onCancel={() => {
        setData([]);
        setLoading(true);
        onCancel();
      }}
    >
      {!loading ? (
        <OrderSummaryTableCard
          size="small"
          items={data}
          summary={() => (
            <>
              <Table.Summary.Row className={classes.tabelSummary}>
                <Table.Summary.Cell index={1} colSpan={2}>
                  Discount
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <Text>- ₹ {order?.discount}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row className={classes.tabelSummary}>
                <Table.Summary.Cell index={1} colSpan={2}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <Text strong>₹ {(+order?.value).toFixed(2)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
          customColumns={columns}
        />
      ) : (
        <Loader height="250px" />
      )}
    </Modal>
  );
}

export default OrderItemsModal;

const useStyles = createUseStyles({
  tabelSummary: {
    "& .ant-table-cell:nth-child(2)": {
      textAlign: "right",
    },
    "& td": {
      padding: ({ small }) =>
        small ? "8px 15px 6px !important" : "8px 30px 6px !important",
    },
  },
});
