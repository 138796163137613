import {
  CheckCircleTwoTone,
  CloseOutlined,
  EditOutlined,
  EyeTwoTone,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { getMarketingCampaigns } from "../api/invoiceApis";
import moment from "moment";
import CampaignMarkAsCompletedModal from "./modal/CampaignMarkAsCompletedModal";
import Flex from "./layouts/Flex";

interface MarketingCampaignsListCardProps {
  storeId: number;
  extraRight: React.ReactNode;
}

export default function MarketingCampaignsListCard({
  storeId,
  extraRight,
}: MarketingCampaignsListCardProps) {
  const classes = useStyle();
  const isLG = useBreakpoint().lg;

  const [marketingCampaigns, setMarketingCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endOfList, setEndOfList] = useState(false);
  const [markAsCompletedModalProps, setMarkAsCompletedModalProps] = useState({
    visible: false,
    data: null,
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 20,
    totalPages: 0,
  });
  const [viewCampaign, setViewCampaign] = useState<{
    visible: boolean;
    data: any;
  }>({
    visible: false,
    data: null,
  });

  const handleViewDetailsClick = (data: any) => () => {
    setViewCampaign({
      data,
      visible: true,
    });
  };

  const handleCloseViewDetailsClick = () => {
    setViewCampaign({
      data: null,
      visible: false,
    });
  };

  const handleMarkAsCompletedClick = (row: any) => () => {
    setMarkAsCompletedModalProps({
      visible: true,
      data: row,
    });
  };

  const handleMarkAsCompletedClose = () => {
    setMarkAsCompletedModalProps({
      visible: false,
      data: null,
    });
  };

  const handleScroll = async (e: any) => {
    if (endOfList) {
      return;
    }
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPagination((curr) => ({
        ...curr,
        currentPage: curr.currentPage + 1,
      }));
    }
  };

  const fetchInitialData = async () => {
    let marketingCampaignsRes = await getMarketingCampaigns(
      +storeId > 0 ? storeId : ""
    );

    if (marketingCampaignsRes?.data?.length) {
      setMarketingCampaigns(marketingCampaignsRes.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [storeId, pagination.pageSize]);

  useEffect(() => {
    fetchInitialData();
  }, [loading, pagination.currentPage]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        document
          .querySelector(".ant-table-body")
          ?.addEventListener("scroll", handleScroll);
      }, 2000);
    }

    return () => {
      document
        .querySelector(".ant-table-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const TableColumns: any[] = [
    {
      title: "#",
      render: (a: any, b: any, index: number) => index + 1,
      width: "50px",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: "160px",
    },
    {
      title: "Campaign Type",
      dataIndex: "campaign_type",
      key: "campaign_type",
      width: "150px",
    },
    {
      title: "Campaign Medium",
      dataIndex: "campaign_medium",
      key: "campaign_medium",
      width: "170px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
    },
    {
      title: "Campaign Cost",
      dataIndex: "campaign_cost",
      key: "campaign_cost",
      width: "170px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Campaign Date",
      dataIndex: "campaign_time",
      key: "campaign_time",
      width: "140px",
      render: (val: any) => moment(val).format("Do MMM, YYYY"),
    },
    {
      title: "Planned Messages",
      dataIndex: "planned_messages",
      key: "planned_messages",
      width: "180px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Total Messages Sent",
      dataIndex: "total_messages_sent",
      key: "total_messages_sent",
      width: "180px",
      render: (val: any) => val ?? " - ",
    },
    {
      title: "Actions",
      key: "actions",
      width: "120px",
      render: (val: any, row: any) => (
        <Space>
          <Button
            type="text"
            icon={<EyeTwoTone />}
            onClick={handleViewDetailsClick(row)}
          />
          <Button
            type="text"
            icon={<CheckCircleTwoTone />}
            onClick={handleMarkAsCompletedClick(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Layout className={classes.marketingCampaignsPage}>
      <Row gutter={[20, 20]}>
        <Col span={viewCampaign.visible ? 16 : 24}>
          <Card className={classes.MarketingCampaignsListCard}>
            <div className={classes.cardHeader}>
              <div>
                <Typography.Title level={3} className={classes.cardTitle}>
                  Manage Campaigns
                </Typography.Title>
                <Link to={`/admin/dashboard/marketing-campaigns/new`}>
                  <Button size="small" type="primary" icon={<PlusOutlined />}>
                    New Campaign
                  </Button>
                </Link>
              </div>
              <div>{extraRight}</div>
            </div>

            <CampaignMarkAsCompletedModal
              {...markAsCompletedModalProps}
              onCancel={handleMarkAsCompletedClose}
              onUpdateDone={fetchInitialData}
            />

            {!loading ? (
              <Table
                dataSource={marketingCampaigns}
                columns={TableColumns}
                size={isLG ? "middle" : "small"}
                scroll={{ y: "calc(100vh - 180px)" }}
                pagination={false}
              />
            ) : (
              <Loader height="70vh" />
            )}
          </Card>
        </Col>

        <Col span={viewCampaign.visible ? 8 : 0}>
          <Card style={{ height: "100%" }}>
            <div className={classes.tableCardHeader}>
              <Typography.Title level={4}>Campaign Details</Typography.Title>

              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCloseViewDetailsClick}
              />
            </div>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Flex justify="space-between">
                <Typography.Text>Campaign Name</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_name}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Type</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_type}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Medium</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_medium}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Status</Typography.Text>
                <Typography.Text>{viewCampaign?.data?.status}</Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Cost</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.campaign_cost ?? " - "}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Campaign Date</Typography.Text>
                <Typography.Text>
                  {moment(viewCampaign?.data?.campaign_time).format(
                    "Do MMM, YYYY"
                  )}
                </Typography.Text>
              </Flex>

              <Divider />

              <Flex justify="space-between">
                <Typography.Text>Planned Messages</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.planned_messages ?? " - "}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Total Messages Sent</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.total_messages_sent ?? " - "}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Total Messages Delivered</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.total_messages_delivered ?? " - "}
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Open Rate</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.open_rate ?? " - "}%
                </Typography.Text>
              </Flex>
              <Flex justify="space-between">
                <Typography.Text>Response Rate</Typography.Text>
                <Typography.Text>
                  {viewCampaign?.data?.response_rate ?? " - "}%
                </Typography.Text>
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  marketingCampaignsPage: {
    height: "100%",
  },
  MarketingCampaignsListCard: {
    flex: 1,
    width: "100%",
    borderRadius: 5,
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
  },
  paginationWrapper: {
    padding: [20, 25, 10],
  },
  cardHeader: {
    paddingBottom: 10,
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  cardTitle: {
    margin: "0 !important",
    paddingRight: 20,
  },

  tableCardHeader: {
    marginBottom: 20,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    "& .ant-typography": {
      marginBottom: "0 !important",
    },
  },
}));
