import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import PhoneOutlined from "@ant-design/icons/PhoneOutlined";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  getCustomer,
  getReorderById,
  getReorderItems,
} from "../../api/invoiceApis";
import Loader from "../../components/Loader";

const getAddressString = (customer: any) =>
  (customer.line1 ? customer.line1 + ", " : "") +
  (customer.line2 ? customer.line2 + ", " : "") +
  (customer.landmark ? customer.landmark + ", " : "") +
  (customer.city ? customer.city + ", " : "") +
  (customer.state ? customer.state + ", " : customer.state) +
  (customer.pincode ? customer.pincode + ", " : customer.pincode);

function ReorderSummary() {
  // ------ other hooks
  const classes = useStyles();
  const history = useHistory();
  const { reorderId } = useParams<any>();
  const screen = useBreakpoint();
  const isLG = screen.lg;

  // ------ states
  const [pageData, setPageData] = useState<any>({
    reorder: {},
    addressDetails: {},
    items: [],
    loading: true,
  });

  // fetch initial reorder data
  useEffect(() => {
    fetchInitialData();
  }, [reorderId]);

  // --------
  // --------
  // --------
  // --------

  const fetchInitialData = async () => {
    const reorder = await getReorderById(reorderId); // reorder details
    const items = await getReorderItems(+reorderId); // reorder items
    const customer = await getCustomer(reorder[0]?.user_id); // customer details

    const addressData = {
      address: getAddressString(customer[0] || {}),
      phone_no: customer[0]?.phone_no || "",
    };

    setPageData({
      ...pageData,
      items: items,
      loading: false,
      addressDetails: addressData,
      reorder: reorder.length > 0 ? reorder[0] : [],
    });
  };

  if (pageData?.loading) {
    return <Loader />;
  }

  const CartSection = () => (
    <>
      <div className={classes.pageRightHeader}>
        <span>{pageData.items.length} Items</span>
      </div>
      <div className={classes.cartItemsWrapper}>
        {pageData.items.map((item: any) => (
          <div className={classes.itemWrapper}>
            <div>
              <div className={classes.itemName}>{item?.name}</div>
              <div className={classes.itemQuantity}>Qty : {item?.quantity}</div>
            </div>
            <div className={classes.itemTotal}>
              ₹ {parseFloat(item?.total).toFixed(2)}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <Row className={classes.reorderSummary}>
      <Col span={isLG ? 12 : 24} className={classes.pageLeft}>
        <div className={classes.pageLeftHeader}>Reorder Summary</div>

        <div className={classes.addressWrapper}>
          <div className={classes.addressWrapperTitle}>Address</div>

          <div className={classes.address}>
            <div className={classes.addressItem}>
              <HomeOutlined />
              <span>{pageData?.addressDetails?.address}</span>
            </div>
            <div className={classes.addressItem}>
              <PhoneOutlined rotate={90} />
              <span>{pageData?.addressDetails.phone_no}</span>
            </div>
          </div>
        </div>

        {/* {!isLG && <CartSection />} */}

        <div className={classes.paymentDetailsWrapper}>
          <div className={classes.paymentDetailsWrapperTitle}>
            Payment Details
          </div>
          <div className={classes.paymentDetails}>
            <div className={classes.paymentDetailsItem}>
              <span>Order No:</span>
              <span>{pageData?.reorder?.id}</span>
            </div>
            {/* <div className={classes.paymentDetailsItem}>
                  <span>Invoice No:</span>
                  <span>APO1506001</span>
                </div>
                <div className={classes.paymentDetailsItem}>
                  <span>Payment Option</span>
                  <span>UPI</span>
                </div> */}
            <div className={classes.paymentDetailsItem}>
              <span>Order Amount</span>
              <span>
                ₹{" "}
                {(
                  +pageData?.reorder?.grandtotal +
                  +pageData?.reorder?.discount +
                  +pageData?.reorder?.coupon_discount +
                  +pageData?.reorder?.redeemed_loyalty_points -
                  +pageData?.reorder?.shipping_charge
                ).toFixed(2)}
              </span>
            </div>
            <div className={classes.paymentDetailsItem}>
              <span>Discount Availed</span>
              <span>
                ₹{" "}
                {(
                  +pageData?.reorder?.discount +
                  +pageData?.reorder?.coupon_discount
                ).toFixed(2)}
              </span>
            </div>
            <div className={classes.paymentDetailsItem}>
              <span>Redeemed Lotalty Points</span>
              <span>
                ₹ {(+pageData?.reorder?.redeemed_loyalty_points).toFixed(2)}
              </span>
            </div>
            <div className={classes.paymentDetailsItem}>
              <span>Shipping Charge</span>
              <span>₹ {(+pageData?.reorder?.shipping_charge).toFixed(2)}</span>
            </div>
            <div className={classes.paymentDetailsItem}>
              <span>Total</span>
              <span>₹ {(+pageData?.reorder?.grandtotal).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </Col>
      {/* {isLG && ( */}
      <Col span={isLG ? 12 : 24} className={classes.pageRight}>
        <CartSection />
      </Col>
      {/* )} */}
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  reorderSummary: {
    height: "100vh",
    overflow: "hidden",
  },
  pageLeft: {
    height: "100vh",
    overflow: "auto",
    padding: [0, 20],
  },
  pageRight: {
    padding: [0, 15],
  },
  pageLeftHeader: {
    height: 80,
    padding: 20,
    lineHeight: "40px",
    fontSize: 20,
    fontWeight: 600,
    color: "#505050",
  },
  pageRightHeader: {
    height: 80,
    padding: 20,
    lineHeight: "40px",
    fontSize: 20,
    fontWeight: 600,
    color: "#505050",
    display: "flex",
    alignItems: "center",
  },

  addressWrapper: {},
  addressWrapperTitle: {
    height: 35,
    paddingLeft: 22,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "35px",
    color: "#505050",
  },
  address: {
    padding: [10, 20],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  addressItem: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#505050",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "&:first-child": {
      marginBottom: 5,
    },

    "& .anticon": {
      marginRight: 10,
      color: "#2A9D8F",
    },
  },

  paymentDetailsWrapper: {},
  paymentDetailsWrapperTitle: {
    height: 35,
    paddingLeft: 22,
    marginTop: 15,
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "35px",
    color: "#505050",
  },
  paymentDetails: {
    padding: [10, 22],
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  paymentDetailsItem: {
    width: "100%",
    marginBottom: 7,
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&:last-child": {
      fontWeight: 700,
    },
  },

  cartItemsCount: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "22px",
    fontVariant: "small-caps",
    color: "#505050",
  },
  cartItemsWrapper: {
    height: "calc(100vh - 60px)",
    overflowY: "auto",
    paddingBottom: 15,

    "& > div": {
      marginBottom: 10,
    },
  },
  itemWrapper: {
    padding: [10, 20],
    marginBottom: 12,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#FFFFFF",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  itemName: {
    marginBottom: 10,
  },
  itemQuantity: {},
  itemTotal: {
    fontWeight: 700,
  },

  "@media (max-width: 768px)": {
    reorderSummary: {
      height: "auto",
    },
    pageLeft: {
      height: "auto",
    },
    pageLeftHeader: {
      height: 50,
      padding: "10px 12px",
      fontSize: 16,
    },
    pageRightHeader: {
      height: 50,
      fontSize: 14,
      padding: 15,
    },
    cartItemsWrapper: {
      height: "auto",
    },
    itemWrapper: {
      fontSize: 10,
    },
    paymentDetailsItem: {
      fontSize: 10,
      lineHeight: "12px",
    },
    paymentDetailsWrapperTitle: {
      height: 35,
      paddingLeft: 15,
      marginTop: 10,
      fontSize: 12,
      lineHeight: "35px",
    },
    addressItem: {
      fontSize: 10,
    },
    addressWrapperTitle: {
      height: 35,
      paddingLeft: 15,
      fontSize: 12,
      lineHeight: "35px",
    },
  },
}));

export default ReorderSummary;
