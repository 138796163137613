import { DeleteOutlined } from "@ant-design/icons";
import { Button, Card, Col, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  deleteAllCartItems,
  deleteCart,
  deleteCartItems,
  getCart,
  getCartItems,
  getProducts,
  updateCartItemsByProductId,
} from "../api/invoiceApis";
import NumberSelect from "./NumberSelect";

interface CartOverviewCardProps {
  cartId: number;
  onChange: () => void;
  loading: boolean;
  onLoaded: () => void;
}

const getPriceWithoutGst = (gstPercentage: number, priceWithGst: number) => {
  let gstAmount = priceWithGst - priceWithGst * (100 / (100 + gstPercentage));
  return priceWithGst - gstAmount;
};

export default function CartOverviewCard({
  cartId,
  loading,
  onChange,
  onLoaded,
}: CartOverviewCardProps) {
  const classes = useStyle();
  const [cart, setCart] = useState<any>({});
  const [cartItems, setCartItems] = useState<any[]>([]);

  const removeCartItem = async (itemId: number) => {
    await deleteCartItems(itemId).then((result) => {
      let newItemsArray = cartItems.filter(
        (cartItem: any) => cartItem.id !== result[0].id
      );
      setCartItems(newItemsArray);
      onChange();
    });
  };

  const clearCart = async () => {
    await deleteAllCartItems(+cart?.id);
    await deleteCart(+cart?.id);
    setCartItems([]);
    setCart({});
    onChange();
  };

  const handleQuantityChange = async (newQty: number, item: any) => {
    let productPrice = item?.strike_price ?? item.pack_price;
    let sub_total = getPriceWithoutGst(
      +item.sgst + +item.cgst,
      +productPrice * +newQty
    );

    let newData = {
      product_section: "cart",
      product_sku: item?.product_sku,
      sub_total: sub_total.toFixed(2),
      quantity: +newQty,
      total: (+productPrice * +newQty).toFixed(2),
    };

    await updateCartItemsByProductId(+cartId, +item.product_id, newData).then(
      (result) => {
        if (result[0]?.product_id) {
          fetchCartItems(cart);
          onChange();
        }
      }
    );
  };

  const fetchCartItems = async (cart: any) => {
    let cartItems = await getCartItems(cartId);
    let items = [];

    for (let i = 0; i < cartItems.length; i++) {
      const productDetails = await getProducts(
        cartItems[i]?.product_id,
        +cart?.retail_store_id,
        +cart?.user_id
      );
      items.push({
        ...productDetails[0],
        ...cartItems[i],
      });
    }

    setCartItems(items);
  };

  const fetchInitialData = async () => {
    let cart = await getCart(cartId);
    if (cart?.length) {
      await fetchCartItems(cart[0]);
      setCart(cart[0]);
      onLoaded();
    }
  };

  useEffect(() => {
    if (loading && !!cartId) {
      fetchInitialData();
    }
  }, [loading]);

  return (
    <Col span={5}>
      <div className={classes.cardOverviewCard}>
        <div className={classes.cardHeader}>
          <Typography.Text strong className={classes.cardTitle}>
            Cart Overview
          </Typography.Text>
          <Button
            danger
            type="text"
            icon={<DeleteOutlined />}
            className={classes.clearCartBtn}
            onClick={clearCart}
          />
        </div>

        {/* <div className={classes.cartSummary}>
          <div className={classes.cartSummaryItem}>
            <Typography.Text strong>Total Amount</Typography.Text>
            <Typography.Text strong>₹ {getTotalAmount()}</Typography.Text>
          </div>
        </div> */}

        <div className={classes.cartItemsWrapper}>
          {cartItems.map((cartItem) => (
            <div className={classes.cartItem}>
              <div className={classes.cartItemLeft}>
                <div className={classes.itemName}>{cartItem?.name}</div>

                <div className={classes.cartItemLeftBottom}>
                  <NumberSelect
                    till={50}
                    size="small"
                    value={cartItem?.quantity}
                    onChange={(value: any) =>
                      handleQuantityChange(value, cartItem)
                    }
                  />
                  <div className={classes.cartItemTotal}>
                    ₹ {parseFloat(cartItem?.total).toFixed(2)}
                  </div>
                </div>
              </div>
              <div className={classes.cartItemRight}>
                <Button
                  type="text"
                  size="small"
                  icon={<DeleteOutlined />}
                  className={classes.removeItemButton}
                  onClick={() => removeCartItem(parseInt(cartItem.id))}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Col>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  cardOverviewCard: {
    width: "100%",
    height: "100%",
    padding: "1rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  cardHeader: {
    marginBottom: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 20,
  },

  cartItemsWrapper: {
    height: "calc(100vh - 120px)",
    overflowY: "auto",
  },

  cartSummary: {
    marginTop: 0,
  },
  cartSummaryItem: {
    padding: [10, 0],
    display: "flex",
    justifyContent: "space-between",
  },

  cartItem: {
    width: "100%",
    height: 65,
    margin: [15, 0],
    padding: [10, 15],
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.3px solid #D9D9D9",
    boxSizing: "border-box",
  },
  cartItemLeft: {},
  cartItemRight: {},
  cartItemLeftBottom: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  itemName: {
    paddingBottom: 12,
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  removeItemButton: {
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  cartItemTotal: {
    marginLeft: 18,
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#505050",
  },
  clearCartBtn: {
    "& .anticon": {
      fontSize: 16,
    },
  },
}));
