import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Empty, Input, Select, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import { getRetailStoreCustomers } from "../api/invoiceApis";
import customeScrollbarStyle from "../styles/CustomScrollbar";
import Loader from "./Loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface CustomerSelectorCardProps {
  storeId: number;
  onCustomerChange: (val: number) => void;
}

export default function CustomerSelectorCard({
  storeId,
  onCustomerChange,
}: CustomerSelectorCardProps) {
  const classes = useStyle();
  const history = useHistory();
  const query = useQuery();
  const isLG = useBreakpoint().lg;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState(0);

  const handleCustomerSelect = (userId: number) => () => {
    setSelectedUserId(userId);
    onCustomerChange(userId);

    history.replace(
      `${window.location.pathname}?store=${storeId}&customer=${userId}`
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleSelectSearchChange = (val: string) => {
    setSearch(val);
    setPage(1);
  };

  const handleCustomerScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    if (customers.length % limit !== 0) {
      return;
    }

    //check if reached bottom
    let element = e.currentTarget;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      setPage(page + 1);
    }
  };

  const fetchCustomers = async () => {
    let result = await getRetailStoreCustomers(storeId, search, limit, page);
    let queryUserId = query?.get("customer");

    if (
      !!queryUserId &&
      +queryUserId > 0
      // @ts-ignore
      // result?.filter((i) => +i.user_id === +queryUserId)
    ) {
      setSelectedUserId(+queryUserId);
    }

    if (page > 1) {
      setCustomers((curr) => [...curr, ...result]);
      setLoading(false);
      return;
    }

    setCustomers(result);
    setLoading(false);
  };

  useEffect(() => {
    if (!!storeId) {
      setLoading(true);
    }
  }, [storeId, page, limit, search]);

  useEffect(() => {
    if (loading) {
      fetchCustomers();
    }
  }, [loading]);

  if (!isLG) {
    return (
      <div className={classes.customersSelectorCard}>
        <div className={classes.cardHeader}>
          <UserOutlined />
          <Typography.Text strong>Select Customer</Typography.Text>
        </div>
        <Select
          showSearch
          placeholder="Select Customer"
          optionFilterProp="children"
          value={!!selectedUserId ? selectedUserId : undefined}
          onSearch={handleSelectSearchChange}
          onChange={setSelectedUserId}
          className={classes.searchSelect}
        >
          {customers.map((customer: any) => (
            <Select.Option
              key={`#${customer.user_id} - ${customer.name}`}
              value={customer?.user_id}
            >
              #{customer.user_id} - {customer.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }

  return (
    <div className={classes.customersSelectorCard}>
      <div className={classes.cardHeader}>
        <UserOutlined />
        <Typography.Text strong>Select Customer</Typography.Text>
      </div>
      <div hidden={!isLG}>
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search customer"
          onChange={handleSearchChange}
          className={classes.searchField}
        />
        {!!storeId ? (
          loading && page === 1 ? (
            <Loader height="60vh" />
          ) : (
            <div
              className={classes.customerListWrapper}
              onScroll={handleCustomerScroll}
            >
              {customers.map((customer: any, index: number) => (
                <div
                  key={index}
                  className={classes.customerItem}
                  onClick={handleCustomerSelect(+customer?.user_id)}
                  style={
                    customer?.user_id === selectedUserId
                      ? { background: "rgba(0,0,0,0.1)" }
                      : {}
                  }
                >
                  <div className={classes.itemHeader}>
                    <b>#{customer?.user_id}</b>
                    <div>{customer?.name}</div>
                  </div>
                  <div className={classes.customerPhone}>
                    {customer?.phone_no ?? customer?.mobile_number}
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className={classes.emptyWrapper}>
            <Empty description="Please select a store" />
          </div>
        )}
      </div>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  customersSelectorCard: {
    flex: 1,
    width: "100%",
    marginBottom: 15,
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  cardHeader: {
    padding: [5, 15, 10],
    fontSize: 22,
    color: colors.textPrimary,

    "& .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },

  searchField: {
    width: "94%",
    margin: "5px 3% 10px",

    "& .anticon": {
      paddingRight: 5,
    },
  },
  customerListWrapper: {
    overflow: "auto",
    height: "calc(100vh - 270px)",
    ...customeScrollbarStyle,
  },
  customerItem: {
    padding: [5, 20],
    fontSize: 15,
    fontWeight: 600,
    color: "#505050",
    opacity: 0.85,
    cursor: "pointer",
    transition: "all 0.3s",

    "&:hover": {
      opacity: 1,
      backgroundColor: "rgba(0,0,0,0.035)",
    },

    "& b": {
      fontWeight: "700 !important",
      color: "#111",
      paddingRight: 10,
    },
  },
  itemHeader: {
    display: "flex",
  },
  customerPhone: {
    fontSize: 10,
    color: "#000",
    opacity: 0.9,
  },
  searchSelect: {
    width: "94%",
    margin: "5px 3% 10px",
    padding: 0,
    fontWeight: 600,
  },
  emptyWrapper: {
    height: "70vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
