import * as yup from "yup";

import BaseModel from "./BaseModel";

class Product extends BaseModel {
  static schema = yup.object().shape({
    // retail_store_id: yup.string(),
    product_name: yup.string().required(),
    brand_id: yup.string().required(),
    category_id: yup.string().required(),
    prescription: yup.string().required().nullable(),
    catalogue_type: yup.string(),
    pack: yup.string().required(),
    pack_size: yup.string().required(),
    vendor: yup.string().required(),
    vendor_code: yup.string().length(5, "Must be 5 chars long").required(),
    product_domain: yup.string().required(),
    dosage_form: yup.string().required(),
    pack_price: yup.string().required(),
    price: yup.string().required(),
    description: yup.string().required(),
    manufacturer: yup.string().required(),
    salt_composition: yup.string().required(),
    drug_info: yup.string().required(),
    when_to_use: yup.string().required(),
    how_to_use: yup.string().required(),
    benefits: yup.string().required(),
    side_effects: yup.string().required(),
  });

  static AddProducts<TInput>() {
    return Product.validationResolver<TInput>([
      // "retail_store_id",
      "product_name",
      "brand_id",
      "category_id",
      "prescription",
      "catalogue_type",
      "pack_size",
      "vendor",
      "vendor_code",
      "product_domain",
      "dosage_form",
      "pack_price",
      "description",
      "manufacturer",
      "salt_composition",
      "drug_info",
      "when_to_use",
      "how_to_use",
      "benefits",
      "side_effects",
    ]);
  }

  static AddProductsOC<TInput>() {
    return Product.validationResolver<TInput>([
      "pack",
      "product_name",
      "pack_size",
      "pack_price",
      "price",
    ]);
  }
}

export default Product;
