import { message, Modal } from "antd";
import { createUseStyles } from "react-jss";
import {
  insertAddresses,
  insertUser,
  insertCustomer,
  getUsers,
  insertRetailStoreUsersMapping,
} from "../api/invoiceApis";
import AddCustomerForm from "./forms/AddCustomerForm";

function AddCustomerModal({
  visible,
  retailStoreId,
  onSave,
  onClose,
  customerUserId,
}: any) {
  const classes = useStyles();

  const handleFormSubmit = async (values: any) => {
    let customer,
      user_type = "Customer";

    let userData = {
      name: `'${values.name}'`,
      mobile_number: `'${values.mobileNumber}'`,
      email: "''",
      password_digest: "''",
      user_type: `'${user_type}'`,
    };

    let address = {
      line1: `' '`,
      line2: `' '`,
      landmark: `' '`,
      pincode: `' '`,
      city: `' '`,
      state: `' '`,
      addressable_type: `'Customer'`,
    };

    await insertUser(userData)
      .then(async (result) => {
        //       if (result.length && result[0]?.id) {
        if (!!result?.user_id) {
          let user = result;
          let customerData = {
            user_id: user.user_id,
            name: `'${user.user_name}'`,
            email: "''",
            phone_no: `'${user.mobile_number}'`,
          };
          await insertCustomer(customerData).then(async (data) => {
            if (data.length) {
              customer = data[0];
              await insertAddresses({
                ...address,
                addressable_id: data[0]?.id,
              });
              await insertRetailStoreUsersMapping(
                retailStoreId,
                userData.mobile_number
              );
            }
          });
        } else {
          return message.error("Mobile number already exists.!");
        }
      })
      .catch(async (err) => {
        if (
          err.response.data?.error ===
          "User Already Registered By This Mobile Number "
        ) {
          await getUsers(userData.mobile_number).then((res) => {
            if (res?.length) {
              Modal.confirm({
                title: `User already registered with this Mobile Number as ${res[0].name}, Do you want to add it to this store?`,
                onOk: async () => {
                  await insertRetailStoreUsersMapping(
                    retailStoreId,
                    res[0].mobile_number
                  );
                  message.info("Successfully Mapped Customer");
                  onSave({ ...res[0], user_id: res[0].id });
                },
              });
            }
          });
        }
      });

    if (!!customer) {
      message.info("Successfully Added New Customer");
      onSave(customer);
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Add New Customer`}
      centered
      footer={false}
      onCancel={onClose}
    >
      <AddCustomerForm onSubmit={handleFormSubmit} />
    </Modal>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({}));

export default AddCustomerModal;
