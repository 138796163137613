import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Card from "antd/es/card";
import Title from "antd/es/typography/Title";
import message from "antd/es/message";
import {
  editProduct,
  getProductDetails,
  updateProduct,
} from "../../api/invoiceApis";
import RaseetProductForm from "../../components/forms/RaseetProductForm";
import { useRouteMatch } from "react-router-dom";
import Loader from "../../components/Loader";
import { EditOutlined } from "@ant-design/icons";
import RetailerProductForm from "../../components/forms/RetailerProductForm";

const defaultValues = {
  product_name: "",
  brand_id: undefined,
  category_id: undefined,
  prescription: undefined,
  catalogue_type: undefined,
  pack_size: "",
  vendor: "",
  vendor_code: "",
  product_domain: "",
  dosage_form: "",
  pack_price: "",
  description: "",
  manufacturer: "",
  salt_composition: "",
  drug_info: "",
  when_to_use: "",
  how_to_use: "",
  benefits: "",
  side_effects: "",
};

// const defaultValues = {
//   benefits: "tewt",
//   brand_id: 2190,
//   catalogue_type: "RASEET",
//   category_id: 25,
//   description: "test",
//   dosage_form: "test",
//   drug_info: "test",
//   how_to_use: "test",
//   manufacturer: "test",
//   pack_price: 100,
//   pack_size: 100,
//   prescription: "PRESCRIPTION",
//   product_domain: "test",
//   product_name: "test001",
//   retail_store_id: "20",
//   salt_composition: "test",
//   side_effects: "test",
//   vendor: "Raseet",
//   vendor_code: "RAS01",
//   when_to_use: "test",
// };

function EditProduct() {
  const classes = useStyles();
  const {
    params: { productSku },
  } = useRouteMatch<{ productSku: string }>() || {};

  const [initialValues, setInitialValues] = useState<any>(defaultValues);
  const [loading, setLoading] = useState(true);

  const handleOnSubmitRC = async ({ product_name, ...values }: any) => {
    const data = {
      ...values,
      name: product_name,
    };

    await updateProduct(initialValues?.product_id, data)
      .then((res) => {
        console.log(res);
        if (!!res?.[0]?.id) {
          message.success("Product created successfully!");
          // window.location.reload();
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  const handleOnSubmitOC = async ({
    drug_info,
    when_to_use,
    how_to_use,
    benefits,
    side_effects,
    ...values
  }: any) => {
    const data = {
      ...values,
      catalogue_type: "RASEET",
      product_attributes: {
        drug_info,
        when_to_use,
        how_to_use,
        benefits,
        side_effects,
      },
    };

    if (data.prescription === "false") {
      data.prescription = null;
    }

    await editProduct(initialValues?.product_id, data)
      .then((res) => {
        console.log(res);
        if (!!res?.product?.id) {
          message.success("Product edited successfully!");
          // window.location.reload();
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  const fetchDefaultValues = async () => {
    let productRes = await getProductDetails(productSku);
    if (productRes?.length) {
      setInitialValues({
        ...productRes[0],
        product_name: productRes[0].name,
        prescription: productRes[0].prescription ?? "false",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDefaultValues();
  }, [productSku]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.addCouponPage}>
      <Card>
        <Title level={3} className={classes.pageTitle}>
          <EditOutlined />
          Edit Product
        </Title>
        {productSku.startsWith("RC") ? (
          <RetailerProductForm
            submitBtnText="Edit"
            onSubmit={handleOnSubmitRC}
            defaultValues={initialValues}
          />
        ) : (
          <RaseetProductForm
            submitBtnText="Edit"
            onSubmit={handleOnSubmitOC}
            defaultValues={initialValues}
          />
        )}
      </Card>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCouponPage: {
    height: "100vh",
    padding: [20, "7vw"],
    background: "#f9f9f9",

    "& .ant-card": {
      boxShadow:
        "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    },
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    "& .anticon": {
      marginRight: 10,
    },
  },
}));

export default EditProduct;
