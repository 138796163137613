import { getAllOrders, getRetailStoreDetails } from "../api/invoiceApis";
import { TableFilters } from "../components/tables/ReviewInvoiceTable";

export type OrderTypes = "All" | "Draft" | "Published" | "Incoming";

export async function getInvoicesByType(
  type: OrderTypes,
  retailStoreId?: any,
  count?: number,
  orderToSkip?: number,
  filters?: TableFilters | any
) {
  if (!!retailStoreId) {
    filters = {
      ...filters,
      orders_search: [
        // @ts-ignore
        ...filters?.orders_search,
        { column: "retail_store_id", column_value: retailStoreId },
      ],
    };
  }

  if (type === "Incoming") {
    filters = {
      ...filters,
      incoming_orders_search: filters?.orders_search.filter((item: any) => {
        if (item.column === "retail_store_id") {
          item.table = "orders";
        }
        return item;
      }),
      incoming_orders_order_by: filters?.orders_order_by,
    };
  }

  const orders = await getAllOrders(type, count, orderToSkip, filters);
  const data: any = [];

  for (let key = 0; key < orders.length; key++) {
    const item = orders[key];

    const {
      order_id,
      retail_store_name,
      retail_store_id,
      status,
      order_status,
      created_at,
      invoice_date,
      updated_at,
      last_action_by,
      published_by,
      incoming_order_created_at,
      incoming_order_id,
      incoming_order_type,
      incoming_order_status,
    } = item;
    data.push({
      key: (orderToSkip ?? 0) + key + 1,
      order_id,
      incoming_order_id,
      retail_store_id,
      status,
      order_status,
      incoming_order_type,
      incoming_order_status,
      updated_at,
      invoice_date,
      created_at: created_at || incoming_order_created_at,
      last_action_by: last_action_by || "",
      retail_store_name,
      published_by: published_by || "",
    });
  }

  return data;
}
