import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { createUseStyles } from "react-jss";
import { Table, Pagination, Select } from "antd";
import Loader from "./Loader";
import { BlockOutlined } from "@ant-design/icons";
import moment from "moment";
import { toggleCouponStatus } from "../api/invoiceApis";
import Search from "antd/lib/input/Search";
import { EditTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Option } = Select;

function CouponsCard({
  coupons,
  tableLoading,
  handleCouponsSearch,
  selectedCustomerUserId,
  paginationProps,
}: any) {
  const screen = useBreakpoint();
  const isLG = screen.lg;
  const classes = useStyles();

  const handleStatusChange = (data: any) => async (newValue: any) => {
    await toggleCouponStatus(
      newValue === "true",
      data.id,
      data.retail_store_id
    );
  };

  const couponsTablecolums: any = [
    {
      title: "#",
      dataIndex: "index",
      width: "60px",
      align: "center",
      render: (value: any, row: any, index: any) =>
        paginationProps.current === 1
          ? index + 1
          : +paginationProps.current * 20 - 20 + index + 1,
    },
    {
      title: "Name",
      dataIndex: "coupon_name",
      key: "coupon_name",
      width: "100px",
    },
    {
      title: "Code",
      dataIndex: "coupon_code",
      key: "coupon_code",
      width: "100px",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: "60px",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "90px",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: "120px",
      render: (value: boolean, row: any) => (
        <Select
          defaultValue={value.toString()}
          onChange={handleStatusChange(row)}
          style={{ width: "100%" }}
        >
          <Option value="true">Active</Option>
          <Option value="false">Disabled</Option>
        </Select>
      ),
    },
    {
      title: "Min Amount",
      dataIndex: "min_cart_amount",
      key: "min_cart_amount",
      width: "100px",
    },
    {
      title: "Max Value",
      dataIndex: "max_value",
      key: "max_value",
      width: "100px",
      render: (value: any) => (value !== null ? value : " - "),
    },
    {
      title: "Usage/Customer",
      dataIndex: "usage_per_customer",
      key: "usage_per_customer",
      width: "125px",
      render: (value: number) => value || "Unlimited",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "100px",
      render: (value: number) => value || "Unlimited",
    },
    {
      title: "Customers",
      dataIndex: "customers",
      key: "customers",
      width: "90px",
      render: (value: Array<string>) => value.length || "All",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "200px",
      render: (date: any) =>
        !!date ? moment(date).format("DD MMMM, YYYY, hh:mm A") : " - ",
    },
    {
      title: "Valid Till",
      dataIndex: "valid_till",
      key: "valid_till",
      width: "200px",
      render: (date: any) =>
        !!date ? moment(date).format("DD MMMM, YYYY, hh:mm A") : " - ",
    },
    {
      title: "Edit",
      width: "50px",
      fixed: "right",
      render: (value: any, coupon: any) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to={`/admin/dashboard/coupons/${coupon.id}`}>
            <EditTwoTone />
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={classes.tableCardTitle}>
        <div>
          <BlockOutlined />
          Coupons
        </div>
        <Search
          enterButton
          placeholder="Search Coupons..."
          onSearch={handleCouponsSearch}
        />
      </div>
      {!tableLoading || selectedCustomerUserId === "" ? (
        <>
          <Table
            pagination={false}
            dataSource={coupons}
            columns={couponsTablecolums}
            size={isLG ? "middle" : "small"}
            scroll={{ y: "calc(100vh - 270px)", x: true }}
          />
          {/* <div className={classes.paginationWrapper}>
            <Pagination
              size={isLG ? "default" : "small"}
              {...paginationProps}
            />
          </div> */}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  tableCardTitle: {
    color: "#505050",
    fontSize: 22,
    padding: "10px 20px 15px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .ant-input-search": {
      width: "40%",
      "& input": {
        fontWeight: 600,
      },
    },

    "& > div .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  paginationWrapper: {
    padding: [20, 25, 10],
    position: "absolute",
    bottom: 5,
    left: 0,
  },

  "@media (max-width: 768px)": {
    tableCardTitle: {
      padding: "5px 15px 10px",
      display: "block",
      fontSize: 18,

      "& .ant-input-search": {
        width: "100%",
        marginTop: 10,
        "& input": {
          fontSize: 12,
        },
        "& .ant-input-search-button": {
          height: 28,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },

      "& > div .anticon": {
        fontSize: 18,
      },
    },
  },
}));

export default CouponsCard;
