import classNames from 'classnames'
import Col from 'antd/es/grid/col'
import React from 'react'
import Row from 'antd/es/row'
import { createUseStyles } from 'react-jss'

type OnboardingWrapperProps = React.PropsWithChildren<{
  height?: 'full' | 'normal'
}>

const useStyles = createUseStyles(() => ({
  container: {
    width: '100%'
  },
  container_fullHeight: {
    height: '100%'
  },
  wrapper: {
    height: 'fit-content'
  }
}))

function OnboardingWrapper({ children, height = 'normal' }: OnboardingWrapperProps) {
  const classes = useStyles()

  return (
    <Row
      align="middle"
      className={classNames(classes.container, {
        [classes.container_fullHeight]: height === 'full'
      })}
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
    >
      <Col
        xs={{ span: 18, offset: 3 }}
        sm={{ span: 14, offset: 5 }}
        lg={{ span: 8, offset: 8 }}
        className={classes.wrapper}
      >
        {children}
      </Col>
    </Row>
  )
}

export default OnboardingWrapper
    