import React, { useEffect, useState, PropsWithChildren } from 'react'
import { CachePersistor } from 'apollo-cache-persist'
import { ApolloClient, ApolloProvider } from '@apollo/client'

import client, { cache } from './client'

const SCHEMA_VERSION = '1'
const SCHEMA_VERSION_KEY = 'apollo-schema-version'

const storage = window.localStorage as any

function ApolloClientProvider({ children }: PropsWithChildren<{}>) {
  const [ apolloClient, setApolloClient ] = useState<ApolloClient<any> | null>(null)

  useEffect(() => {
    const persistor = new CachePersistor({
      cache,
      storage
    })

    const currentVersion = localStorage.getItem(SCHEMA_VERSION_KEY)

    if (currentVersion === SCHEMA_VERSION) {
      persistor.restore().then(() => {
        setApolloClient(client)
      })
    } else {
      persistor.purge().then(() => {
        storage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
        setApolloClient(client)
      })
    }

    return () => {}
  }, [])

  if (!apolloClient) {
    return null
  }

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloClientProvider
