import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import { getSession, Session, logout } from '../client/reactives/session'
import fields from '../client/reactives'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields
    }
  }
})

const authLink = setContext((_, { headers }) => {
  const { token } = getSession() as Session
  const clientHeaders = { ...headers }

  if (token) {
    clientHeaders['X-Token'] = token
  }

  return {
    headers: clientHeaders
  }
})

const httpLink = createUploadLink({ uri: `${process.env.REACT_APP_API_BASE_URL}/graphql` })

const errorLink = onError(({ networkError }) => {
  if (!networkError) {
    return
  }

  if ('statusCode' in networkError && networkError.statusCode === 401) {
    logout()
    cache.reset()
  }
})

const link = from([
  authLink,
  errorLink,
  httpLink
])

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
})

export { cache }

export default client
