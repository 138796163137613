import Button from "antd/es/button";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import EmptyCardIllustration from "../assets/svg/empty-cart.svg";

 const EmptyCart = ({ onButtonClick }: any) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        padding: "2rem",
        fontWeight: 700,
        color: "#7f5e5e",
        alignItems: "center",
        background: "#f2f2f2",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img src={EmptyCardIllustration} style={{ width: "30%" }} />
      <p style={{ marginTop: "2.5rem", fontSize: 20 }}>Cart is Empty...</p>
      <Button
        type="primary"
        icon={<ArrowLeftOutlined />}
        onClick={onButtonClick}
      >
        Go Back
      </Button>
    </div>
  );
};

export default EmptyCart