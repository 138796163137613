import { DeleteTwoTone, EyeTwoTone } from "@ant-design/icons";
import { Button, Pagination, Popconfirm, Table, Typography } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { PaginationProps } from "antd/lib/pagination";
import moment from "moment";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import DisputeResolveModal from "../modal/DisputeResolveModal";
import MapOrderModal from "../modal/MapOrderModal";

const getOrderStatus = (value: number) => {
  let status = "";

  switch (value) {
    case 1:
      status = "Order Placed";
      break;
    case 2:
      status = "Packed";
      break;
    case 3:
      status = "Shipped";
      break;
    case 4:
      status = "Partially Delivered";
      break;
    case 5:
      status = "Delivered";
      break;
    case 6:
      status = "Closed";
      break;
    case 7:
      status = "Closed";
      break;
  }

  return status;
};
interface ReordersTableProps {
  activeTab: string;
  reorders: any[];
  onViewOverview: (reorderId: number) => void;
  onReorderCancel: (reorderId: number) => void;
  onChange: () => void;
  pagination: {
    pageSize: number;
    total: number;
    current: number;
    onChange: PaginationProps["onChange"];
    onShowSizeChange: PaginationProps["onShowSizeChange"];
  };
}

export default function ReordersTable({
  activeTab,
  reorders,
  pagination,
  onChange,
  onViewOverview,
  onReorderCancel,
}: ReordersTableProps) {
  const classes = useStyle();
  const isLG = useBreakpoint().lg;

  const [mapOrderModalVisible, setMapOrderModalVisible] = useState(false);
  const [disputeModalVisible, setDisputeModalVisible] = useState(false);
  const [selectedReorder, setSelectedReorder] = useState<any>(null);

  const reordersTablecolums: any = [
    {
      title: "#",
      dataIndex: "index",
      width: "60px",
      align: "center",
      render: (value: any, row: any, index: any) =>
        pagination.current === 1
          ? index + 1
          : +pagination.current * 20 - 20 + index + 1,
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
      width: "100px",
    },
    {
      title: "Reorder ID",
      dataIndex: "id",
      key: "id",
      width: "100px",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: "80px",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: "130px",
      render: (val: string) => (!!val ? val : " - "),
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "order_status",
      width: "120px",
      render: (val: number) => getOrderStatus(val),
    },
    {
      title: "Reconciliation",
      dataIndex: "reconciliation_status",
      key: "reconciliation_status",
      width: "140px",
      render: (val: string, row: any) =>
        activeTab === "delivered" ? (
          <Button
            size="small"
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setMapOrderModalVisible(true);
              setSelectedReorder(row);
            }}
          >
            {val !== "Verification Pending" ? "Map Orders" : val}
          </Button>
        ) : (
          <Typography.Text>{val ?? " - "}</Typography.Text>
        ),
    },
    {
      title: "Dispute",
      dataIndex: "dispute_desc",
      key: "dispute_desc",
      width: "120px",
      render: (dispute_desc: any, row: any) =>
        !!dispute_desc ? (
          <Button
            size="small"
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              setDisputeModalVisible(true);
              setSelectedReorder(row);
            }}
          >
            {!!row?.settle_desc ? "View" : "Settle"}
          </Button>
        ) : (
          " - "
        ),
    },
    {
      title: "Grand Total",
      dataIndex: "grandtotal",
      key: "grandtotal",
      width: "120px",
      sorter: (a: any, b: any) => +a.grandtotal - +b.grandtotal,
      render: (grandtotal: any, row: any) => (+grandtotal).toFixed(2),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "160px",
      sorter: (a: any, b: any) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (date: any) => moment(date).format("DD MMMM, hh:mm A"),
    },
    {
      title: "Delete",
      width: "60px",
      render: (value: any, reorder: any) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onReorderCancel(reorder.id)}
          >
            <DeleteTwoTone />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "View",
      width: "50px",
      fixed: "right",
      render: (value: any, reorder: any) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Button
            type="link"
            icon={<EyeTwoTone />}
            onClick={() => onViewOverview(reorder.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        pagination={false}
        dataSource={reorders}
        columns={reordersTablecolums}
        size={isLG ? "middle" : "small"}
        scroll={{ y: "calc(100vh - 200px)", x: true }}
      />
      <div className={classes.paginationWrapper}>
        <Pagination size={isLG ? "default" : "small"} {...pagination} />
      </div>

      <DisputeResolveModal
        visible={disputeModalVisible}
        reorderId={selectedReorder?.id}
        disputeReason={selectedReorder?.dispute_desc}
        settleReason={selectedReorder?.settle_desc}
        onSettled={onChange}
        onCancel={() => {
          setDisputeModalVisible(false);
          setSelectedReorder(null);
        }}
      />

      <MapOrderModal
        visible={mapOrderModalVisible}
        reorderId={selectedReorder?.id}
        customerUserId={selectedReorder?.user_id}
        storeId={selectedReorder?.retail_store_id}
        mappedOrders={selectedReorder?.mapped_orders}
        isVerificationPending={
          selectedReorder?.reconciliation_status === "Verification Pending"
        }
        onStatusChange={onChange}
        onCancel={() => {
          setMapOrderModalVisible(false);
          setSelectedReorder(null);
        }}
      />
    </>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  paginationWrapper: {
    padding: [20, 25, 10],
  },
}));
