import { Tabs, Select } from "antd";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import ReviewInvoiceTable from "../../components/tables/ReviewInvoiceTable";
import { OrderTypes } from "../../utils/getInvoices";
import { getAllRetailStores } from "../../api/invoiceApis";
const { TabPane } = Tabs;

const tabKeysWithHash = ["#All", "#Incoming", "#Draft", "#Published"];
const tabKeysWithoutHash: OrderTypes[] = [
  "All",
  "Incoming",
  "Draft",
  "Published",
];

interface ReviewInvoicesProps {
  setOrders: React.SetStateAction<any>;
}

function ReviewInvoices({ setOrders }: ReviewInvoicesProps) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [stores, setStores] = useState([]);
  const [activeStore, setActiveStore] = useState(0);

  const { hash } = useLocation();
  const { replace, location } = useHistory();

  const setActiveTabValue = () => {
    if (!!hash && tabKeysWithHash.includes(hash)) {
      setActiveTab(hash);
    } else {
      setActiveTab("#All");
    }
  };

  const fetchInitialData = async () => {
    await getAllRetailStores().then((res) => {
      if (res.length) {
        setStores(res);
        setLoading(false);
      }
    });
  };

  const handleInvoicesChange = (key: string) => (invoices: Array<any>) => {
    if (invoices?.length && key !== "All") {
      setOrders((curr: any) => {
        curr[key] = invoices.map(
          (item) => item?.order_id ?? item?.incoming_order_id
        );
        return curr;
      });
    }
  };

  useEffect(() => {
    if (loading) {
      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    setActiveTabValue();
  }, [hash]);

  useEffect(() => {
    let newPathName = "/admin/dashboard/review-invoices" + activeTab;
    if (location.pathname !== newPathName) {
      replace(newPathName);
    }
  }, [activeTab]);

  if (loading) {
    return <Loader />;
  }

  const StoreSelector = () => (
    <Select
      showSearch
      placeholder="Filter by Retailer"
      optionFilterProp="children"
      value={+activeStore ?? 0}
      onChange={(id) => setActiveStore(id)}
      className={classes.searchSelect}
      filterOption={(input, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Select.Option key={""} value={0}>
        All Stores
      </Select.Option>

      {stores.map((store: any) => (
        <Select.Option key={`#${store.id} - ${store.name}`} value={+store?.id}>
          #{store.id} - {store.name}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <div className={classes.reviewInvoicesPage}>
      <Tabs defaultActiveKey={activeTab} onChange={setActiveTab}>
        <StoreSelector />
        {tabKeysWithoutHash.map((key, index) => (
          <TabPane tab={key + " Invoices"} key={tabKeysWithHash[index]}>
            <ReviewInvoiceTable
              storeId={activeStore}
              type={key}
              onInvoicesChange={handleInvoicesChange(key)}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  reviewInvoicesPage: {
    height: "100%",
    overflow: "hidden",
    padding: "2rem 3rem",

    "& .ant-tabs": {
      marginBottom: 15,
      position: "relative",
    },
    "& .ant-table": {
      display: "contents",
    },
  },
  searchSelect: {
    width: 300,
    zIndex: 5,
    position: "absolute",
    top: 0,
    right: 0,
    margin: "5px 3% 10px",
    padding: 0,
    fontWeight: 600,
  },
}));

export default ReviewInvoices;
