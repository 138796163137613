import { useState, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Select from "antd/es/select";
import DatePicker from "antd/es/date-picker";
import {
  getAllRetailStores,
  getRetailStoreCustomers,
} from "../../api/invoiceApis";
import Coupon from "../../models/Coupon";
import Field from "../field/Field";
import moment from "moment";
import useForm from "../../hooks/useForm";
import Loader from "../Loader";
const { Option } = Select;

interface AddCouponFormProps {
  submitBtnText?: string;
  retailStores?: Array<Object>;
  selectedCustomers: Array<string>;
  defaultValues: any;
  onSubmit: (values: any) => void;
  onSelectedCustomerChange: (value: any) => void;
}

function AddCouponForm({
  submitBtnText = "Add",
  retailStores = [],
  selectedCustomers,
  defaultValues,
  onSubmit,
  onSelectedCustomerChange,
  ...props
}: AddCouponFormProps) {
  const classes = useStyles();
  const [customerSearch, setCustomerSearch] = useState("");
  const [pageData, setPageData] = useState<any>({
    customers: [],
    selectedStoreId: "",
    retailStores: retailStores,
    loading: defaultValues?.id === 0,
  });

  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    onSubmit,
    defaultValues,
    resolver: Coupon.addCoupon(),
  });

  // fetch retail stores on page load
  useEffect(() => {
    if (pageData.loading && pageData.retailStores.length === 0) {
      fetchRetailStores();
    }
  }, []);

  // fetch customer on stor change or on search
  useEffect(() => {
    if (pageData.selectedStoreId !== "") {
      fetchCustomers(customerSearch);
    }
  }, [pageData.selectedStoreId, customerSearch]);

  // chnage selected store change to fetch customers accordingly
  useEffect(() => {
    if (
      pageData.selectedStoreId !== methods.watch().retail_store_id &&
      +methods.watch().retail_store_id &&
      methods.watch().retail_store_id!
    ) {
      handleSelectedStoreChange(methods.watch().retail_store_id);
    }
  }, [methods.watch().retail_store_id]);

  // -------
  // -------
  // -------

  const fetchRetailStores = async () => {
    const stores = await getAllRetailStores();
    if (stores.length) {
      onSelectedCustomerChange([]);
      setPageData({
        ...pageData,
        loading: false,
        retailStores: stores,
      });
    }
  };

  const fetchCustomers = async (search?: string) => {
    const customers = await getRetailStoreCustomers(
      +pageData.selectedStoreId,
      search || "",
      50
    );
    setPageData({ ...pageData, customers });
  };

  const handleSelectedStoreChange = (value: any) => {
    onSelectedCustomerChange([]);
    setPageData({
      ...pageData,
      selectedStoreId: value,
      customers: [],
    });
  };

  const handleSelectedCustomersChange = (arr: any) => {
    setCustomerSearch("");
    onSelectedCustomerChange(arr);
  };

  const customerSelectFilterOption = (input: any, option: any) => {
    setCustomerSearch(input);
    return option?.key?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const StoreSelector = (
    <Select
      showSearch
      placeholder="Select Retailer"
      optionFilterProp="children"
      className={classes.searchSelect}
      filterOption={(input, option: any) =>
        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {pageData.retailStores.map((store: any) => (
        <Option key={`#${store.id} - ${store.name}`} value={store?.id}>
          #{store.id} - {store.name}
        </Option>
      ))}
    </Select>
  );

  const CouponTypeSelector = (
    <Select placeholder="Select discount type">
      <Option value="percentage">Percentage ( % )</Option>
      <Option value="value">Absolute Value ( ₹ )</Option>
    </Select>
  );

  const StatusSelector = (
    <Select placeholder="Select status">
      <Option value="true">Active</Option>
      <Option value="false">Deactivated</Option>
    </Select>
  );

  if (pageData?.loading) {
    return <Loader />;
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler} className={classes.addCouponForm}>
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Field as={StoreSelector} name="retail_store_id" />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={CouponTypeSelector} name="type" />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="coupon_name"
                type="text"
                placeholder="Coupon Name"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="coupon_code"
                type="text"
                placeholder="Coupon Code"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="value"
                type="number"
                placeholder="Coupon Value"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="min_cart_amount"
                type="number"
                placeholder="Minimum cart amount"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={
                  <DatePicker
                    showTime
                    disabledDate={(current) =>
                      current && current < moment().subtract(1, "days")
                    }
                  />
                }
                name="start_date"
                placeholder="Start Date"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={
                  <DatePicker
                    showTime
                    disabledDate={(current) =>
                      current && current < moment().subtract(1, "days")
                    }
                  />
                }
                name="valid_till"
                placeholder="Valid Till"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={StatusSelector} name="is_active" />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="description"
                type="text"
                placeholder="Coupon Description"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                type="number"
                name="max_value"
                placeholder="Max Discount Value ( if % )"
                disabled={methods.watch().type !== "percentage"}
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                type="number"
                name="usage_per_customer"
                placeholder="Usage per customer ( leave blank for unlimited )"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field
                as={Input}
                type="number"
                name="quantity"
                placeholder="No. of coupons ( leave blank for unlimited )"
              />
            </Col>

            <Col xs={24} lg={12}>
              <input type="hidden" name="customers" value={selectedCustomers} />
              <Select
                allowClear
                mode="multiple"
                optionLabelProp="label"
                defaultValue={selectedCustomers}
                className={classes.customerSelect}
                onChange={handleSelectedCustomersChange}
                onSearch={(val) => setCustomerSearch(val)}
                filterOption={customerSelectFilterOption}
                placeholder="Selected Customers Only ( leave blank for all )"
              >
                {pageData.customers.map((customer: any) => (
                  <Option
                    key={`#${customer.user_id} - ${customer.name}`}
                    value={customer?.user_id}
                    label={customer.name}
                  >
                    #{customer.user_id} - {customer.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xs={24}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                style={{ width: "50%" }}
              >
                {submitBtnText}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCouponForm: {
    padding: [20, 0],

    "& *": {
      fontWeight: 600,
    },
    "& .ant-picker": {
      width: "100%",
    },
  },
  searchSelect: {
    width: "100%",
    padding: 0,
    fontWeight: 600,
  },
  customerSelect: {
    width: "100%",
    marginBottom: 24,
    padding: 0,
    fontWeight: 600,
  },
}));

export default AddCouponForm;
