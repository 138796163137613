import Sidebar from "./Sidebar";
import { createUseStyles } from "react-jss";
import { Col, Row } from "antd/es/grid";
import Topbar from "./Topbar";
import customeScrollbarStyle from "../styles/CustomScrollbar";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

function AdminDashboardLayout({ children }: any) {
  const classes = useStyles();
  const screen = useBreakpoint();
  const isLG = screen.lg;

  return (
    <Row className={classes.adminDashboard}>
      {!isLG ? (
        <Col span={24}>
          <Topbar />
        </Col>
      ) : (
        <Col span={1}>
          <Sidebar />
        </Col>
      )}
      <Col span={isLG ? 23 : 24}>
        <div className={classes.pageContent}>{children}</div>
      </Col>
    </Row>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  adminDashboard: {
    height: "100%",
    overflow: "hidden",
  },
  pageContent: {
    overflow: "auto",
    height: "100vh",
  },
}));

export default AdminDashboardLayout;
