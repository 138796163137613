import { ShoppingOutlined } from "@ant-design/icons";
import { Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import {
  deleteAllReorderItems,
  deleteReorder,
  getCustomerReorders,
  getReordersByRetailStore,
  getReordersSummary,
} from "../api/invoiceApis";
import Loader from "./Loader";
import ReordersTable from "./tables/ReordersTable";

interface ReorderListCardProps {
  storeId: number;
  userId: number;
  onReorderView: (reorderId: number) => void;
}

export default function ReorderListCard({
  storeId,
  userId,
  onReorderView,
}: ReorderListCardProps) {
  const classes = useStyle();
  const [reorders, setReorders] = useState([]);
  const [reorderStatus, setReorderStatus] = useState("incoming");
  const [loading, setLoading] = useState(false);
  const [reordersSummary, setReordersSummary] = useState({
    reorder_total_all_time: 0,
  });
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });

  const handleViewOverview = (val: number) => {
    onReorderView(val);
  };

  const handleReorderCancel = async (orderId: any) => {
    await deleteAllReorderItems(+orderId);
    await deleteReorder(+orderId);
    setLoading(true);
  };

  const handlePaginationChange = (page: number, pageSize?: number) =>
    setPagination({ ...pagination, current: page, pageSize: pageSize ?? 20 });

  const fetchReorders = async () => {
    let reordersRes, reorderSummaryRes;

    if (!!storeId && userId === 0) {
      reordersRes = await getReordersByRetailStore(
        storeId,
        pagination.pageSize,
        pagination.current,
        reorderStatus
      );
      reorderSummaryRes = await getReordersSummary(storeId);
    }
    //
    else if (!!storeId && !!userId) {
      reorderSummaryRes = await getReordersSummary(storeId, userId);
      reordersRes = await getCustomerReorders(
        userId,
        storeId,
        pagination.pageSize,
        pagination.current,
        reorderStatus
      );
    }

    setReorders(reordersRes);
    setReordersSummary(reorderSummaryRes);
    setLoading(false);
    setPagination({
      ...pagination,
      total: reorderSummaryRes?.reorder_count_all_time,
    });
  };

  useEffect(() => {
    if (!!storeId || !!userId) {
      setLoading(true);
    }
  }, [storeId, userId, pagination.current, pagination.pageSize, reorderStatus]);

  useEffect(() => {
    if (loading) {
      fetchReorders();
    }
  }, [loading]);

  return (
    <div className={classes.reorderListCard}>
      {/* <div className={classes.cardHeader}>
        <div>
          <ShoppingOutlined />
          <Typography.Text strong className={classes.cardTitle}>
            Reorders
          </Typography.Text>
        </div>
        <Typography.Text>
          All Reorders Total: ₹{reordersSummary?.reorder_total_all_time}
        </Typography.Text>
      </div> */}

      <Tabs
        defaultActiveKey="1"
        onChange={setReorderStatus}
        className={classes.tabs}
        tabBarExtraContent={
          <Typography.Text>
            All Reorders Total: ₹{reordersSummary?.reorder_total_all_time}
          </Typography.Text>
        }
      >
        <Tabs.TabPane tab="Incoming" key="incoming"></Tabs.TabPane>
        <Tabs.TabPane tab="Delivered" key="delivered"></Tabs.TabPane>
        <Tabs.TabPane tab="Reconciled" key="reconciled"></Tabs.TabPane>
        <Tabs.TabPane tab="Settled" key="settled"></Tabs.TabPane>
      </Tabs>

      {!loading ? (
        <ReordersTable
          reorders={reorders}
          activeTab={reorderStatus}
          onViewOverview={handleViewOverview}
          onReorderCancel={handleReorderCancel}
          pagination={{
            ...pagination,
            onChange: handlePaginationChange,
            onShowSizeChange: handlePaginationChange,
          }}
          onChange={() => setLoading(true)}
        />
      ) : (
        <Loader height="80vh" />
      )}
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  reorderListCard: {
    width: "100%",
    flex: 1,
    marginBottom: 15,
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  cardHeader: {
    padding: [5, 15, 10],
    color: colors.textPrimary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  tabs: {
    padding: [0, 20],
  },
  cardTitle: {
    fontSize: 22,
  },
}));
