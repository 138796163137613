import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { createUseStyles } from "react-jss";
import Col from "antd/es/col";
import Search, { SearchProps } from "antd/es/input/Search";
import Table from "antd/es/table";
import Pagination, { PaginationProps } from "antd/es/pagination";
import ShoppingOutlined from "@ant-design/icons/ShoppingOutlined";
import NumberSelect from "./NumberSelect";
import Loader from "./Loader";
import { Empty } from "antd";

interface ProductsCardProps {
  products: Array<any>;
  isLoading: boolean;
  cartId: number;
  selectedCustomerUserId: any;
  onQuantityChange: (qty: number, row: Object) => void;
  onProductsSearch: SearchProps["onSearch"];
  paginationProps: {
    pageSize: number;
    total: number;
    current: number;
    onChange: PaginationProps["onChange"];
    onShowSizeChange: PaginationProps["onShowSizeChange"];
  };
}

function ProductsCard({
  products,
  cartId,
  isLoading,
  paginationProps,
  selectedCustomerUserId,
  onQuantityChange,
  onProductsSearch,
}: ProductsCardProps) {
  const classes = useStyles();
  const screen = useBreakpoint();
  const isLG = screen.lg;

  const productsTablecolums: any = [
    {
      title: "#",
      dataIndex: "index",
      width: "60px",
      align: "center",
      render: (value: any, row: any, index: any) =>
        paginationProps.current === 1
          ? index + 1
          : +paginationProps.current * 20 - 20 + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "160px",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "80px",
      render: (qty: any, row: any) => (
        <NumberSelect
          till={99}
          value={qty}
          onChange={(qty: number) => onQuantityChange(qty, row)}
        />
      ),
    },
    {
      title: "Pack",
      dataIndex: "pack",
      key: "pack",
      width: "100px",
      render: (val: any, row: any) => row?.pack ?? row.pack_size ?? " - ",
    },
    {
      title: "Price",
      dataIndex: "pack_price",
      key: "pack_price",
      width: "100px",
      render: (val: any, row: any) => (+row?.pack_price).toFixed(2),
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      width: "110px",
      render: (val: any) => (+val).toFixed(2),
    },
  ];

  return (
    <Col
      xs={24}
      lg={!!cartId && +cartId !== 0 ? 12 : 17}
      className={classes.tableCard}
    >
      <div className={classes.tableCardTitle}>
        <div>
          <ShoppingOutlined /> Products
        </div>
        <Search
          enterButton
          placeholder="Search Products..."
          onSearch={onProductsSearch}
        />
      </div>
      {!isLoading ? (
        selectedCustomerUserId === "" ? (
          <div className={classes.emptyWrapper}>
            <Empty description="Please select a customer" />
          </div>
        ) : products.length ? (
          <>
            <Table
              pagination={false}
              dataSource={products}
              columns={productsTablecolums}
              size={isLG ? "middle" : "small"}
              scroll={{ y: "calc(100vh - 220px)" }}
              className={classes.productsTable}
              onRow={(record) => {
                console.log(+record.quantity > 0);
                return {
                  className: +record?.quantity > 0 ? "selected" : "",
                };
              }}
            />
          </>
        ) : (
          <div className={classes.emptyWrapper}>
            <Empty description="Please select retailer and customer" />
          </div>
          // <Loader />
        )
      ) : (
        <Loader height="80vh" />
      )}
      {/* <div hidden={!products.length} className={classes.paginationWrapper}>
        <Pagination size={isLG ? "default" : "small"} {...paginationProps} />
      </div> */}
    </Col>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  tableCard: {
    width: "100%",
    padding: "0.5rem",
    boxShadow:
      "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    borderRadius: 5,
    backgroundColor: "#fff",
  },
  tableCardTitle: {
    color: "#505050",
    fontSize: 22,
    padding: "10px 20px 15px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .ant-input-search": {
      width: "40%",
      "& input": {
        fontWeight: 600,
      },
    },

    "& > div .anticon": {
      fontSize: 20,
      paddingRight: 10,
    },
  },
  productsTable: {
    "& tr.selected": {
      background: colors.light300,
    },
  },
  paginationWrapper: {
    padding: [20, 25, 10],
  },

  "@media (max-width: 768px)": {
    tableCardTitle: {
      padding: "5px 15px 10px",
      display: "block",
      fontSize: 18,

      "& .ant-input-search": {
        width: "100%",
        marginTop: 10,
        "& input": {
          fontSize: 12,
        },
        "& .ant-input-search-button": {
          height: 28,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },

      "& > div .anticon": {
        fontSize: 18,
      },
    },

    tableCard: {
      padding: "0.25rem !important",

      "& .ant-table *": {
        fontSize: 12,
      },
    },
  },
  emptyWrapper: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default ProductsCard;
