import { Input } from "antd";
import Button from "antd/es/button";
import Field from "../field/Field";
import FormBox from "../FormBox";
import hideNumberInputArrows from "../../styles/hideNumberInputArrows";
import React from "react";
import useForm from "../../hooks/useForm";
import { createUseStyles } from "react-jss";
import { FormProvider } from "react-hook-form";
import type { FormProps } from "../../hooks/useForm";
import { AnyARecord } from "dns";
import User from "../../models/User";

function CustomerAddressForm({ onSkip, ...props }: any) {
  const classes = useStyles();
  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    resolver: User.addCustomer(),
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={submitHandler}
          className={classes.customerAddressFormView}
        >
          <Field
            as={Input}
            name="name"
            type="text"
            placeholder="Customer Name"
          />

          <Field
            as={Input}
            name="mobileNumber"
            type="number"
            placeholder="Mobile Number"
          />

          <Button block type="primary" htmlType="submit" loading={isSubmitting}>
            Add
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  customerAddressFormView: {
    ...hideNumberInputArrows,

    "& .ant-btn": {
      fontWeight: "500",
      letterSpacing: "0.45px",
    },
  },
}));

export default CustomerAddressForm;
