import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
//import InternalContext from 'contexts/InternalContext'
import { User } from "../../generated/schema";
//import CustomerDashboard from 'components/pages/customerDashboard'
//import RetailerDashboard from 'components/pages/oldRetailerDashboard'
//import RetailerDashboard from 'components/pages/retailerDashboard'
import AdminDashboard from "../../pages/adminDashboard";

import { getSession, Session } from "../../client/reactives/session";

enum SessionState {
  LOGGED_IN = "logged-in",
  LOGGED_OUT = "logged-out",
}

enum RestrictedSection {
  RETAILER = "Retailer",
  CUSTOMER = "Customer",
  ADMIN = "admin",
  SUPER_ADMIN = "super_admin",
}

enum SectionPathPrefix {
  RETAILER = "/retailer",
  CUSTOMER = "/dashboard",
  ADMIN = "/admin",
}

enum UserType {
  USER = "user",
  ADMIN = "admin",
  SUPER_ADMIN = "super_admin",
}

type ProtectedRouteProps = RouteProps & {
  layout: React.JSXElementConstructor<any>;
  requiredSessionState: SessionState;
  requiredRestrictedSection?: RestrictedSection;
  requiredUserType?: UserType;
};

function canAccessSection(
  roles: User["roles"] = [],
  section?: RestrictedSection
) {
  // if a route has no required section then the route can be accessed by users
  if (!section) {
    return true;
  }

  return roles.includes(section);
}

function redirectToRootPath(userType: string | undefined) {
  switch (userType) {
    case RestrictedSection.RETAILER:
    //return <RetailerDashboard />
    case RestrictedSection.CUSTOMER:
    //return <CustomerDashboard />
    case RestrictedSection.ADMIN:
      return <AdminDashboard />;
    case RestrictedSection.SUPER_ADMIN:
      return <AdminDashboard />;
    default:
      return <Redirect to="/" />;
  }
}

function determineRootRedirection(userType: string | undefined) {
  //const [ role ] = roles

  return redirectToRootPath(userType);
}

function ProtectedRoute({
  component,
  layout: Layout,
  render,
  requiredRestrictedSection,
  requiredSessionState,
  requiredUserType,
}: ProtectedRouteProps) {
  // const { currentUser, currentAdminUser } = useContext(InternalContext)
  // const { roles } = currentUser || {}
  const { token, userType } = getSession() as Session;
  let currentUser = false;
  let currentAdminUser = false;

  if (token != null) {
    currentUser = true;
    currentAdminUser = true;
  }
  const Component = (component || render) as React.JSXElementConstructor<any>;

  if (requiredSessionState === SessionState.LOGGED_IN) {
    if (requiredUserType === UserType.USER && !currentUser) {
      return <Redirect to="/login" />;
    }

    if (requiredUserType === UserType.ADMIN && !currentAdminUser) {
      return <Redirect to="/admin/login" />;
    }

    if (
      currentAdminUser &&
      requiredRestrictedSection
      //&& !canAccessSection(roles, requiredRestrictedSection)
    ) {
      return redirectToRootPath(userType?.toString());
    }
  }
  if (requiredSessionState === SessionState.LOGGED_OUT && currentAdminUser) {
    //&& currentUser
    return determineRootRedirection(userType?.toString());
  }

  // if (requiredSessionState === SessionState.LOGGED_OUT) {
  //   return <Redirect to="/admin" />
  // }

  return (
    <Layout>
      <Component />
    </Layout>
  );
}

export { RestrictedSection, SessionState, UserType };

export default ProtectedRoute;
