import { createUseStyles } from "react-jss";
import RaseetLogo from "../assets/svg/raseet-bw-logo.png";
import {
  ShoppingOutlined,
  EditOutlined,
  FundViewOutlined,
  ShoppingCartOutlined,
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
  ContainerOutlined,
  BlockOutlined,
  AppstoreAddOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { NavLink, useRouteMatch } from "react-router-dom";
import PlaceholderImage from "../assets/svg/placeholder-profile-img.svg";
import { Tooltip } from "antd";

function Sidebar() {
  const classes = useStyles();
  const { path } = useRouteMatch("/admin/dashboard") || {};

  const LinkWrapper = (props: any) => (
    <Tooltip title={props.title} placement="right" className={classes.menuIcon}>
      {props.children}
    </Tooltip>
  );

  const navLinks = [
    {
      to: path + "/review-invoices",
      label: "Review Invoices",
      icon: <FundViewOutlined />,
    },
    {
      to: path + "/settings",
      label: "Settings",
      icon: <SettingOutlined />,
    },
    {
      to: "/logout",
      label: "Logout",
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className={classes.sidebar}>
      <div className={classes.logo}>
        <img src={RaseetLogo} alt="Raseet Logo" />
      </div>

      <div className={classes.sidebarMenu}>
        <NavLink
          to={path + "/review-invoices"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Review Invoices">
            <FundViewOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/add-invoice"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Add Invoice">
            <EditOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/place-order?store=&customer="}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Place Reorder">
            <ShoppingCartOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/reorders?store=&customer="}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Reorders List">
            <ShoppingOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/add-coupon"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Add Coupon">
            <ContainerOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/coupons"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Coupons List">
            <BlockOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/manage-products"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Manage Products">
            <AppstoreAddOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/marketing-campaigns"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Marketing Campaigns">
            <LineChartOutlined />
          </LinkWrapper>
        </NavLink>
      </div>

      <div className={classes.sidebarBottom}>
        {/* <NavLink
          to={path + "/notification"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Notifications">
            <BellOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={path + "/settings"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Settings">
            <SettingOutlined />
          </LinkWrapper>
        </NavLink> */}

        <NavLink
          to={"/logout"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <LinkWrapper title="Logout">
            <LogoutOutlined />
          </LinkWrapper>
        </NavLink>

        <NavLink
          to={"/profile"}
          activeClassName={classes.activeMenuOption}
          className={classes.menuOption}
        >
          <img
            className={classes.profileImg}
            src={PlaceholderImage}
            alt="Profile Image"
          />
        </NavLink>
      </div>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  sidebar: {
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    padding: "0.5rem",
    backgroundColor: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  logo: {
    width: "100%",
    paddingTop: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& img": {
      width: 40,
      margin: "auto",
      objectFit: "contain",
    },
  },
  sidebarMenu: {
    paddingTop: "6rem",
    flex: "1 1",

    "& $menuOption": {
      marginBottom: "2.5rem",
    },
  },
  menuOption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    opacity: "0.5",

    "&:hover": {
      opacity: "0.8",
    },
  },
  activeMenuOption: {
    opacity: "1",

    "&:hover": {
      opacity: "1",
    },

    "& $menuIcon": {
      color: colors.primary200,
      backgroundColor: colors.light100,
    },
  },
  menuIcon: {
    width: 35,
    height: 35,
    margin: "0.4rem",
    fontSize: "1.25rem",
    borderRadius: "0.2rem",
    display: "flex",
    color: colors.light100,

    justifyContent: "center",
    alignItems: "center",
  },
  menuLabel: {
    color: colors.light100,
    fontWeight: 600,
  },
  sidebarBottom: {},
  profileImg: {
    padding: 4,
    width: 45,
  },
}));

export default Sidebar;
