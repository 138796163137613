import { FormProvider } from "react-hook-form";
import { createUseStyles } from "react-jss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Button from "antd/es/button";
import Field from "../field/Field";
import useForm from "../../hooks/useForm";
import Product from "../../models/Product";

interface RetailerProductFormProps {
  submitBtnText?: string;
  defaultValues: any;
  onSubmit: (values: any) => void;
}

function RetailerProductForm({
  submitBtnText = "Add",
  defaultValues,
  onSubmit,
  ...props
}: RetailerProductFormProps) {
  const classes = useStyles();

  const { isSubmitting, methods, submitHandler } = useForm<any>({
    ...props,
    onSubmit,
    defaultValues,
    resolver: Product.AddProductsOC(),
  });

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={submitHandler} className={classes.RetailerProductForm}>
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Field
                as={Input}
                name="product_name"
                placeholder="Product name"
              />
            </Col>

            <Col xs={24} lg={12}>
              <Field as={Input} name="pack" placeholder="Pack" />
            </Col>
            <Col xs={24} lg={12}>
              <Field as={Input} name="pack_size" placeholder="Pack Size" />
            </Col>
            <Col xs={24} lg={12}>
              <Field as={Input} name="pack_price" placeholder="Pack price" />
            </Col>
            <Col xs={24} lg={12}>
              <Field as={Input} name="price" placeholder="Price" />
            </Col>

            <Col xs={24}>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                style={{ width: "50%" }}
              >
                {submitBtnText}
              </Button>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  RetailerProductForm: {
    padding: [20, 0],

    "& *": {
      fontWeight: 600,
    },
    "& .ant-picker": {
      width: "100%",
    },
  },
  searchSelect: {
    width: "100%",
    padding: 0,
    fontWeight: 600,
  },
  customerSelect: {
    width: "100%",
    marginBottom: 24,
    padding: 0,
    fontWeight: 600,
  },
}));

export default RetailerProductForm;
