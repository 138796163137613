import { useState } from "react";
import { createUseStyles } from "react-jss";
import Card from "antd/es/card";
import Title from "antd/es/typography/Title";
import moment from "moment";
import message from "antd/es/message";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import { createCoupon } from "../../api/invoiceApis";
import AddCouponForm from "../../components/forms/AddCouponForm";

const defaultValues = {
  id: 0,
  retail_store_id: null,
  coupon_name: "",
  value: "",
  coupon_code: "",
  type: null,
  min_cart_amount: "",
  start_date: "",
  valid_till: "",
  is_active: "true",
  description: "",
  max_value: "",
  usage_per_customer: "",
  quantity: "",
};

function AddCoupon() {
  const classes = useStyles();
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const handleOnSubmit = async ({ max_value, ...values }: any) => {
    const data = {
      ...values,
      start_date: values.start_date
        ? moment(values.start_date).format()
        : undefined,
      valid_till: values.valid_till
        ? moment(values.valid_till).format()
        : undefined,
      customers: selectedCustomers,
    };

    if (!!!data.start_date) {
      delete data.start_date;
    }
    if (!!!data.valid_till) {
      delete data.valid_till;
    }

    if (values.type === "percentage") {
      data.max_value = max_value;
    }

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const item = data[key];
        (item === "" || item === undefined || item === null) &&
          delete data[key];
      }
    }

    console.log(data);
    await createCoupon(data)
      .then((res) => {
        if (res?.length && res[0].id) {
          message.success("Coupon created successfully!");
          window.location.reload();
        } else {
          message.error(res?.err);
        }
      })
      .catch((res) => {
        message.error(res?.err);
      });
  };

  return (
    <div className={classes.addCouponPage}>
      <Card>
        <Title level={3} className={classes.pageTitle}>
          <PlusCircleOutlined />
          Add Coupon
        </Title>
        <AddCouponForm
          onSubmit={handleOnSubmit}
          defaultValues={defaultValues}
          selectedCustomers={selectedCustomers}
          onSelectedCustomerChange={setSelectedCustomers}
        />
      </Card>
    </div>
  );
}

const useStyles = createUseStyles(({ colors }: Theme) => ({
  addCouponPage: {
    height: "100vh",
    padding: [20, "7vw"],
    background: "#f9f9f9",

    "& .ant-card": {
      boxShadow:
        "-1px -1px 4px rgb(34 94 119 / 25%), 1px 1px 4px rgb(34 94 119 / 25%)",
    },
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    "& .anticon": {
      marginRight: 10,
    },
  },
}));

export default AddCoupon;
